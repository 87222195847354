import { Store } from 'redux';
import { IGlobalState } from '.';

let clientStore: Store<IGlobalState>;

export const setStore = (store: Store<IGlobalState>) => {
    clientStore = store;
};

/** Для доступа к state и dispatch вне React компонентов */
export const getStore = (): Store<IGlobalState> => {
    return clientStore;
};
