import { createAction, createReducer } from 'redux-act';
import { IBranch } from '../../services/gateway/gateway.types';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../index';

export type BranchesState = {
    [key: string]: IBranch[];
};

export const addBranches = createAction<{ items: IBranch[]; bankId: string }>('add branches');

export const clearBranches = createAction('clear branches');

export const branchesReducer = createReducer<BranchesState>({}, {})
    .on(addBranches, (state, { items, bankId }) => ({
        ...state,
        [bankId]: items,
    }))
    .on(clearBranches, () => ({}));

export const useBranchSelector = () => {
    return useSelector((state: IGlobalState) => {
        return state.branchesStore;
    });
};
