import React from 'react';
import { LottieLoader } from '../../../form/ui/lottie.loader';
import { Typography } from '@sravni/react-design-system';
import animationData from './animations/application.json';
import styles from './styles.module.scss';

export const ApplicationLoad: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <LottieLoader
                fallbackUrl={require('./animations/application.svg')}
                className={styles.imageContainer}
                options={{ animationData }}
                height={140}
            />
            <Typography.Heading className={styles.heading} level={4}>
                Загружаем вашу анкету
            </Typography.Heading>
        </div>
    );
};
