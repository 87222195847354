import { ErrorBoundary } from 'react-error-boundary';

import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';
import { FC } from 'react';
import { sendSentryClientError } from '../../helpers/logger';

export const ErrorBoundaryFallbackProvider: FC = ({ children }) => (
    <ErrorBoundary
        fallbackRender={ErrorBoundaryFallback}
        onError={(error: Error, info: { componentStack: string }) =>
            sendSentryClientError(error, { ...info, ErrorBoundaryId: 'Component' }, { level: 'fatal' })
        }>
        {children}
    </ErrorBoundary>
);
