import React, { ComponentPropsWithoutRef, FC } from 'react';
import { useMetadata } from '../../../../store/reducers/metadata';
import { Breadcrumbs as SeoBreadcrumbs } from '@sravni/react-seo';
import styles from './styles.module.scss';
import cn from 'classnames';

type Props = ComponentPropsWithoutRef<'div'> & {
    breadcrumbsClassName?: string;
};

export const Breadcrumbs: FC<Props> = (props) => {
    const { className, breadcrumbsClassName } = props;
    const { breadcrumbs = [] } = useMetadata();

    if (breadcrumbs.length === 0) {
        return null;
    }

    return (
        <div className={cn(styles.container, className)}>
            <SeoBreadcrumbs className={breadcrumbsClassName} items={breadcrumbs} />
        </div>
    );
};
