export const GTM_ID = {
    common: 'GTM-PMDFG9',
    whitelabel: 'GTM-58R4BJK',
    whitelabelRosfines: 'GTM-P9FZF2DG',
};

const PARTNER_AFF_ID = {
    ROSFINES: '2371',
};

export const AFF_ID_MAP_TO_GTM_ID = {
    [PARTNER_AFF_ID.ROSFINES]: GTM_ID.whitelabelRosfines,
};
