import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../index';

type IState = {
    showDownloadApp: boolean;
};

const initialState: IState = {
    showDownloadApp: false,
};

export const toggleDownloadApp = createAction<{ show: boolean }>('toggle download app popup');

export const popupReducer = createReducer<IState>({}, initialState).on(toggleDownloadApp, (state, payload) => ({
    ...state,
    showDownloadApp: payload.show,
}));

export const useDownloadAppPopup = () => useSelector((state: IGlobalState) => state.popup.showDownloadApp);
