import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';
import { IGlobalState } from '..';
import { SelectionDictionaries } from '../../services/offers/dictionaries';

interface IState {
    organizations: SelectionDictionaries.TOrganizations;
    products: SelectionDictionaries.TProducts;
}

const initialState: IState = {
    organizations: {},
    products: {},
};

export const setDictionaries = createAction<IState>('set selection dictionaries');

export const selectionDictionariesReducer = createReducer<IState>({}, initialState).on(
    setDictionaries,
    (_, payload) => payload,
);

export const useSelectionDictionaryProducts = () =>
    useSelector((state: IGlobalState) => state.selectionDictionaries.products);

export const useSelectionDictionaryOrganizations = () =>
    useSelector((state: IGlobalState) => state.selectionDictionaries.organizations);
