import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';

import type { IGlobalState } from '../index';
import { Organization } from '../../services/organizations/types';
import { VITRINA_PRODUCT_TYPE } from '../../services/vitrins/vitrins.constants';
import { IBranches } from '../../services/gateway/gateway.types';
import type { IVitrinaConfig } from '@sravni/vitrina/lib/types/config/IVitrinaConfig';
import type { IDictionary } from '@sravni/types/lib/dictionaries';
import IProduct from '@sravni/vitrina/lib/types/IProduct';
import { IReviewItem } from '@sravni/react-seo';
import { CreditProductType } from '../../services/creditProduct/types';

interface IState {
    organization: Organization | null;
    selectionProductId: string | null;
    selectionData: {
        name: string;
        type: CreditProductType;
    } | null;
    vitrinaProductId: string | null;
    vitrinaData: {
        type: ValuesOf<typeof VITRINA_PRODUCT_TYPE> | null;
        product: Vitrina.Product | null;
        fullProduct: IProduct | null;
        config: IVitrinaConfig | null;
        dictionaries: Record<string, IDictionary>;
        filters: Record<string, any>;
        defaultFilters: Record<string, any>;
        reviews: IReviewItem[];
        branches: IBranches;
    };
}

const initialState: IState = {
    organization: null,
    selectionProductId: null,
    selectionData: null,
    vitrinaProductId: null,
    vitrinaData: {
        type: null,
        product: null,
        fullProduct: null,
        config: null,
        dictionaries: {},
        filters: {},
        defaultFilters: {},
        reviews: [],
        branches: {
            items: [],
            totalCount: 0,
        },
    },
};

export const setReviews = createAction<IReviewItem[]>('set vitrina product reviews');

export const setBranches = createAction<IBranches>('set vitrina bank branches');
export const setSelectionProductId = createAction<string>('set selection product id');

export const setVitrinaProductId = createAction<string>('set vitrina product id');

export const autoSendProductReducer = createReducer<IState>({}, initialState)
    .on(setReviews, (state, payload) => ({
        ...state,
        vitrinaData: {
            ...state.vitrinaData,
            reviews: payload,
        },
    }))
    .on(setBranches, (state, payload) => ({
        ...state,
        vitrinaData: {
            ...state.vitrinaData,
            branches: payload,
        },
    }))
    .on(setSelectionProductId, (state, payload) => ({
        ...state,
        selectionProductId: payload,
    }))
    .on(setVitrinaProductId, (state, payload) => ({
        ...state,
        vitrinaProductId: payload,
    }));

export const useAutoSendProductOrganization = () => {
    return useSelector((state: IGlobalState) => state.autoSendProduct.organization);
};

export const useAutoSendProductVitrinaData = () => {
    return useSelector((state: IGlobalState) => state.autoSendProduct.vitrinaData);
};

export const useAutoSendProductParams = () => {
    return useSelector((state: IGlobalState) => ({
        selectionProductId: state.autoSendProduct.selectionProductId,
        vitrinaProductId: state.autoSendProduct.vitrinaProductId,
        vitrinaProductType: state.autoSendProduct.vitrinaData.type,
        vitrinaProductDataLoaded: !!state.autoSendProduct.vitrinaData.product,
    }));
};

export const useAutoSendProductData = () => {
    return useSelector((state: IGlobalState) => ({
        product: state.autoSendProduct.selectionData,
    }));
};

export const useIsVitrinaProductLanding = () => {
    return useSelector((state: IGlobalState) => !!state.autoSendProduct.vitrinaData.product);
};
