import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../index';

type Name = 'carBrands' | 'purposes';

interface IState {
    carBrands: Array<{
        id: number;
        name: string;
        alias: string;
        popularity: number;
        firstLetter: string;
        isImported: boolean;
        isPopular: boolean;
    }>;
    purposes: Array<{ name: string; value: string }>;
}

const defaultState: IState = {
    carBrands: [],
    purposes: [],
};

export const addDictionary = createAction<{ name: Name; options: any[] }>('add dictionary');

export const dictionariesReducer = createReducer<IState>({}, defaultState).on(
    addDictionary,
    (state, { name, options }) => ({
        ...state,
        [name]: options,
    }),
);

export const useCarBrands = () => {
    return useSelector(
        (state: IGlobalState) =>
            state.dictionaries['carBrands'] as {
                id: number;
                name: string;
                alias: string;
                popularity: number;
                firstLetter: string;
                isImported: boolean;
                isPopular: boolean;
            }[],
    );
};

export const useDictionary = <T extends keyof IState>(dictionaryName: T) => {
    return useSelector((state: IGlobalState) => state.dictionaries[dictionaryName] || ([] as IState[T]));
};
