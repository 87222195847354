import React from 'react';
import { Typography } from '@sravni/react-design-system';
import styles from './title.module.scss';
import classNames from 'classnames';

type Props = {
    title: React.ReactNode;
    subTitle: React.ReactNode;
    banner?: React.ReactNode;
    heroImg: string;
    titleWrapperClassName?: string;
    titleClassName?: string;
    subTitleClassName?: string;
};

export const Title: React.FC<Props> = (props) => {
    const { title, subTitle, heroImg, titleClassName, subTitleClassName, titleWrapperClassName, banner } = props;

    return (
        <div className={styles.root}>
            <div className={styles.bannerWrapper_mobile}>{banner}</div>
            <div
                className={classNames(
                    styles.titleWrapper,
                    banner ? styles.titleWrapper_withBanner : null,
                    titleWrapperClassName,
                )}>
                <div>
                    <Typography.Heading level={1} className={classNames(styles.title, titleClassName)}>
                        {title}
                    </Typography.Heading>
                    <Typography.Text className={classNames(styles.subTitle, subTitleClassName)} size={12}>
                        {subTitle}
                    </Typography.Text>
                    <div className={styles.bannerWrapper_desktop}>{banner}</div>
                </div>
            </div>
            <img className={styles.heroImage} alt="" src={heroImg} loading="lazy" />
        </div>
    );
};
