import React from 'react';
import cn from 'classnames';
import { useSeoBlocksHidden, useShowingSeoBlocks } from '../../../store/reducers/metadata';
import { useSelection } from '../../../store/reducers/selection';
import { CREDIT_SELECTION_ABC_EXPERIMENT_IDS, PAGE } from '../../../constants';
import { useExperimentGroups } from '../../hooks/experiments';
import { SeoBlocks } from './seo.blocks';
import { Breadcrumbs } from './breadcrumbs';
import { PreFooter } from './prefooter';
import styles from './styles.module.scss';

export const AllSeoBlocks = () => {
    const { seoBlocks, prefooter, breadcrumbs } = useShowingSeoBlocks();
    const { page } = useSelection();
    const isHidden = useSeoBlocksHidden();
    const [isCreditsNewLanding] = useExperimentGroups({
        name: CREDIT_SELECTION_ABC_EXPERIMENT_IDS.NEW_MAIN_LANDING,
        value: ['1', '2'],
    });

    if ((!seoBlocks && !prefooter && !breadcrumbs) || isHidden) {
        return null;
    }

    const isCreditsOldLanding = page === PAGE.LANDING && !isCreditsNewLanding;

    return (
        <div
            className={cn(styles.seo_blocks_wrapper, {
                [styles.seo_blocks_wrapper_gray_bg]: isCreditsOldLanding,
                [styles.seo_blocks_wrapper_padding]: isCreditsNewLanding,
            })}>
            {seoBlocks && (
                <SeoBlocks className={cn({ [styles.seo_block_new_landing_container]: isCreditsNewLanding })} />
            )}
            {breadcrumbs && (
                <Breadcrumbs className={cn({ [styles.seo_block_new_landing_container]: isCreditsNewLanding })} />
            )}
            {prefooter && <PreFooter />}
        </div>
    );
};
