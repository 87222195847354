import * as Sentry from '@sentry/nextjs';
import type { Primitive, Scope, SeverityLevel } from '@sentry/types';
import { isProduction } from '../index';

interface IExtraOptions {
    // если мы точно знаем что ошибка будет крашить браузер, можно поставить fatal
    level?: SeverityLevel;
    tags?: Record<string, Primitive>;
}

const addContextToScope = (scope: Scope, payload?: Record<string, unknown>, extraOptions?: IExtraOptions) => {
    const { level, tags } = extraOptions || {};

    if (payload) {
        scope.setContext('additional info', payload);
    }
    if (level) {
        scope.setLevel(level);
    }

    if (tags) {
        Object.entries(tags).forEach(([key, value]) => scope.setTag(key, value));
    }

    return scope;
};

/**
 * Логгер Sentry, можно использовать в клиентском коде,
 * Для логирования стараемся использовать sendSentryClientErrorOnce дабы не отправлять одинаковые ошибки 100500 раз,
 * использовать только в критических секциях - нагружает Sentry
 * в payload не отправляем большой объем информации сентри просто отклонит евент и мы о нем никоогда не узнаем https://docs.sentry.io/platforms/go/guides/http/enriching-events/context/#size-limitations
 * */
export const sendSentryClientError = (
    e: Error | string,
    payload?: Record<string, unknown>,
    extraOptions?: IExtraOptions,
) => {
    // чтобы при разработке тоже было видно ошибки
    // eslint-disable-next-line no-console
    if (!isProduction) console.error(e, payload);

    if (typeof e === 'string') {
        Sentry.captureMessage(e, (scope) => addContextToScope(scope, payload, extraOptions));
    } else {
        Sentry.captureException(e, (scope) => addContextToScope(scope, payload, extraOptions));
    }
};

/*
 * Если нужно сделать замыкание внутри конкретного места, а не глобально
 */
export const sendSentryClientErrorScope = () => {
    const set = new Set();
    return (idOrSameAsMessage: string | true, ...arg: Parameters<typeof sendSentryClientError>) => {
        const uniqueId = idOrSameAsMessage === true && typeof arg[0] === 'string' ? arg[0] : idOrSameAsMessage;

        if (!set.has(uniqueId)) {
            sendSentryClientError(...arg);
            if (uniqueId || !isProduction) {
                // если не в продакшене, то пусть разносит нам и тестерам консоль ошибками
                set.add(uniqueId);
            }
        }
    };
};

/*
 * Чтобы не отправлять 100500 одинаковых событий для одного юзера используем эту функцию
 * особенно актуально при логировании маперов
 */
export const sendSentryClientErrorOnce = sendSentryClientErrorScope();
