import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { updateAbExperiments, useExperiments } from '../../store/reducers/abData';
import { isProduction } from '../../helpers';
import { CREDIT_SELECTION_ABC_EXPERIMENT_IDS, LOCAL_STORAGE_DEV_AB_EXPERIMENTS, UTM_MARKS } from '../../constants';
import { sessionStorage } from '../../helpers/storage';
import { useSelection } from '../../store/reducers/selection';
import { checkListOfAbExperimentVariant, ExperimentValue } from '../../helpers/experiments';
import { useUtmMarks } from '../../store/reducers/utmMarks';

type ExperimentsStorageItem = Record<string, string>;

export const useDevAbExperiments = () => {
    const isDevelopment = !isProduction;
    const dispatch = useDispatch();

    const loadDevExperimentFromLocal = (): ExperimentsStorageItem | null => {
        try {
            const customExperiments = sessionStorage.getItem(LOCAL_STORAGE_DEV_AB_EXPERIMENTS);
            if (!customExperiments) {
                return null;
            }
            return JSON.parse(customExperiments) as ExperimentsStorageItem;
        } catch (e) {
            return null;
        }
    };

    const saveDevExperimentsLocal = (name: string, value: string) => {
        const newExperiments = {
            ...loadDevExperimentFromLocal(),
            [name]: value,
        };
        sessionStorage.setItem(LOCAL_STORAGE_DEV_AB_EXPERIMENTS, JSON.stringify(newExperiments));
    };

    const setDevExperiments = () => {
        const devExperiments = loadDevExperimentFromLocal();
        if (!devExperiments) {
            return;
        }
        dispatch(updateAbExperiments(devExperiments));
    };

    useEffect(() => {
        if (isDevelopment) {
            setDevExperiments();

            (window as any).setDevAbExperiment = (name: unknown, value: unknown) => {
                if (typeof name !== 'string' || typeof value !== 'string' || !name || !value) {
                    return;
                }
                dispatch(updateAbExperiments({ [name]: value }));
                saveDevExperimentsLocal(name, value);
            };
            (window as any).clearDevAbExperiments = () => {
                sessionStorage.removeItem(LOCAL_STORAGE_DEV_AB_EXPERIMENTS);
            };
        }
    }, []);
};

type ExperimentGroupsParams =
    | Readonly<{ name: ValuesOf<typeof CREDIT_SELECTION_ABC_EXPERIMENT_IDS>; value: ExperimentValue }>
    | ReadonlyArray<Readonly<{ name: ValuesOf<typeof CREDIT_SELECTION_ABC_EXPERIMENT_IDS>; value: ExperimentValue }>>;

/** Возвращает активные эксперименты с учетом страницы */
export const useExperimentGroups = (params: ExperimentGroupsParams) => {
    const experiments = useExperiments();
    const { page } = useSelection();
    const { [UTM_MARKS.MEDIUM]: utmMedium } = useUtmMarks();

    return useMemo(() => {
        const checkList = (
            [] as Array<{ name: ValuesOf<typeof CREDIT_SELECTION_ABC_EXPERIMENT_IDS>; value: ExperimentValue }>
        ).concat(params);

        return checkListOfAbExperimentVariant(experiments, page, utmMedium, checkList);
    }, [experiments, page, utmMedium]);
};
