import React from 'react';
import { Typography } from '@sravni/react-design-system';
import { LottieLoader } from '../../../form/ui/lottie.loader';
import animationData from './animations/scoring.json';

import styles from './styles.module.scss';

export const ScoringLoad: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <LottieLoader className={styles.imageContainer} options={{ animationData }} height={160} />
            <Typography.Heading className={styles.heading} level={4}>
                Изучаем вашу кредитную историю
            </Typography.Heading>
        </div>
    );
};
