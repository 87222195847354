export const LandingFormModule = () => import(/* webpackChunkName: "landing-form" */ '../components/form/landing');
export const WhitelabelFormModule = () =>
    import(/* webpackChunkName: "landing-form-whitelabel" */ '../components/form/whitelable');
export const OffersModule = () => import(/* webpackChunkName: "offers" */ '../components/offers.new');

export const ReactLottieModule = () => import(/* webpackChunkName: "react-lottie" */ 'react-lottie');

export const PromoBankFormModule = () => import(/* webpackChunkName: "bank-form" */ '../components/form/promo.bank');

export const RefinancingFormModule = () =>
    import(/* webpackChunkName: "refinancing-form" */ '../components/form/promo.refinancing');
