import { isProduction } from '.';

export enum EventType {
    mainEvent = 'mainEvent',
    mainWLEvent = 'mainWLEvent',
    adEvent = 'adEvent',
}

export const Categories = {
    Marketplace: 'Маркетплейс',
    Loan: 'Подбор кредита',
    CreditCard: 'Подбор кредитной карты',
} as const;

export interface AnalyticEvent {
    event: EventType;
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    eventValue?: number;
}

const emit = (event: AnalyticEvent | { SravniUserId: string }) => {
    if (!isProduction) {
        if ('SravniUserId' in event && event.SravniUserId) {
            console.info(`%c Analytics: SravniUserId | ${event.SravniUserId}`, 'color: #848d95');
            return;
        }

        console.groupCollapsed(`Analytics Event: ${(event as AnalyticEvent).eventAction}`);
        console.table(
            Object.entries(event)
                .filter(([_, value]) => value)
                .map(([key, value]) => ({ key, value })),
        );
        console.groupEnd();
        return;
    }

    if (typeof window !== 'undefined') {
        window.sravniDataLayer = window.sravniDataLayer || [];

        if ('SravniUserId' in event && event.SravniUserId) {
            window.sravniDataLayer.push(event);
        } else {
            window.sravniDataLayer.push(
                Object.assign(
                    {
                        eventLabel: undefined,
                        eventValue: undefined,
                    },
                    event,
                ),
            );
        }
    }
};

/** @deprecated use hook useAnalytics instead */
const analytics = { events: EventType, emit, categories: Categories };

export default analytics;

declare global {
    interface Window {
        sravniDataLayer: Array<AnalyticEvent | { SravniUserId: string }>;
    }
}
