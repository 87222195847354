import React, { useState, useEffect, useRef } from 'react';
import { setMainSkeletonIsActive, useMainSkeleton } from '../../../store/reducers/selection';
import { ActiveComponent } from './components/active.component';
import { useDispatch } from 'react-redux';

/** Если скелетон показывается не дольше, чем MIN_STEP_DURATION, показ завершится немедленно */
const MIN_STEP_DURATION = 300;
/** Если скелетон показывается дольше, чем MIN_STEP_DURATION, показ завершится после STEP_DURATION */
const STEP_DURATION = 2.5 * 1000;
/** Количество кадров минус 1 (отчет с нуля) */
const MAX_SKELETON_VARIANTS = 2;

/** Компонент устанавливается максимально высоко, один раз. Управляется MainSkeleton и store.mainSkeleton */
export const MainSkeletonProvider: React.FC = (props) => {
    const { children } = props;
    const dispatch = useDispatch();
    const [variant, setVariant] = useState<number>(0);
    const [shortDuration, setShortDuration] = useState(true);
    const shortIntervalRef = useRef(0);
    const debounceIntervalRef = useRef(0);
    const { showMainSkeleton, shortSkeleton, mainSkeletonIsActive } = useMainSkeleton();

    const maxVariants = shortSkeleton ? 0 : MAX_SKELETON_VARIANTS;

    useEffect(() => {
        return () => {
            clearTimeout(shortIntervalRef.current);
            clearTimeout(debounceIntervalRef.current);
        };
    }, []);

    useEffect(() => {
        if (showMainSkeleton && !mainSkeletonIsActive) {
            shortIntervalRef.current = setTimeout(() => {
                setShortDuration(false);
            }, MIN_STEP_DURATION);

            dispatch(setMainSkeletonIsActive(true));
            setVariant(0);
            return;
        }

        if (mainSkeletonIsActive) {
            clearTimeout(debounceIntervalRef.current);
            debounceIntervalRef.current = setTimeout(
                () => {
                    if (!showMainSkeleton) {
                        clearTimeout(shortIntervalRef.current);
                        setShortDuration(true);
                        dispatch(setMainSkeletonIsActive(false));
                    }

                    setVariant(variant >= maxVariants || shortDuration ? 0 : variant + 1);
                },
                shortDuration ? 0 : STEP_DURATION,
            );
        }
    }, [mainSkeletonIsActive, shortDuration, showMainSkeleton, variant, maxVariants]);

    const showSkeleton = mainSkeletonIsActive;

    const mainStyle: React.CSSProperties = {
        opacity: 0,
        position: 'absolute',
        height: 0,
        overflow: 'hidden',
    };

    return (
        <>
            {showSkeleton && <ActiveComponent variant={variant} shortDuration={shortDuration} />}
            <div style={showSkeleton ? mainStyle : {}}>{children}</div>
        </>
    );
};
