import React from 'react';
import { TextInput as TextInputBase, MaskInput } from '@sravni/react-design-system';
import { ErrorTextWrapper } from '../common';
import { MASKS } from '../../../constants';

type Props = React.ComponentProps<typeof TextInputBase> & {
    errorMessage: React.ReactNode | undefined;
};

export const MonthInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { errorMessage, label, ...other } = props;
    return (
        <ErrorTextWrapper errorText={errorMessage}>
            <MaskInput
                ref={ref}
                inputMode="numeric"
                mask={MASKS.MONTH}
                label={label ? label : undefined}
                {...other}
                invalid={Boolean(errorMessage)}
            />
        </ErrorTextWrapper>
    );
});

MonthInput.displayName = 'MonthInput';
