import routes from './routes.internal.json';

type PageName =
    | 'LANDING'
    | 'CARDS_LANDING'
    | 'CARDS_WHITELABEL'
    | 'CARDS_RESTORE'
    | 'SCORING'
    | 'RESTORE'
    | 'CALLCENTER'
    | 'CALLCENTER_MAIN_FORM_EDIT'
    | 'CALLCENTER_FULL_PATH'
    | 'WHITELABEL'
    | 'AGENTS'
    | 'WAYBACK'
    | 'AVITO'
    | 'DEALS'
    | 'PROMO_SKB'
    | 'PROMO_RAIFFAIZEN'
    | 'PROMO_WEBBANKIR'
    | 'PROMO_ZAIMER'
    | 'PROMO_MTS'
    | 'MAIN_FORM_EDIT'
    | 'SEND'
    | 'PROMO_REFINANCING'
    | 'THANKS_FOR_INTEREST'
    | 'CREDIT_CARDS_LANDING'
    | 'CRM_CREDIT_CARDS_LANDING'
    | 'CRM_AUTO_SEND';

export const PAGE: Record<PageName, keyof typeof routes> = {
    LANDING: 'landing',
    CARDS_LANDING: 'cards',
    SCORING: 'scoring',
    RESTORE: 'restore',
    CARDS_RESTORE: 'cardsRestore',
    CALLCENTER: 'callcenter',
    CALLCENTER_MAIN_FORM_EDIT: 'callcenterMainFormEdit',
    CALLCENTER_FULL_PATH: 'callcenterFullPath',
    WHITELABEL: 'whitelable',
    CARDS_WHITELABEL: 'cardsWhitelable',
    AGENTS: 'agents',
    WAYBACK: 'wayback',
    AVITO: 'avito',
    DEALS: 'deals',
    PROMO_SKB: 'promoSkb',
    PROMO_RAIFFAIZEN: 'promoRaiffeisen',
    PROMO_WEBBANKIR: 'promoWebbankir',
    PROMO_ZAIMER: 'promoZaimer',
    PROMO_MTS: 'promoMts',
    MAIN_FORM_EDIT: 'mainFormEdit',
    SEND: 'send',
    PROMO_REFINANCING: 'promoRefinancing',
    THANKS_FOR_INTEREST: 'thanksForInterest',
    CREDIT_CARDS_LANDING: 'creditCardsLanding',
    CRM_CREDIT_CARDS_LANDING: 'crmCreditCardsLanding',
    CRM_AUTO_SEND: 'crmAutoSend',
} as const;

export const PAGE_CONTEXT = {
    DEFAULT: '',
    STEP_1: 'step1',
    STEP_2: 'step2',
    STEP_3: 'step3',
    STEP_4: 'step4',
    OFFERS: 'offers',
} as const;
