import { createReducer } from 'redux-act';
import { PARTNER_SOURCES } from '../../constants/partners';

export interface IState {
    partnerSource?: ValuesOf<typeof PARTNER_SOURCES>;
}

const initialState: IState = {};

export const whitelabelReducer = createReducer({}, initialState);
