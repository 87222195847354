import React from 'react';
import classNames from 'classnames';
import { Button, Select, Dialog, Spinner } from '@sravni/react-design-system';
import { Caption } from '../text';
import styles from './common.module.scss';

export { Button, Select, Dialog };

export const Paper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, children, ...others }) => {
    return (
        <div className={classNames(styles.paper, className)} {...others}>
            {children}
        </div>
    );
};

export const Label: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, children, ...others }) => {
    return (
        <div className={classNames(styles.darkLabel, className)} {...others}>
            {children}
        </div>
    );
};

export const LinkButton: React.FC<
    React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = ({ className, children, ...others }) => {
    return (
        <a className={classNames(styles.linkButton, className)} {...others}>
            {children}
        </a>
    );
};

type Props = {
    value: React.ReactNode;
    caption: React.ReactNode;
    loading?: boolean;
    className?: string;
    valueClassName?: string;
    captionClassName?: string;
};

export const DataLabel: React.FC<Props> = ({
    value,
    caption,
    loading,
    className,
    valueClassName,
    captionClassName,
}) => {
    return (
        <div className={classNames(styles.dataLabel, className)}>
            {loading && (
                <div className={styles.loadingWrapper}>
                    <Spinner />
                </div>
            )}
            <div className={classNames(valueClassName, styles.value)}>{value}</div>
            <Caption className={captionClassName}>{caption}</Caption>
        </div>
    );
};
