import React from 'react';
import { MaskInput, TextInput } from '@sravni/react-design-system';
import { MASKS, PLACEHOLDERS } from '../../../constants';
import { ErrorTextWrapper } from '../common';

type Props = React.ComponentProps<typeof TextInput> & { errorMessage: React.ReactNode | undefined };

/** Поле для ввода телефонных номеров с маской ввода и обработчиком вставки */
export const PhoneInput = React.forwardRef<HTMLInputElement, Props>(({ errorMessage, label, ...props }, ref) => {
    /** Нормализация значения при вставке номера, начинающегося на 7 или 8 */
    const onPastePhone = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const { clipboardData } = event;
        const value = clipboardData.getData('text/plain') || '';

        const paste = value.replace(/\D/g, '').slice(-10);
        document.execCommand('insertText', false, paste);
    };

    return (
        <ErrorTextWrapper errorText={errorMessage}>
            <MaskInput
                ref={ref}
                label={label ? label : undefined}
                {...props}
                autoComplete="off"
                onPaste={onPastePhone}
                mask={props.value ? MASKS.PHONE : undefined}
                placeholder={PLACEHOLDERS.PHONE}
                invalid={Boolean(errorMessage)}
            />
        </ErrorTextWrapper>
    );
});

PhoneInput.displayName = 'PhoneInput';
