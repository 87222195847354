import React, { FocusEventHandler } from 'react';
import { TextInput as TextInputBase, MaskInput } from '@sravni/react-design-system';
import styled from 'styled-components';
import { ErrorTextWrapper } from '../common';

type Props = React.ComponentProps<typeof TextInputBase> & {
    errorMessage: React.ReactNode | undefined;
    errorMessageClassName?: string;
    additionalMessage?: React.ReactNode;
    mask?: React.ComponentProps<typeof MaskInput>['mask'];
    withoutErrorTextWrapper?: boolean;
};

const TextInputBaseStyled = styled(TextInputBase)`
    // TODO: убрать после обновления @sravni/react-design-system, если текст не растягивает input за ширину страницы на 320px
    div > div > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }
`;

const TextInputComponent = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { errorMessage, mask, value, label, ...other } = props;

    const onFocus: FocusEventHandler<HTMLInputElement> = (event) => {
        const { target } = event;
        if (!target.value) {
            setTimeout(() => target.setSelectionRange(0, 0), 0);
        }

        other.onFocus && other.onFocus(event);
    };

    return mask ? (
        <MaskInput
            ref={ref}
            value={value}
            label={label ? label : undefined}
            {...other}
            invalid={Boolean(errorMessage)}
            mask={mask}
            onFocus={onFocus}
        />
    ) : (
        <TextInputBaseStyled
            ref={ref}
            value={value}
            label={label ? label : undefined}
            {...other}
            invalid={Boolean(errorMessage)}
        />
    );
});

TextInputComponent.displayName = 'TextInputComponent';

export const TextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { errorMessage, errorMessageClassName, additionalMessage, withoutErrorTextWrapper, ...other } = props;

    return withoutErrorTextWrapper ? (
        <TextInputComponent {...other} errorMessage={errorMessage} ref={ref} />
    ) : (
        <ErrorTextWrapper errorText={errorMessage} additionalText={additionalMessage} className={errorMessageClassName}>
            <TextInputComponent {...other} errorMessage={errorMessage} ref={ref} />
        </ErrorTextWrapper>
    );
});

TextInput.displayName = 'TextInput';
