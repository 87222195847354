import cn from 'classnames';
import React, { memo, FC } from 'react';

import { Reviews } from '@sravni/react-seo';

import style from './style.module.scss';
import { IReview } from '../helpers';

interface IProps {
    reviews: IReview[];
    organizationAlias?: string;
    organizationType?: string;
    heading?: string;
    className?: string;
    itemClassName?: string;
    gradient?: boolean;
    urlParams?: Record<string, string>;
}

const ReviewsBlock: FC<IProps> = memo(function ReviewsBlockComponent({
    reviews,
    organizationAlias,
    heading,
    className,
    itemClassName,
    gradient = false,
    urlParams = {},
}) {
    if (reviews.length === 0) {
        return null;
    }

    const linkBase = organizationAlias ? `/bank/${organizationAlias}/otzyvy/` : `/kredity/otzyvy/`;
    const paramString = new URLSearchParams(urlParams).toString();

    const link = paramString ? `${linkBase}?${paramString}` : linkBase;

    return (
        <Reviews
            className={cn(style.wrapper, className)}
            reviewItemClassName={itemClassName}
            heading={heading}
            productType="bank"
            allReviewsLink={link}
            reviews={reviews}
            gradient={gradient}
        />
    );
});

export default ReviewsBlock;
