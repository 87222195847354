import React from 'react';
import { LottieLoader } from '../../../form/ui/lottie.loader';
import { Typography } from '@sravni/react-design-system';
import styles from './styles.module.scss';
import animationData from './animations/selection.json';

export const SelectionLoad: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <LottieLoader className={styles.imageContainer} options={{ animationData }} height={150} />
            <Typography.Heading className={styles.heading} level={4}>
                Подбираем лучшие кредитные предложения под ваши параметры
            </Typography.Heading>
        </div>
    );
};
