import { FC, ComponentPropsWithoutRef, ReactNode, useState, memo } from 'react';
import { Accordion, Card, Typography } from '@sravni/react-design-system';
import styles from './faq.module.scss';
import { Container } from '../common';
import classNames from 'classnames';

export interface FaqItem {
    question: ReactNode;
    answer: ReactNode;
}

const { Text } = Typography;

type Props = ComponentPropsWithoutRef<'div'> & {
    header?: ReactNode;
    description?: ReactNode;
    questionsData: FaqItem[];
    defaultOpenedItems?: number[];
};

export const Faq: FC<Props> = memo(function FaqComponent(props) {
    const { header, description, questionsData, defaultOpenedItems } = props;
    const [openedItems, setOpenedItems] = useState<number[]>(defaultOpenedItems || []);

    const onChangeItem = (indexes: number[]) => setOpenedItems(indexes);

    return (
        <Container className={styles['faq-container']}>
            {header}
            {description}
            <div className={styles['grid-row']}>
                <Card className={styles['main-image']} />
                <Accordion className={styles['accordion']} defaultIndex={defaultOpenedItems} onChange={onChangeItem}>
                    {questionsData.map((item, i) => (
                        <Accordion.Item
                            title={
                                <Text className={styles['question']} size={16}>
                                    {item.question}
                                </Text>
                            }
                            key={i}
                            className={classNames(styles['accordion__item'], {
                                [styles['accordion__item_active']]: openedItems.includes(i),
                            })}>
                            <Text size={14} className={styles['answer']}>
                                {item.answer}
                            </Text>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </Container>
    );
});
