import { FORM_FIELDS } from '../../../../constants';

/** Клик по полям из списка не будет приводить к сворачиванию полей организации */
export const namesOfOrganizationFields = [
    FORM_FIELDS.ORGANIZATION_NAME,
    FORM_FIELDS.EMPLOYER_INN,
    FORM_FIELDS.EMPLOYER_TYPE,
    FORM_FIELDS.AREA_OF_EMPLOYMENT,
    FORM_FIELDS.WORK_ADDRESS,
] as const;

/** Состояние плашки редактирования данных организации */
export enum WPViews {
    wpVisible,
    wpShort,
    wpHidden,
    wpVisibleManual,
}
