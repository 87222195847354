import { createAction, createReducer } from 'redux-act';
import { destroyUser } from './user';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../index';
import { MPL_CLIENT_SCREEN_STATE_VALUES, MplClientScreenStateType, TabsType } from '../../constants';

export type SendingFbRequestsStatusType =
    | 'not_started'
    | 'need_to_auto_send'
    | 'sending'
    | 'auto_sending'
    | 'completed'
    | 'already_sent';

type IState = {
    client?: MPL.ClientData | null;
    clientState?: MPL.ClientState | 'no-mpl-account';
    unsignedDocuments?: Array<MPL.UnsignedDocument>;
    consentDocuments?: Array<MPL.SignedDocument>;
    lastDeal?: MPL.DealItem | null;
    lastDealVersion?: number;
    lastDealVersionOnSlidersShowing?: number;
    activeRequest?: MPL.ActiveRequest | null;
    mplClientScreenState: MplClientScreenStateType;
    pendingMplClientScreenState?: MplClientScreenStateType;
    sendingFbRequestsStatus: SendingFbRequestsStatusType;
    requestsWithExactConditions?: FB.RequestWithExactConditions[] | null;
    activeMeeting?: any;
    exclusiveTab?: TabsType | TabsType[];
};

const initialState: IState = {
    mplClientScreenState: MPL_CLIENT_SCREEN_STATE_VALUES.INITIAL_CLIENT_LOADING,
    sendingFbRequestsStatus: 'not_started',
};

export const setMplClient = createAction<MPL.ClientData | null>('set marketplace client data');
export const setMplActiveMeeting = createAction<any | null>('set marketplace active meeting');

export const setMplUnsignedDocuments = createAction<IState['unsignedDocuments']>('set marketplace unsigned documents');

export const setMplConsentDocuments = createAction<IState['consentDocuments']>('set marketplace consent documents');

export const setMplLastDeal = createAction<MPL.DealItem | null>('set marketplace last deal');

export const setActiveRequest = createAction<MPL.ActiveRequest | null>('set marketplace active request');

export const setMplClientScreenState = createAction<MplClientScreenStateType>('set marketplace client screen state');

export const setPendingMplClientScreenState = createAction<MplClientScreenStateType | undefined>(
    'set pending marketplace client screen state',
);

export const setMplSendingFbRequestsStatus = createAction<SendingFbRequestsStatusType>(
    'set marketplace auto sending fb requests status',
);

export const setMplDealCounters = createAction<MPL.DealCounters | null>('set marketplace deal counters');

export const setRequestsWithExactConditions = createAction<FB.RequestWithExactConditions[] | null>(
    'set fb requests with exact conditions',
);

export const clearMplState = createAction('clear marketplace state');

export const setExlusiveTab = createAction<TabsType | TabsType[] | undefined>('set exclusive tabs');

export const mplReducer = createReducer<IState>({}, initialState)
    .on(setMplClient, (state, payload) => ({
        ...state,
        client: payload,
        clientState: payload ? payload.state : 'no-mpl-account',
    }))
    .on(setMplActiveMeeting, (state, payload) => ({
        ...state,
        activeMeeting: payload,
    }))
    .on(setMplUnsignedDocuments, (state, payload) => ({
        ...state,
        unsignedDocuments: payload,
    }))
    .on(setMplConsentDocuments, (state, payload) => ({
        ...state,
        consentDocuments: payload,
    }))
    .on(setMplLastDeal, (state, payload) => {
        let newDealVersion = state.lastDealVersion || 0;
        if ((!state.lastDeal && payload) || (state.lastDeal && payload && state.lastDeal.id !== payload.id)) {
            newDealVersion++;
        }
        return {
            ...state,
            lastDeal: payload,
            lastDealVersion: newDealVersion,
        };
    })
    .on(setActiveRequest, (state, payload) => ({
        ...state,
        activeRequest: payload,
    }))
    .on(setMplClientScreenState, (state, payload) => {
        return {
            ...state,
            mplClientScreenState: payload,
            ...(payload === MPL_CLIENT_SCREEN_STATE_VALUES.SLIDERS
                ? { lastDealVersionOnSlidersShowing: state.lastDealVersion || 0 }
                : {}),
        };
    })
    .on(setPendingMplClientScreenState, (state, payload) => ({
        ...state,
        pendingMplClientScreenState: payload,
    }))
    .on(setMplSendingFbRequestsStatus, (state, payload) => ({
        ...state,
        sendingFbRequestsStatus: payload,
    }))
    .on(setMplDealCounters, (state, payload) =>
        state.lastDeal && payload
            ? {
                  ...state,
                  lastDeal: {
                      ...state.lastDeal,
                      ...(payload
                          ? {
                                counterRequestsAll: payload.counterRequestsAll,
                                counterRequestsApproved: payload.counterRequestsApproved,
                                counterRequestsWaiting: payload.counterRequestsWaiting,
                                counterRequestsRefused: payload.counterRequestsRefused,
                            }
                          : {}),
                  },
              }
            : state,
    )
    .on(setRequestsWithExactConditions, (state, payload) => ({
        ...state,
        requestsWithExactConditions: payload,
    }))
    .on(destroyUser, () => initialState)
    .on(clearMplState, () => initialState)
    .on(setExlusiveTab, (state, payload) => ({
        ...state,
        exclusiveTab: payload,
    }));

export function useMplState(): MPL.ClientState | 'no-mpl-account' | undefined {
    return useSelector((state: IGlobalState) => state.mpl.clientState);
}

export function useMplActiveMeeting() {
    return useSelector((state: IGlobalState) => state.mpl.activeMeeting);
}

export function useMplClient(): MPL.ClientData | null | undefined {
    return useSelector((state: IGlobalState) => state.mpl.client);
}

export function useMplLastDeal(): MPL.DealItem | null | undefined {
    return useSelector((state: IGlobalState) => state.mpl.lastDeal);
}

export function useMplActiveRequest(): MPL.ActiveRequest | null | undefined {
    return useSelector((state: IGlobalState) => state.mpl.activeRequest);
}

export function useMplClientScreenState(): MplClientScreenStateType {
    return useSelector((state: IGlobalState) => state.mpl.mplClientScreenState);
}

export function useRequestsWithExactConditions() {
    return useSelector((state: IGlobalState) => state.mpl.requestsWithExactConditions);
}

export function useMplDocs(): {
    unsignedDocuments?: Array<MPL.UnsignedDocument>;
    consentDocuments?: Array<MPL.SignedDocument>;
} {
    return useSelector((state: IGlobalState) => ({
        unsignedDocuments: state.mpl.unsignedDocuments,
        consentDocuments: state.mpl.consentDocuments,
    }));
}

export function useMplDealCounters(): MPL.DealCounters | null {
    return useSelector((state: IGlobalState) => {
        const deal = state.mpl.lastDeal;
        if (!deal) {
            return null;
        }
        return {
            counterRequestsAll: deal.counterRequestsAll,
            counterRequestsApproved: deal.counterRequestsApproved,
            counterRequestsWaiting: deal.counterRequestsWaiting,
            counterRequestsRefused: deal.counterRequestsRefused,
        };
    });
}

export function useMplSendingFbRequestsStatus(): SendingFbRequestsStatusType {
    return useSelector((state: IGlobalState) => state.mpl.sendingFbRequestsStatus);
}

export function useLastDealVersions(): { currentDealVersion: number; dealVersionOnSlidersShowing: number } {
    return useSelector((state: IGlobalState) => ({
        currentDealVersion: state.mpl.lastDealVersion || 0,
        dealVersionOnSlidersShowing: state.mpl.lastDealVersionOnSlidersShowing || 0,
    }));
}

export function usePendingMplClientScreenState(): MplClientScreenStateType | undefined {
    return useSelector((state: IGlobalState) => state.mpl.pendingMplClientScreenState);
}

export function useExclusiveTab(): TabsType | TabsType[] | undefined {
    return useSelector((state: IGlobalState) => state.mpl.exclusiveTab);
}
