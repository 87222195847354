import { createAction, createReducer } from 'redux-act';
import { IGlobalState } from '../index';
import { useSelector } from 'react-redux';

type IState = {
    firstName: string;
} | null;

const initialState: IState = null;

export const setUserInvitedBy = createAction<IState>('set user invited by');

export const userInvitedByReducer = createReducer<IState>({}, initialState).on(
    setUserInvitedBy,
    (_, payload) => payload,
);

export const useUserInvitedBy = () => {
    return useSelector((state: IGlobalState) => state.userInvitedBy);
};
