import { FC, useEffect, useRef } from 'react';
import { IPublicUser } from '@sravni/types/lib/auth';
import { useRouter } from 'next/router';
import { useLocation } from 'react-use';
import { useUser } from '../../store/reducers/user';
import analytics from '../../helpers/analytics';
import { useSelection } from '../../store/reducers/selection';
import { PAGE } from '../../constants';
import { useDevAbExperiments } from '../hooks/experiments';
import { clearQueryParam } from '../../helpers/url';
import { sendAuthAnalytics } from './helpers';

export const CommonSideEffects: FC = () => {
    useDevAbExperiments();
    const { query } = useRouter();
    const location = useLocation();
    const { search = '', pathname = '' } = location;
    const user = useUser();
    const prevUser = useRef(user);
    const { page } = useSelection();

    const manageCalculatorAnalytics = (account: IPublicUser | null) => {
        const eventCategory = ![PAGE.CARDS_RESTORE, PAGE.CARDS_LANDING, PAGE.CARDS_WHITELABEL].includes(page)
            ? analytics.categories.Loan
            : analytics.categories.CreditCard;

        const calculatorAuthFromMap: Record<string, string> = {
            gosuslugi: 'Войти Госуслуги',
            button: 'Войти Подбор',
            link: 'Войти Сравни',
        };

        const authFrom = query['after_calculator_auth'] as string;

        if (account?.sub) {
            analytics.emit({ SravniUserId: account?.sub });
            analytics.emit({
                event: analytics.events.mainEvent,
                eventCategory,
                eventAction: 'Вход в лк',
                eventLabel: calculatorAuthFromMap[authFrom] || 'unknown',
                eventValue: undefined,
            });
        }

        clearQueryParam(pathname, search, 'after_calculator_auth');
        clearQueryParam(pathname, search, 'after_auth');
    };

    const manageUserAnalytics = (account: IPublicUser | null, isClearQuery = false) => {
        const eventCategory = ![PAGE.CARDS_RESTORE, PAGE.CARDS_LANDING, PAGE.CARDS_WHITELABEL].includes(page)
            ? analytics.categories.Loan
            : analytics.categories.CreditCard;

        if (account?.sub) {
            analytics.emit({ SravniUserId: account?.sub });
            sendAuthAnalytics(eventCategory);
            if (isClearQuery) {
                clearQueryParam(pathname, search, 'after_auth');
            }
        }
    };

    useEffect(() => {
        if (query['after_calculator_auth'] && user) {
            manageCalculatorAnalytics(user);
        } else if (query['after_auth'] === 'true' && user) {
            manageUserAnalytics(user, true);
        }
    }, []);

    useEffect(() => {
        if (prevUser.current === null && user) {
            prevUser.current = user;
            manageUserAnalytics(user);
        }
    }, [user]);

    return <></>;
};
