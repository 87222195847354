import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import type { Options, LottieProps } from 'react-lottie';
import dynamic from 'next/dynamic';
import { ReactLottieModule } from '../../../../helpers/dynamic.modules';
import styles from './styles.module.scss';

export type LottieOptions = Omit<Options, 'animationData'> & {
    /** Для совместимости с imported json */
    animationData?: Record<string, unknown>;
};

type Props = Omit<LottieProps, 'options'> & {
    /** можно использовать publicAssetsPath() */
    jsonUrl?: string;
    /** картинка, пока анимация загружается */
    fallbackUrl?: string;
    fallback?: JSX.Element;
    options?: LottieOptions;
    className?: string;
};

const Lottie = dynamic(ReactLottieModule, {
    ssr: false,
});

/** Загрузчик анимационных картинок с fallback */
export const LottieLoader: React.FC<Props> = (props) => {
    const {
        fallbackUrl,
        fallback = null,
        jsonUrl,
        options = {},
        width = '100%',
        height = '100%',
        className,
        ...otherProps
    } = props;
    const { animationData = null, ...otherOptions } = options;
    const [jsonData, setJsonData] = useState<Record<string, unknown> | null>(null);

    const fallbackComponent = useMemo(
        () => (fallbackUrl ? <img alt="animation" src={fallbackUrl} width={width} height={height} /> : fallback),
        [fallbackUrl, fallback, width, height],
    );

    const defaultOptions: Options = {
        loop: true,
        autoplay: true,
        animationData: {},
        ...otherOptions,
    };

    useEffect(() => {
        if (jsonUrl) {
            fetch(jsonUrl)
                .then((response) => response.json())
                .then((data) => setJsonData(data));
        }
    }, [jsonUrl]);

    useEffect(() => {
        setJsonData(animationData);
    }, [animationData]);

    return (
        <div className={classNames(styles.root, className)}>
            {jsonData ? (
                <Lottie
                    options={{ ...defaultOptions, animationData: jsonData }}
                    height={height}
                    width={width}
                    {...otherProps}
                />
            ) : (
                fallbackComponent
            )}
        </div>
    );
};
