import { STATUS_STATES } from '../../../../../constants';

/** Группирует статусы активных заявок */
export const getActiveGroupByStatus = (status: string) => {
    switch (status) {
        case STATUS_STATES.REFUSED:
        case STATUS_STATES.REJECTED:
            return 'refused';
        case STATUS_STATES.APPROVED:
        case STATUS_STATES.FINAL_APPROVED:
        case STATUS_STATES.CONFIRMED:
            return 'approved';
        case STATUS_STATES.NEW:
        case STATUS_STATES.WAITING:
        case STATUS_STATES.COUNTDOWN:
        default:
            return 'waiting';
    }
};

export const sortByPositionOnLanding = () => {
    const endOfPosition = Infinity;
    return (a: Offer.Suitable, b: Offer.Suitable) => {
        const aPosition = a.advertising.positionOnLanding || endOfPosition;
        const bPosition = b.advertising.positionOnLanding || endOfPosition;
        return aPosition - bPosition;
    };
};

export const sortBySuperPositionManual = () => {
    const endOfPosition = Infinity;
    return (a: Offer.Suitable, b: Offer.Suitable) => {
        const aPosition = a.advertising.superPositionManual || endOfPosition;
        const bPosition = b.advertising.superPositionManual || endOfPosition;
        return aPosition - bPosition;
    };
};

export const sortByCallCenterPriority = () => {
    return (a: Offer.Suitable) => {
        return a.advertising.callCenterPriority ? -1 : 1;
    };
};

export const sortByOkbArrange = (arrange: Record<string, number>) => {
    return (a: Offer.Suitable, b: Offer.Suitable) => {
        return !arrange[b.organization.newId] || arrange[a.organization.newId] > arrange[b.organization.newId] ? -1 : 1;
    };
};
