import React, { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import { SeoBlock } from '@sravni/react-seo';
import SeoBlockTypes from '@sravni/vitrina/lib/enums/SeoBlockTypes';
import type { ContentBlock } from '@sravni/types/lib/seo';
import { useMetadata } from '../../../../store/reducers/metadata';
import { NoIndex } from '../../../noIndex';
import styles from './styles.module.scss';
import cn from 'classnames';
import { decorateReviews, IReview } from './helpers';
import { useReviews } from '../../../../store/reducers/reviews';
import { useOrganization } from '../../../../store/reducers/organization';
import ReviewsBlock from './reviews.block';

type Props = ComponentPropsWithoutRef<'div'>;

export const SeoBlocks: FC<Props> = (props) => {
    const { className } = props;
    const reviewsState = useReviews();
    const organization = useOrganization();
    const { contentBlocks = [], noIndexSeoText } = useMetadata();

    const mappedContentBlocks = useMemo(() => {
        return (contentBlocks || [])
            .map((seoBlock) => {
                switch (seoBlock.type) {
                    case SeoBlockTypes.Review: {
                        const reviews = decorateReviews(reviewsState as IReview[]);
                        return {
                            type: seoBlock.type,
                            reviewsComponent: (
                                <ReviewsBlock
                                    key="review"
                                    heading={seoBlock.title}
                                    reviews={reviews}
                                    organizationAlias={organization?.item.alias}
                                />
                            ),
                        };
                    }
                    case SeoBlockTypes.FAQ: {
                        return {
                            type: seoBlock.type,
                            title: seoBlock.serviceResponseData.title,
                            items: seoBlock.serviceResponseData.items,
                        };
                    }

                    case SeoBlockTypes.Html: {
                        return {
                            type: seoBlock.type,
                            text: seoBlock.text,
                            author: seoBlock.author,
                        };
                    }
                    default: {
                        return null;
                    }
                }
            })
            .filter(Boolean) as ContentBlock[];
    }, [contentBlocks]);

    if (mappedContentBlocks.length === 0) {
        return null;
    }

    return (
        <div className={cn(styles.container, className)}>
            <NoIndex if={noIndexSeoText}>
                <SeoBlock
                    className={styles.seoBlock}
                    // @ts-ignore
                    contentBlocks={mappedContentBlocks}
                />
            </NoIndex>
        </div>
    );
};
