import React, { FC, ReactNode, ComponentPropsWithoutRef, memo } from 'react';
import { Card as CommonCard } from '@sravni/react-design-system';
import { CardProps } from '@sravni/react-design-system/lib/Card';
import styles from './guard.module.scss';
import { Container } from '../common';
import classNames from 'classnames';

const Card: FC<CardProps> = ({ children, className, ...props }) => (
    <CommonCard className={classNames(styles['vertical-card'], className)} {...props}>
        {children}
    </CommonCard>
);

interface BlockProps {
    header: ReactNode;
    description: ReactNode;
    className?: string;
}

export interface AboutGuardData {
    mainBlock?: BlockProps;
    asideBlocks?: BlockProps[];
    bottomBlock?: BlockProps;
}

type Props = ComponentPropsWithoutRef<'div'> & {
    data: AboutGuardData;
};

export const AboutGuard: React.FC<Props> = memo(function AboutGuardComponent(props) {
    const {
        className,
        data: { mainBlock, asideBlocks, bottomBlock },
    } = props;
    return (
        <Container className={classNames(styles['guard-container'], className)}>
            <div className={styles.grid_1}>
                {mainBlock && (
                    <Card className={styles.guardPart}>
                        {mainBlock.header}
                        {mainBlock.description}
                        <div className={styles.img} />
                    </Card>
                )}

                {asideBlocks && (
                    <div className={styles.grid_2}>
                        {asideBlocks.map((item, i) => (
                            <Card key={i}>
                                {item.header}
                                {item.description}
                            </Card>
                        ))}
                    </div>
                )}
            </div>
            {bottomBlock && (
                <Card className={styles.banksPart}>
                    <div>
                        {bottomBlock.header}
                        {bottomBlock.description}
                    </div>
                    <div className={styles.img} />
                </Card>
            )}
        </Container>
    );
});
