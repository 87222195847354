import { IOrganizationExtended } from '../../../../services/vitrins/vitrins.types';
import { REVIEWS_PAGE_SIZE, MIN_REVIEWS_COUNT, ORGANIZATION_REVIEWS_MIN_COUNT } from '../../../../constants';

export interface IReview {
    id: number;
    alias: string;
    locationName: string;
    locationId: number;
    logo: string;
    authorName: string;
    isPremiumPartner: boolean;
    partnerCard: boolean;
    rating: number;
    title: string;
    text: string;
    reviewObjectId: number;
    date: string;
    organizationName: string;
    averageRating?: number;
}

const REVIEWS_COUNT = 4;

export const decorateReviews = (items: IReview[] | null, organization?: IOrganizationExtended): IReview[] => {
    if (organization) {
        if (!items || items.length < ORGANIZATION_REVIEWS_MIN_COUNT) {
            return [];
        }
        return items.slice(0, REVIEWS_COUNT);
    }

    const ids: number[] = [];
    const reviews = (items || []).reduce((output: IReview[], item: IReview) => {
        if ((item.alias || item.organizationName) && item.logo) {
            if (ids.every((elem) => elem !== item.reviewObjectId)) {
                ids.push(item.reviewObjectId);
                output.push(item);
            }
        }

        return output.length > REVIEWS_PAGE_SIZE ? output.slice(0, REVIEWS_PAGE_SIZE) : output;
    }, []);

    return reviews.length > MIN_REVIEWS_COUNT ? reviews : [];
};
