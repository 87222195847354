import { FORM_FIELDS } from './form.constants';
import {
    EAdditionalPhoneOwner,
    ECreditPurpose,
    ECreditSecurity,
    EDependents,
    EEducation,
    EEmployerType,
    EEmploymentType,
    EFamilyStatus,
    EGender,
    EHaveCar,
    EIncomeConfirmation,
    EJobPosition,
    EOrganizationActivity,
    EPropertyType,
    ERelationStatus,
    EUnemploymentReason,
    EWorkExperience,
} from '../services/profile.graphql/profile.sdk';

export const PROFILE_HABITATION_FIELDS_TO_DADATA_MAP = {
    area: 'area',
    areaType: 'area_type',
    block: 'block',
    blockType: 'block_type',
    city: 'city',
    cityType: 'city_type',
    dadata: 'dadata',
    fiasId: 'fias_id',
    fiasLevel: 'fias_level',
    flat: 'flat',
    flatType: 'flat_type_full',
    house: 'house',
    houseType: 'house_type',
    kladrId: 'kladr_id',
    postalCode: 'postal_code',
    region: 'region',
    regionType: 'region_type',
    settlement: 'settlement',
    settlementType: 'settlement_type',
    street: 'street',
    streetType: 'street_type',
};

export const PROFILE_ESIA_OWNER_FIELDS_MAP = {
    // Параметры кредита
    [FORM_FIELDS.FIRST_NAME]: 'firstName',
    [FORM_FIELDS.LAST_NAME]: 'lastName',
    [FORM_FIELDS.MIDDLE_NAME]: 'middleName',
    [FORM_FIELDS.GENDER]: 'gender',
    [FORM_FIELDS.EMAIL]: 'email',

    // Паспортные данные

    [FORM_FIELDS.BIRTHDAY]: 'birthDate',
    [FORM_FIELDS.BIRTH_PLACE]: 'birthPlace',

    [FORM_FIELDS.REGISTRATION_STREET]: 'registrationHabitation.address.dadata',
    [FORM_FIELDS.REGISTRATION_APARTMENT]: 'registrationHabitation.address.flat',
    [FORM_FIELDS.REGISTRATION_DADATA_FULL]: 'registrationHabitation.address',

    [FORM_FIELDS.RESIDENCE_STREET]: 'livingHabitation.address.dadata',
    [FORM_FIELDS.RESIDENCE_APARTMENT]: 'livingHabitation.address.flat',
    [FORM_FIELDS.RESIDENCE_DADATA_FULL]: 'livingHabitation.address',

    //   Доп информация

    [FORM_FIELDS.SNILS]: 'snils',
} as const;

export const PROFILE_ESIA_PASSPORT_FIELDS_MAP = {
    // Паспортные данные, серия и номер обрабатываются в _convertEsiaPassportToFormData

    [FORM_FIELDS.UNIT_CODE]: 'issueId',
    [FORM_FIELDS.ISSUED]: 'issuedBy',
    [FORM_FIELDS.PASSPORT_DATE]: 'issueDate',
} as const;

export const PROFILE_PEOPLE_FIELDS_MAP = {
    // Параметры кредита

    [FORM_FIELDS.FIRST_NAME]: 'firstName',
    [FORM_FIELDS.LAST_NAME]: 'lastName',
    [FORM_FIELDS.MIDDLE_NAME]: 'middleName',
    [FORM_FIELDS.EMAIL]: 'email',
    [FORM_FIELDS.GENDER]: 'gender',
    [FORM_FIELDS.AMOUNT]: 'creditSelection.amount',
    [FORM_FIELDS.PERIOD]: 'creditSelection.period',
    [FORM_FIELDS.PURPOSE]: 'creditSelection.purpose',
    [FORM_FIELDS.REGION]: 'creditSelection.region',
    [FORM_FIELDS.REGION_ID]: 'creditSelection.obtainCityId',
    [FORM_FIELDS.AUTO_BRAND]: 'creditSelection.autoBrand',
    [FORM_FIELDS.AUTO_MODEL]: 'creditSelection.autoModel',
    [FORM_FIELDS.AUTO_YEAR]: 'creditSelection.autoYear',
    [FORM_FIELDS.INITIAL_FEE]: 'creditSelection.initialFee',
    [FORM_FIELDS.CREDIT_SECURITY]: 'creditSelection.creditSecurity',
    [FORM_FIELDS.REGION_LOCATION_ROUTE]: 'creditSelection.obtainCityLocationRoute',

    // Паспортные данные

    [FORM_FIELDS.PASSPORT_NUMBER]: 'passport.number',
    [FORM_FIELDS.ISSUED]: 'passport.issuedBy',
    [FORM_FIELDS.UNIT_CODE]: 'passport.issuedByCode',
    [FORM_FIELDS.PASSPORT_DATE]: 'passport.issueDate',
    [FORM_FIELDS.REGISTRATION_DATE]: 'passport.addressRegistrationDate',
    [FORM_FIELDS.BIRTHDAY]: 'birthDate',
    [FORM_FIELDS.BIRTH_PLACE]: 'birthPlace',

    [FORM_FIELDS.REGISTRATION_STREET]: 'registrationHabitation.address.dadata',
    [FORM_FIELDS.REGISTRATION_APARTMENT]: 'registrationHabitation.address.flat',
    [FORM_FIELDS.REGISTRATION_ROUTE]: 'registrationHabitation.address.locationRoute',

    [FORM_FIELDS.REGISTRATION_DADATA_FULL]: 'registrationHabitation.address',

    [FORM_FIELDS.RESIDENCE_STREET]: 'livingHabitation.address.dadata',
    [FORM_FIELDS.RESIDENCE_APARTMENT]: 'livingHabitation.address.flat',
    [FORM_FIELDS.RESIDENCE_ROUTE]: 'livingHabitation.address.locationRoute',

    [FORM_FIELDS.RESIDENCE_DADATA_FULL]: 'livingHabitation.address',

    //   Доп информация

    [FORM_FIELDS.EDUCATION]: 'education',
    [FORM_FIELDS.FAMILY_STATUS]: 'maritalStatus',
    [FORM_FIELDS.NUMBER_OF_DEPENDENTS]: 'dependentsCount',
    [FORM_FIELDS.ADDITIONAL_PHONE]: 'additionalPhone',
    [FORM_FIELDS.ADDITIONAL_PHONE_OWNER]: 'additionalPhoneOwner',
    [FORM_FIELDS.ADDITIONAL_PHONE_OWNER_FIO]: 'additionalPhoneOwnerFIO',
    [FORM_FIELDS.SNILS]: 'snils',
    [FORM_FIELDS.CAR]: 'haveCar',
    [FORM_FIELDS.PROPERTY_TYPE]: 'haveApartment',
    [FORM_FIELDS.RENT_APARTMENT]: 'flatRentAmount',
    [FORM_FIELDS.ADDITIONAL_INCOME]: 'additionalIncome',

    // Место работы

    [FORM_FIELDS.EMPLOYMENT_TYPE]: 'employmentType',
    [FORM_FIELDS.MONTHLY_SALARY]: 'monthlyIncome',
    [FORM_FIELDS.JOB_TITLE]: 'job.title',
    [FORM_FIELDS.JOB_TYPE]: 'job.type',
    [FORM_FIELDS.LAST_EXPERIENCE]: 'job.lastExperienceStartDate',
    [FORM_FIELDS.ORGANIZATION_NAME]: 'job.organization.name',
    [FORM_FIELDS.EMPLOYER_INN]: 'job.organization.inn',
    [FORM_FIELDS.AREA_OF_EMPLOYMENT]: 'job.organization.activity',
    [FORM_FIELDS.EMPLOYER_TYPE]: 'job.organization.type',
    [FORM_FIELDS.WORK_PHONE]: 'job.organization.phone',

    [FORM_FIELDS.WORK_ADDRESS]: 'job.address.dadata',
    [FORM_FIELDS.WORK_ROUTE]: 'job.address.locationRoute',
    [FORM_FIELDS.SENIORITY]: 'workExperience',
    [FORM_FIELDS.INCOME_CONFIRMATION]: 'solvencyProof',
    [FORM_FIELDS.UNEMPLOYMENT_REASON]: 'unemploymentReason',
    [FORM_FIELDS.ANOTHER_UNEMPLOYMENT_REASON]: 'unemploymentReasonText',

    // Залог недвижимости

    [FORM_FIELDS.PLEDGE_OWNER_SINGLE]: 'creditSelectionPledgeHabitation.creditSelection.pledgeOwnerSingle',
    [FORM_FIELDS.PLEDGE_OWNER_RELATIONSHIP]: 'creditSelectionPledgeHabitation.creditSelection.pledgeOwnerRelationship',
    [FORM_FIELDS.PLEDGE_OWNER_FAMILY_STATUS]: 'creditSelectionPledgeHabitation.creditSelection.pledgeOwnerFamilyStatus',
    [FORM_FIELDS.PLEDGE_OWNER_FIRST_NAME]: 'creditSelectionPledgeHabitation.creditSelection.pledgeOwnerFirstName',
    [FORM_FIELDS.PLEDGE_OWNER_LAST_NAME]: 'creditSelectionPledgeHabitation.creditSelection.pledgeOwnerLastName',
    [FORM_FIELDS.PLEDGE_OWNER_MIDDLE_NAME]: 'creditSelectionPledgeHabitation.creditSelection.pledgeOwnerMiddleName',
    [FORM_FIELDS.PLEDGE_OWNER_PHONE]: 'creditSelectionPledgeHabitation.creditSelection.pledgeOwnerPhone',

    [FORM_FIELDS.PLEDGE_DADATA_FULL]: 'creditSelectionPledgeHabitation.address',
    [FORM_FIELDS.PLEDGE_REALTY_STREET]: 'creditSelectionPledgeHabitation.address.dadata',
    [FORM_FIELDS.PLEDGE_REALTY_BUILDING]: 'creditSelectionPledgeHabitation.address.block',
    [FORM_FIELDS.PLEDGE_REALTY_FLAT]: 'creditSelectionPledgeHabitation.address.flat',
    [FORM_FIELDS.PLEDGE_REALTY_HOUSE]: 'creditSelectionPledgeHabitation.address.house',

    // Залог авто

    [FORM_FIELDS.PLEDGE_AUTO_NUMBER]: 'creditSelectionPledgeCar.registrationPlate',
    [FORM_FIELDS.PLEDGE_AUTO_BRAND]: 'creditSelectionPledgeCar.mark',
    [FORM_FIELDS.PLEDGE_AUTO_BRAND_ID]: 'creditSelectionPledgeCar.markId',
    [FORM_FIELDS.PLEDGE_AUTO_MODEL]: 'creditSelectionPledgeCar.model',
    [FORM_FIELDS.PLEDGE_AUTO_MODEL_ID]: 'creditSelectionPledgeCar.modelId',
    [FORM_FIELDS.PLEDGE_AUTO_YEAR]: 'creditSelectionPledgeCar.year',
    [FORM_FIELDS.PLEDGE_AUTO_POWER]: 'creditSelectionPledgeCar.power',
} as const;

export const CLEARABLE_PROFILE_PEOPLE_DADATA_FIELDS = ['middleName', 'flat', 'flatType', 'blockType', 'block'];

export const REQUIRED_FIELDS_FOR_PROFILE_PEOPLE_UPDATE = [
    FORM_FIELDS.FIRST_NAME,
    FORM_FIELDS.LAST_NAME,
    FORM_FIELDS.BIRTHDAY,
];

export const FIELDS_FOR_PLEDGE_REALTY = [
    FORM_FIELDS.PLEDGE_OWNER_SINGLE,
    FORM_FIELDS.PLEDGE_OWNER_RELATIONSHIP,
    FORM_FIELDS.PLEDGE_OWNER_FAMILY_STATUS,
    FORM_FIELDS.PLEDGE_OWNER_FIRST_NAME,
    FORM_FIELDS.PLEDGE_OWNER_LAST_NAME,
    FORM_FIELDS.PLEDGE_OWNER_MIDDLE_NAME,
    FORM_FIELDS.PLEDGE_OWNER_PHONE,
    FORM_FIELDS.PLEDGE_DADATA_FULL,
    FORM_FIELDS.PLEDGE_REALTY_STREET,
    FORM_FIELDS.PLEDGE_REALTY_BUILDING,
    FORM_FIELDS.PLEDGE_REALTY_FLAT,
    FORM_FIELDS.PLEDGE_REALTY_HOUSE,
];

export const REQUIRED_FIELDS_FOR_PLEDGE_REALTY_UPDATE = [FORM_FIELDS.PLEDGE_DADATA_FULL];

export const FIELDS_FOR_PLEDGE_AUTO = [
    FORM_FIELDS.PLEDGE_AUTO_NUMBER,
    FORM_FIELDS.PLEDGE_AUTO_BRAND,
    FORM_FIELDS.PLEDGE_AUTO_BRAND_ID,
    FORM_FIELDS.PLEDGE_AUTO_MODEL,
    FORM_FIELDS.PLEDGE_AUTO_MODEL_ID,
    FORM_FIELDS.PLEDGE_AUTO_YEAR,
    FORM_FIELDS.PLEDGE_AUTO_POWER,
];
export const REQUIRED_FIELDS_FOR_PLEDGE_AUTO_UPDATE = [
    FORM_FIELDS.PLEDGE_AUTO_BRAND,
    FORM_FIELDS.PLEDGE_AUTO_MODEL,
    FORM_FIELDS.PLEDGE_AUTO_YEAR,
];

export const DADATA_FORM_FIELDS_MAP = {
    [FORM_FIELDS.RESIDENCE_DADATA_FULL]: [
        FORM_FIELDS.RESIDENCE_STREET,
        FORM_FIELDS.RESIDENCE_APARTMENT,
        FORM_FIELDS.RESIDENCE_ROUTE,
    ],
    [FORM_FIELDS.REGISTRATION_DADATA_FULL]: [
        FORM_FIELDS.REGISTRATION_STREET,
        FORM_FIELDS.REGISTRATION_APARTMENT,
        FORM_FIELDS.REGISTRATION_ROUTE,
    ],
    [FORM_FIELDS.PLEDGE_DADATA_FULL]: [
        FORM_FIELDS.PLEDGE_REALTY_STREET,
        FORM_FIELDS.PLEDGE_REALTY_BUILDING,
        FORM_FIELDS.PLEDGE_REALTY_FLAT,
        FORM_FIELDS.PLEDGE_REALTY_HOUSE,
        FORM_FIELDS.PLEDGE_REALTY_STRUCTURE,
    ],
};

export const CLEARABLE_FORM_FIELDS = [
    FORM_FIELDS.MIDDLE_NAME,
    FORM_FIELDS.RESIDENCE_APARTMENT,
    FORM_FIELDS.REGISTRATION_APARTMENT,
    FORM_FIELDS.PLEDGE_REALTY_FLAT,
    FORM_FIELDS.PLEDGE_REALTY_STRUCTURE,
];

export const CLEARABLE_PEOPLE_FIELDS = {
    DADATA: ['flat', 'flatType', 'blockType', 'block'],
    OTHER: ['middleName'],
};

// TODO: привести ВСЕ значения Sources в анкете к значениям из PS

export const PROFILE_SOURCES_MAP = {
    [FORM_FIELDS.PURPOSE]: ECreditPurpose,
    [FORM_FIELDS.GENDER]: EGender,
    [FORM_FIELDS.NUMBER_OF_DEPENDENTS]: EDependents,
    [FORM_FIELDS.EMPLOYMENT_TYPE]: EEmploymentType,
    [FORM_FIELDS.ADDITIONAL_PHONE_OWNER]: EAdditionalPhoneOwner,
    [FORM_FIELDS.JOB_TYPE]: EJobPosition,
    [FORM_FIELDS.AREA_OF_EMPLOYMENT]: EOrganizationActivity,
    [FORM_FIELDS.EMPLOYER_TYPE]: EEmployerType,
    [FORM_FIELDS.EDUCATION]: EEducation,
    [FORM_FIELDS.FAMILY_STATUS]: EFamilyStatus,
    [FORM_FIELDS.CREDIT_SECURITY]: ECreditSecurity,
    [FORM_FIELDS.CAR]: EHaveCar,
    [FORM_FIELDS.PROPERTY_TYPE]: EPropertyType,
    [FORM_FIELDS.SENIORITY]: EWorkExperience,
    [FORM_FIELDS.PLEDGE_OWNER_FAMILY_STATUS]: EFamilyStatus,
    [FORM_FIELDS.PLEDGE_OWNER_RELATIONSHIP]: ERelationStatus,
    [FORM_FIELDS.UNEMPLOYMENT_REASON]: EUnemploymentReason,
    [FORM_FIELDS.INCOME_CONFIRMATION]: EIncomeConfirmation,
};

export const NUMBER_OF_DEPENDENTS_MAP = {
    [EDependents.Zero]: 'None',
    [EDependents.One]: '1',
    [EDependents.Two]: '2',
    [EDependents.Three]: '3',
    [EDependents.MoreThanThree]: 'MoreThanThree',
} as Record<string, string>;

export const EDUCATION_MAP = {
    [EEducation.AcademicDegree]: 'AcademicDegree',
    [EEducation.Elementary]: 'Primary',
    [EEducation.Higher]: 'Higher',
    [EEducation.IncompleteHigher]: 'IncompleteHigher',
    [EEducation.Primary]: 'SecondSpecial',
    [EEducation.SecondHigher]: 'SecondHigher',
} as Record<string, string>;

export const JOB_TYPE_MAP = {
    [EJobPosition.HeadOfDepartment]: 'headOfDepartment',
    [EJobPosition.HeadOfOrganization]: 'headOfOrganization',
    [EJobPosition.ServiceStaff]: 'serviceStaff',
    [EJobPosition.Specialist]: 'specialist',
} as Record<string, string>;

export const SENIORITY_MAP = {
    [EWorkExperience.LessOneYear]: 'P0Y',
    [EWorkExperience.BetweenOneAndThreeYears]: 'P1Y',
    [EWorkExperience.BetweenThreeAndTenYears]: 'P3Y',
    [EWorkExperience.MoreTenYears]: 'P10Y',
} as Record<string, string>;

export const INCOME_CONFIRMATION_MAP = {
    [EIncomeConfirmation.None]: 'None',
    [EIncomeConfirmation.Any]: 'None',
    [EIncomeConfirmation.Ndfl2]: 'Ndfl2',
    [EIncomeConfirmation.Ndfl3]: 'Ndfl3',
    [EIncomeConfirmation.BankForm]: 'BankForm',
} as Record<string, string>;

export const EMPLOYER_TYPE_MAP = {
    [EEmployerType.Ao]: 'AO',
    [EEmployerType.Ip]: 'IP',
    [EEmployerType.Nao]: 'NAO',
    // [EEmployerType.Oao]: 'OAO',
    [EEmployerType.Ooo]: 'OOO',
    [EEmployerType.Pao]: 'PAO',
    // [EEmployerType.Zao]: 'ZAO',
    [EEmployerType.Branch]: 'Branch',
    [EEmployerType.DontKnow]: 'DontKnow',
    [EEmployerType.FederalStateTreasury]: 'FederalStateTreasury',
    [EEmployerType.MunicipalInstitution]: 'MunicipalInstitution',
    [EEmployerType.StateInstitution]: 'StateInstitution',
} as Record<string, string>;

export const PROFILE_LEGACY_SOURCES_MAP = {
    [FORM_FIELDS.SENIORITY]: SENIORITY_MAP,
    [FORM_FIELDS.JOB_TYPE]: JOB_TYPE_MAP,
    [FORM_FIELDS.EDUCATION]: EDUCATION_MAP,
    [FORM_FIELDS.NUMBER_OF_DEPENDENTS]: NUMBER_OF_DEPENDENTS_MAP,
    [FORM_FIELDS.INCOME_CONFIRMATION]: INCOME_CONFIRMATION_MAP,
    [FORM_FIELDS.EMPLOYER_TYPE]: EMPLOYER_TYPE_MAP,
};
