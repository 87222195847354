export const GRAY_BANKS_LOGOS = [
    {
        url: '/resources/selection/credit-cards-landing/gray-banks-logos/alfabank.svg',
        name: 'Alfa Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/gray-banks-logos/home_bank.svg',
        name: 'Home Credit Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/gray-banks-logos/pochtabank.svg',
        name: 'PochtaBank',
    },
    {
        url: '/resources/selection/credit-cards-landing/gray-banks-logos/renessans.svg',
        name: 'Renessans Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/gray-banks-logos/rsh_bank.svg',
        name: 'RosSelhos Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/gray-banks-logos/t_bank.svg',
        name: 'Tinkoff Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/gray-banks-logos/vtb.svg',
        name: 'VTB Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/gray-banks-logos/khlyn.svg',
        name: 'Khlynov',
    },
];
