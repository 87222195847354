import React, { useEffect } from 'react';
import { ApplicationLoad } from './application.load';
import { ScoringLoad } from './scoring.load';
import { SelectionLoad } from './selection.load';
import { BanksLoad } from './banks.load';
import { Container } from '../../common';
import { Card } from '@sravni/react-design-system';
import styles from './styles.module.scss';

type Props = {
    variant: number;
    /** Чтобы картинка не показывалась при коротком отображении лоадера */
    shortDuration?: boolean;
};

const ActiveComponent: React.FC<Props> = ({ variant, shortDuration }) => {
    const Component =
        {
            0: ApplicationLoad,
            1: ScoringLoad,
            2: SelectionLoad,
            3: BanksLoad,
        }[variant] || (() => null);

    useEffect(() => {
        /** Прелоадим первую картинку, чтобы не было прыжка */
        import('./animations/application.svg');
    }, []);

    return (
        <Container className={styles.activeComponent}>
            <Card className={styles.card} size={16}>
                {!shortDuration && <Component />}
            </Card>
        </Container>
    );
};

const Memoized = React.memo(ActiveComponent);

export { Memoized as ActiveComponent };
