import { FC, ComponentPropsWithoutRef, memo } from 'react';
import {
    LogoCarouselItemProps,
    LogoCarousel as SharedLogoCarousel,
} from '@sravni/react-shared-components/lib/LogoCarousel';
import { GRAY_BANKS_LOGOS } from './constants';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const BanksLogo: FC<ComponentPropsWithoutRef<'div'>> = memo(function BanksLogoComponent({ className }) {
    return (
        <div className={classNames(styles.root, className)}>
            <SharedLogoCarousel
                className={styles.carousel}
                items={GRAY_BANKS_LOGOS.map(
                    ({ url, name }): LogoCarouselItemProps => ({
                        alt: name,
                        url,
                    }),
                )}
            />
        </div>
    );
});
