import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';

import type { IGlobalState } from '../index';
import { INT_ADS_FB_MAP } from '../../constants';

interface IState {
    crossData?:
        | {
              [key in keyof typeof INT_ADS_FB_MAP]?: string;
          }
        | null;
    adsData?:
        | {
              [key in keyof typeof INT_ADS_FB_MAP]?: string;
          }
        | null;
}

export const setAdvData = createAction<IState>('set advData');

export const useAdvData = () => {
    return useSelector((state: IGlobalState) => state.advData as IState);
};

export const advDataReducer = createReducer({}, {} as IState).on(setAdvData, (_, data) => data);
