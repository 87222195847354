import { createAction, createReducer } from 'redux-act';
import { IMarketplaceFilterParams } from './types';

interface IState {
    params: IMarketplaceFilterParams;
}

const defaultState: IState = {
    params: {
        isManuallyChanged: false,
    },
};

export const resetIsManuallyChanged = createAction('marketplace/filters/resetIsManuallyChanged');

export const setParams = createAction<Partial<IMarketplaceFilterParams>>('marketplace/filters/setParams');
export const setParamsSilent = createAction<Partial<IMarketplaceFilterParams>>('marketplace/filters/setParamsSilent');

export const marketplaceFilterReducer = createReducer((on) => {
    on(resetIsManuallyChanged, (state) => ({
        ...state,
        params: {
            ...state.params,
            isManuallyChanged: false,
        },
    }));
    on(setParams, (state, payload) => ({
        ...state,
        params: {
            ...state.params,
            ...payload,
            isManuallyChanged: true,
        },
    }));
    on(setParamsSilent, (state, payload) => ({
        ...state,
        params: {
            ...state.params,
            ...payload,
        },
    }));
}, defaultState);
