import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export type Props = React.ComponentProps<'div'> & {
    gap?: '24' | '16' | '12' | '8' | '4';
    mobileGap?: '16' | '12' | '8' | '4';
};

export const Rows: React.FC<Props> = ({ className, gap = '24', mobileGap = '16', ...other }) => {
    return React.createElement('div', {
        className: cn(className, styles.rows, {
            [styles[`row_gap-${gap}`]]: !!gap,
            [styles[`row_mobile-gap-${mobileGap}`]]: !!mobileGap,
        }),
        ...other,
    });
};

export const Row: React.FC<Props> = ({ className, gap = '24', mobileGap = '16', ...other }) => {
    return React.createElement('div', {
        className: cn(className, styles.row, {
            [styles[`row_gap-${gap}`]]: !!gap,
            [styles[`row_mobile-gap-${mobileGap}`]]: !!mobileGap,
        }),
        ...other,
    });
};

export type MediaAttrs = Partial<{
    ['md-1']: boolean;
    ['md-2']: boolean;
    ['md-3']: boolean;
    ['md-4']: boolean;
    ['md-5']: boolean;
    ['md-6']: boolean;
    ['md-7']: boolean;
    ['md-8']: boolean;
    ['md-9']: boolean;
    ['md-10']: boolean;
    ['md-11']: boolean;
    ['md-12']: boolean;
    ['sm-1']: boolean;
    ['sm-2']: boolean;
    ['sm-3']: boolean;
    ['sm-4']: boolean;
    ['sm-5']: boolean;
    ['sm-6']: boolean;
    ['sm-7']: boolean;
    ['sm-8']: boolean;
    ['sm-9']: boolean;
    ['sm-10']: boolean;
    ['sm-11']: boolean;
    ['sm-12']: boolean;
}>;

export const Col: React.FC<React.ComponentProps<'div'> & MediaAttrs> = ({ className, ...props }) => {
    const { other, media } = (Object.keys(props) as Array<keyof typeof props>).reduce<{
        other: React.ComponentProps<'div'>;
        media: { [key: string]: keyof MediaAttrs };
    }>(
        (acc, current) => {
            if (/^(md|sm)-\d+$/.test(current)) {
                acc.media[`data-${current}`] = props[current];
            } else {
                acc.other[current as keyof typeof acc.other] = props[current];
            }
            return acc;
        },
        { other: {}, media: {} },
    );
    return React.createElement('div', { className: cn(className, styles.col), ...other, ...media });
};
