export const UTM_MEDIUM_VALUES = {
    PAID_TRAFFIC: [
        'olv',
        'cpc_out',
        'cpm_out',
        'tv_out',
        'cpc',
        'cpm',
        'display',
        'bloggers',
        'smm_cpc',
        'smm_paid',
        'cpc_paid',
        'cpm_paid',
    ],
    ORGANIC_TRAFFIC: ['organic'],
};
