import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../';

type IState = {
    unsignedDocuments: Array<MPL.UnsignedDocument>;
};

const initialState: IState = {
    unsignedDocuments: [],
};

export const setEsiaUnsignedDocuments = createAction<IState['unsignedDocuments']>('set esia unsigned documents');

export const esiaReducer = createReducer<IState>({}, initialState).on(setEsiaUnsignedDocuments, (state, payload) => ({
    ...state,
    unsignedDocuments: payload,
}));

export const useEsiaUnsignedDocs = () => {
    return useSelector((state: IGlobalState) => state.esia.unsignedDocuments);
};
