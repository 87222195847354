import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useIsMobile } from '@sravni/react-utils';
import { MobileHeader, DesktopHeader } from '@sravni/react-header';
import { SberPromo } from '@sravni/react-shared-components/lib/SberPromo';
import { GlobalSearch } from '@sravni/react-search/lib/GlobalSearch';
import { IGlobalState } from '../../../store';
import { useUser } from '../../../store/reducers/user';
import getConfig from 'next/config';

const Wrapper = styled.div`
    position: relative;
    z-index: 15;
`;

export const Header = () => {
    const user = useUser();
    const isMobile = useIsMobile();
    const settings = useSelector((state: IGlobalState) => state.siteSettings.header);
    const locations = useSelector((state: IGlobalState) => state.locations);
    const Component = isMobile ? MobileHeader : DesktopHeader;
    const { asPath } = useRouter();
    const { alias: regionAlias } = useSelector((state: IGlobalState) => state.locations.currentLocation);
    const searchUrl = getConfig().publicRuntimeConfig.publicUrl;

    const formatPath = (_location: any) => {
        let url = asPath;
        if (url.match(regionAlias)) {
            url = url.replace(`${regionAlias}/`, '');
        }
        return url;
    };

    return (
        <Wrapper>
            <Component
                serviceURL={asPath}
                regions={locations.centers}
                settings={settings}
                formatPath={formatPath}
                user={user || undefined}
                promo={<SberPromo />}
                enableTheme
                search={<GlobalSearch searchServiceHost={searchUrl} asPath={asPath} />}
            />
        </Wrapper>
    );
};
