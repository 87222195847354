import React from 'react';
import Head from 'next/head';
import { Seo } from '@sravni/types/lib/seo';
interface Props {
    seo: Partial<Seo>;
    realPath: string;
}

export const SeoHead: React.FC<Props> = ({ seo, realPath }) => (
    <Head>
        <title>{seo.title}</title>
        <meta name="robots" content={seo.indexingDisabled ? 'noindex, nofollow' : 'index, follow'} />
        <meta name="description" content={seo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={seo.ogDescription} />
        <meta property="og:title" content={seo.ogTitle} />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(seo.schema) }} />
        <link rel="canonical" href={!!seo && seo.canonical ? seo.canonical : `https://www.sravni.ru${realPath}`} />
        <meta property="og:image" content="https://f.sravni.ru/f/_/logo_200x200.jpg" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />

        <link rel="preconnect" href="https://vk.com" />
        <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
        <link rel="preconnect" href="https://static.server.retentioneering.com" />
    </Head>
);
