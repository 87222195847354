import { createReducer, createAction } from 'redux-act';
import { ISiteSettings } from '@sravni/types/lib/sitesettings';
import { IYMetrics } from '../../services/settings';

export interface IState extends ISiteSettings {
    yandexMetrics?: IYMetrics;
}

const initialState = {
    header: {
        menu: undefined,
        mobileMenu: undefined,
    },
    footer: {
        menu: undefined,
        socialLinks: undefined,
        support: undefined,
    },
} as unknown as IState;

export const setSettings = createAction<Partial<IState>>('set sitesettings');

export const siteSettingsReducer = createReducer({}, initialState).on(setSettings, (state, data) => ({
    ...state,
    ...data,
}));
