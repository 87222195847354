import React, { useRef } from 'react';
import { Select as SelectInputBase } from '@sravni/react-design-system';
import { ErrorTextWrapper } from '../common';
import { wait } from '../../../helpers';
import { SelectValue } from '@sravni/react-design-system/dist/types/types/Select.types';
import { mergeRefs } from '@sravni/react-utils';

type Props = Omit<React.ComponentProps<typeof SelectInputBase>, 'onBlur'> & {
    errorMessage: React.ReactNode | undefined;
    additionalMessage?: React.ReactNode;
    onBlur?: (event?: SelectValue) => void;
    rows?: number;
    hideErrorMessage?: boolean;
};

export const SelectInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { errorMessage, additionalMessage, rows, style, onBlur, onChange, label, hideErrorMessage, ...other } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const isPropagateEvent = useRef<boolean>(false);

    const onChangeHandler: Props['onChange'] = async (value?) => {
        if (onChange) {
            onChange(value);
        }
        await wait(0);
        if (onBlur) {
            /** ooBlur не должен наступать перед onChange, а по умолчанию это так */
            onBlur(value);
        }
        const { current } = inputRef;
        if (current) {
            isPropagateEvent.current = true;
            current.blur();
        }
    };

    const onBlurHandler: React.FocusEventHandler = (event) => {
        if (!isPropagateEvent.current) {
            event.stopPropagation();
        } else {
            isPropagateEvent.current = false;
        }
    };

    return (
        <ErrorTextWrapper
            errorText={!hideErrorMessage && errorMessage}
            additionalText={additionalMessage}
            onBlur={onBlurHandler}>
            <SelectInputBase
                ref={mergeRefs(ref, inputRef)}
                label={label ? label : undefined}
                {...other}
                onChange={onChangeHandler}
                invalid={Boolean(errorMessage)}
            />
        </ErrorTextWrapper>
    );
});

SelectInput.displayName = 'SelectInput';
