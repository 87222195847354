import React from 'react';
import { format, formatPhone, mapEnToRu, mapRuToEng } from '../../../helpers';
import { ControllerRenderProps } from 'react-hook-form';

type FieldParam = Omit<ControllerRenderProps, 'onBlur'> & { onBlur?: () => void };

interface Transform {
    input: (field: FieldParam) => string;
    output: (field: FieldParam) => (event: React.FormEvent<HTMLInputElement>) => void;
}

/** in: "100 000", out: "100000" */
export const amountTransform: Transform = {
    input: (field) => {
        if (field.value === '' || field.value === undefined) return '';
        const numberValue = Number(field.value);
        return format(isNaN(numberValue) ? 0 : numberValue);
    },
    output: (field) => (event) => {
        const { currentTarget } = event;
        currentTarget.value = String(parseFloat(currentTarget.value.replace(/\D/g, '')));
        const numberValue = parseFloat(currentTarget.value.replace(/\D/g, ''));
        currentTarget.value = String(isNaN(numberValue) ? '' : numberValue);
        return field.onChange(event);
    },
};

/** in: "+7 (999) 999-99-99", out: "79999999999" Учитывает автоподстановку Safari */
export const phoneTransform: Transform = {
    input: (field) => {
        return formatPhone(field.value.replace(/\D/g, ''));
    },
    output: (field) => (event) => {
        const { currentTarget } = event;
        const phoneValue = currentTarget.value.replace(/\D/g, '').replace(/^[78]/, '');
        return field.onChange({
            ...event,
            currentTarget: {
                ...event.currentTarget,
                value: phoneValue ? `7${phoneValue}` : '',
            },
        });
    },
};

export const onlyNumbersOutput: Transform['output'] = (field) => (event) => {
    const { currentTarget } = event;
    currentTarget.value = currentTarget.value.replace(/\D/g, '');
    return field.onChange(event);
};

/** @deprecated некорректно работает в некоторых случаях */
export const onlyEnglishOutput: Transform['output'] = (field) => (event) => {
    const { currentTarget: target } = event;
    const valueFromEvent = target.value;
    const transpiled = mapRuToEng(valueFromEvent);
    if (transpiled !== valueFromEvent) {
        const { selectionStart } = target;
        target.value = transpiled;
        target.setSelectionRange(selectionStart || 0, selectionStart || 0);
    }
    return field.onChange(event);
};

export const onlyRussianOutput: Transform['output'] = (field) => (event) => {
    const { currentTarget: target } = event;
    const valueFromEvent = target.value;
    const transpiled = mapEnToRu(valueFromEvent);
    if (transpiled !== valueFromEvent) {
        const { selectionStart } = target;
        target.value = transpiled;
        target.setSelectionRange(selectionStart || 0, selectionStart || 0);
    }
    return field.onChange(event);
};
