import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    BirthDate: { input: any; output: any };
    CadastralNumber: { input: any; output: any };
    DadataResult: { input: any; output: any };
    DateTimeISO: { input: any; output: any };
    EmailAddress: { input: any; output: any };
    FiasLevel: { input: any; output: any };
    INN: { input: any; output: any };
    IssueId: { input: any; output: any };
    OGRN: { input: any; output: any };
    PassportNumber: { input: any; output: any };
    PassportSeries: { input: any; output: any };
    PhoneNumber: { input: any; output: any };
    RegistrationPlateScalar: { input: any; output: any };
    SNILS: { input: any; output: any };
    SerialNumber: { input: any; output: any };
    SerialNumberEPTS: { input: any; output: any };
    SerialNumberInternationalPassport: { input: any; output: any };
    SerialNumberTS: { input: any; output: any };
    SimpleString: { input: any; output: any };
    StrictString: { input: any; output: any };
    Void: { input: any; output: any };
    year: { input: any; output: any };
};

export type TCar = {
    __typename?: 'Car';
    /** Псевдоним объекта */
    alias?: Maybe<Scalars['String']['output']>;
    /** Цвет кузова */
    bodyColor?: Maybe<Scalars['String']['output']>;
    /** Номер кузова */
    bodyNumber?: Maybe<Scalars['String']['output']>;
    /** Id кузова в справочнике */
    bodyTypeId?: Maybe<Scalars['String']['output']>;
    /** Владелец авто */
    carOwner?: Maybe<TPeople>;
    /** Тип кузова */
    carcase?: Maybe<Scalars['String']['output']>;
    /** id типа кузова */
    carcaseid?: Maybe<Scalars['String']['output']>;
    casco?: Maybe<TNestedCarCasco>;
    /** Страхователь в расчете КАСКО */
    cascoInsurer?: Maybe<TPeople>;
    /** Категория авто по тех регламенту (A, B, C...) */
    category?: Maybe<ECarCategory>;
    /** Номер шасси */
    chassisNumber?: Maybe<Scalars['String']['output']>;
    /** Залоговое авто в кредитах */
    creditSelectionPledgeForPeople?: Maybe<Array<Maybe<TPeople>>>;
    diagnosticCard?: Maybe<TNestedCarDiagnosticCard>;
    /** Привод автомобиля */
    drive?: Maybe<ECarDrive>;
    /** Водители */
    drivers?: Maybe<Array<Maybe<TPeople>>>;
    ePts?: Maybe<TNestedCarEPts>;
    /** Объём двигателя */
    enginevolume?: Maybe<Scalars['String']['output']>;
    /** Уникальный хеш машины клиента */
    hash?: Maybe<Scalars['String']['output']>;
    /** КПП */
    kpp?: Maybe<EKpp>;
    /** Идентификатор КПП */
    kppId?: Maybe<Scalars['String']['output']>;
    /** Ссылка на логотип марки автомобиля */
    logoLink?: Maybe<Scalars['String']['output']>;
    /** Марка */
    mark: Scalars['String']['output'];
    /** Id марки в справочнике ОСАГО */
    markId?: Maybe<Scalars['String']['output']>;
    /** Модель */
    model: Scalars['String']['output'];
    /** Id модели в справочнике ОСАГО */
    modelId?: Maybe<Scalars['String']['output']>;
    /** Название модификации машины */
    modification?: Maybe<Scalars['String']['output']>;
    /**
     * Номер СТС
     * @deprecated Не использовать. Старое поле для стс.
     */
    number?: Maybe<Scalars['String']['output']>;
    osago?: Maybe<TNestedCarOsago>;
    /** Страхователь авто в ОСАГО */
    osagoInsurer?: Maybe<TPeople>;
    /** Водители в расчете ОСАГО */
    osagoSelectedDrivers?: Maybe<Array<Maybe<TPeople>>>;
    /** Залоговое авто в кредитах */
    pledger?: Maybe<TPeople>;
    /** Мощность двигателя */
    power?: Maybe<Scalars['String']['output']>;
    /** Введенный вручную вне какого-либо продукта автомобиль */
    profileFromManualInput?: Maybe<TProfile>;
    pts?: Maybe<TNestedCarPts>;
    /** Регистрационный знак */
    registrationPlate?: Maybe<Scalars['RegistrationPlateScalar']['output']>;
    /** Год начала владения */
    startOwnershipYear?: Maybe<Scalars['year']['output']>;
    sts?: Maybe<TNestedCarSts>;
    /** Срок владения автомобилем */
    tenure?: Maybe<Scalars['String']['output']>;
    /** Тестовая ссылка */
    testo2o?: Maybe<TPeople>;
    updated?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Идентификационный номер транспортного средства (VIN)  */
    vin?: Maybe<Scalars['String']['output']>;
    /** Год выпуска */
    year: Scalars['year']['output'];
};

export type TDeleteProfileResult = {
    __typename?: 'DeleteProfileResult';
    formDeletedAmount?: Maybe<Scalars['Int']['output']>;
    profileLinksDeletedAmount?: Maybe<Scalars['Int']['output']>;
    profileObjectsDeletedAmount?: Maybe<Scalars['Int']['output']>;
    profileUpdateDataDeleteAmount?: Maybe<Scalars['Int']['output']>;
};

export enum EAdditionalPhoneOwner {
    FriendNumber = 'friendNumber',
    MyNumber = 'myNumber',
    RelativeNumber = 'relativeNumber',
}

export enum ECarCategory {
    A = 'A',
    A1 = 'A1',
    B = 'B',
    B1 = 'B1',
    Be = 'BE',
    C = 'C',
    C1 = 'C1',
    C1E = 'C1E',
    Ce = 'CE',
    D = 'D',
    D1 = 'D1',
    D1E = 'D1E',
    De = 'DE',
    M = 'M',
    Tb = 'Tb',
    Tm = 'Tm',
}

export enum ECarDrive {
    FrontWheel = 'frontWheel',
    FullWheel = 'fullWheel',
    RearWheel = 'rearWheel',
}

export enum ECitizenship {
    Ru = 'RU',
}

export enum ECreditPurpose {
    Money = 'money',
    Mortgage = 'mortgage',
    NewCar = 'newCar',
    RefinanceCredit = 'refinanceCredit',
    RefinanceMortgage = 'refinanceMortgage',
    UsedCar = 'usedCar',
}

export enum ECreditSecurity {
    NoMatter = 'noMatter',
    SecuredByAvailableRealEstate = 'securedByAvailableRealEstate',
    SecuredByAvailableVehicle = 'securedByAvailableVehicle',
    WithoutBail = 'withoutBail',
}

export enum EDependents {
    MoreThanThree = 'moreThanThree',
    One = 'one',
    Three = 'three',
    Two = 'two',
    Zero = 'zero',
}

export enum EDocumentRelevance {
    Actual = 'actual',
    Expired = 'expired',
    Invalid = 'invalid',
}

export enum EDocumentStatus {
    Unverified = 'unverified',
    VerifiedByPush = 'verifiedByPush',
    VerifiedByRequest = 'verifiedByRequest',
    VerifiedByValidate = 'verifiedByValidate',
}

export enum EDocumentType {
    FrgnPass = 'frgn_pass',
    RfDrivingLicense = 'rf_driving_license',
    RfPassport = 'rf_passport',
}

export enum EEducation {
    AcademicDegree = 'academicDegree',
    Elementary = 'elementary',
    Higher = 'higher',
    IncompleteHigher = 'incompleteHigher',
    Primary = 'primary',
    SecondHigher = 'secondHigher',
}

export enum EEmployerType {
    Ao = 'AO',
    Ip = 'IP',
    Nao = 'NAO',
    Oao = 'OAO',
    Ooo = 'OOO',
    Pao = 'PAO',
    Zao = 'ZAO',
    Branch = 'branch',
    DontKnow = 'dontKnow',
    FederalBudgetaryInstitution = 'federalBudgetaryInstitution',
    FederalStateTreasury = 'federalStateTreasury',
    MunicipalInstitution = 'municipalInstitution',
    StateInstitution = 'stateInstitution',
}

export enum EEmploymentType {
    Ip = 'IP',
    BusinessOwner = 'businessOwner',
    Employment = 'employment',
    Judge = 'judge',
    NotWorking = 'notWorking',
    Retired = 'retired',
    SelfEmployed = 'selfEmployed',
    Serviceman = 'serviceman',
    StateEmployee = 'stateEmployee',
}

export enum EFamilyStatus {
    CivilMarriage = 'civilMarriage',
    Divorced = 'divorced',
    Married = 'married',
    Single = 'single',
    Widow = 'widow',
}

export enum EGender {
    Female = 'female',
    Male = 'male',
}

export enum EHabitationFloorMaterials {
    Composite = 'composite',
    Concrete = 'concrete',
    Metal = 'metal',
    Other = 'other',
    Wood = 'wood',
}

export enum EHabitationType {
    Flat = 'flat',
}

export enum EHabitationWallMaterials {
    Block = 'block',
    Brick = 'brick',
    Composite = 'composite',
    Concrete = 'concrete',
    Metal = 'metal',
    Other = 'other',
    Panel = 'panel',
    Rock = 'rock',
    Wood = 'wood',
}

export enum EHaveCar {
    Domestic = 'domestic',
    Foreign = 'foreign',
    No = 'no',
}

export enum EIncomeConfirmation {
    Ndfl2 = 'NDFL2',
    Ndfl3 = 'NDFL3',
    Any = 'any',
    BankForm = 'bankForm',
    None = 'none',
}

export enum EJobPosition {
    CivilServant = 'civilServant',
    HeadOfDepartment = 'headOfDepartment',
    HeadOfOrganization = 'headOfOrganization',
    ServiceStaff = 'serviceStaff',
    Soldier = 'soldier',
    Specialist = 'specialist',
}

export enum EKpp {
    Automatic = 'automatic',
    Manual = 'manual',
}

export enum EObjectTypeName {
    Car = 'Car',
    EsiaHabitation = 'ESIAHabitation',
    EsiaPassport = 'ESIAPassport',
    Habitation = 'Habitation',
    Organization = 'Organization',
    People = 'People',
}

export enum EOrganizationActivity {
    AgricultureFishingHuntingForestry = 'agricultureFishingHuntingForestry',
    Building = 'building',
    CultureSportsActivities = 'cultureSportsActivities',
    DefenseLawEnforcementAgencies = 'defenseLawEnforcementAgencies',
    Education = 'education',
    ElectricityGasWaterHeatSupply = 'electricityGasWaterHeatSupply',
    FinanceInsurance = 'financeInsurance',
    HealthCareSocialServices = 'healthCareSocialServices',
    ManufacturingIndustry = 'manufacturingIndustry',
    MiningIndustry = 'miningIndustry',
    Other = 'other',
    ProfessionalScientificTechnicalActivities = 'professionalScientificTechnicalActivities',
    StateMunicipalAdministration = 'stateMunicipalAdministration',
    TradeServicesCommunications = 'tradeServicesCommunications',
    Transport = 'transport',
}

export enum EOsagoCarDocumentType {
    Epts = 'EPTS',
    Pts = 'PTS',
    Sts = 'STS',
}

export enum EPassportSource {
    Approved = 'approved',
    Default = 'default',
}

export enum EProfileChars {
    CarWantOnlyCredit = 'car_want_only_credit',
    GotMortWithoutIns = 'got_mort_without_ins',
    LowIncomeMfo = 'low_income_mfo',
    LowRevenueHighLoad = 'low_revenue_high_load',
    OnlyMfo = 'only_mfo',
    OsagoBoughtPolis = 'osago_bought_polis',
}

export enum EPropertyType {
    Apartments = 'apartments',
    CommercialRealEstate = 'commercialRealEstate',
    Flat = 'flat',
    Nothing = 'nothing',
}

export enum ERelationStatus {
    AnotherRelative = 'anotherRelative',
    Child = 'child',
    Other = 'other',
    Parent = 'parent',
    Spouse = 'spouse',
}

export type TEsiaDriverLicense = {
    __typename?: 'ESIADriverLicense';
    /** Дата рождения ESIA */
    birthDate?: Maybe<Scalars['BirthDate']['output']>;
    /** Место рождения ESIA */
    birthPlace?: Maybe<Scalars['String']['output']>;
    /** Категории ESIA */
    categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Страна выдачи ВУ ESIA */
    countryName?: Maybe<Scalars['String']['output']>;
    /** Ведомство ESIA */
    departmentDoc?: Maybe<Scalars['String']['output']>;
    /** Год начала стажа by ESIA */
    experience?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Действителен до ESIA */
    expiryDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Имя ESIA */
    firstName?: Maybe<Scalars['StrictString']['output']>;
    hash?: Maybe<Scalars['String']['output']>;
    /** ИД документа ESIA */
    id: Scalars['String']['output'];
    /** Дата выдачи ESIA */
    issueDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Кем выдан документ ESIA */
    issuedBy?: Maybe<Scalars['String']['output']>;
    /** Фамилия ESIA */
    lastName?: Maybe<Scalars['String']['output']>;
    /** Отчество ESIA */
    middleName?: Maybe<Scalars['String']['output']>;
    /** Номер ESIA */
    number?: Maybe<Scalars['String']['output']>;
    /** Водительское удостоверение (ЕСИА) */
    profile?: Maybe<TProfile>;
    /** Дата получения документа ESIA */
    receiptDocDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Релевантность ESIA */
    relevance?: Maybe<EDocumentRelevance>;
    /** Серия ESIA */
    series?: Maybe<Scalars['String']['output']>;
    /** Статус ESIA */
    status?: Maybe<EDocumentStatus>;
    /** Тип документа ESIA */
    type?: Maybe<EDocumentType>;
    updated?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Дата валидации документа ESIA */
    validateDateDoc?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TEsiaeWorkbook = {
    __typename?: 'ESIAEWorkbook';
    /** ЕСИА */
    departmentDoc?: Maybe<Scalars['String']['output']>;
    events?: Maybe<Array<Maybe<TNestedEsiaeWorkbookEvents>>>;
    /** ЕСИА */
    hash?: Maybe<Scalars['String']['output']>;
    /** Идентификатор электронной книжки ЕСИА */
    id: Scalars['String']['output'];
    /** Электронная книжка ЕСИА */
    profile?: Maybe<TProfile>;
    /** Трудовая книжка ЕСИА */
    receiptDocDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** ЕСИА */
    relevance?: Maybe<EDocumentRelevance>;
    /** ЕСИА */
    status?: Maybe<EDocumentStatus>;
    updated?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Трудовая книжка ЕСИА */
    validateDateDoc?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TEsiaHabitation = {
    __typename?: 'ESIAHabitation';
    address: TNestedEsiaHabitationAddress;
    /** В ручную подтвержденный адрес регистрации ЕСИА */
    approvedRegisteredPeople?: Maybe<TEsiaPeople>;
    hash?: Maybe<Scalars['String']['output']>;
    /** Адрес  проживания (ЕСИА) */
    livingPeople?: Maybe<TEsiaPeople>;
    /** Адрес  регистрации (ЕСИА) */
    registeredPeople?: Maybe<TEsiaPeople>;
    updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TEsiaPassport = {
    __typename?: 'ESIAPassport';
    /** Дата рождения ESIA */
    birthDate?: Maybe<Scalars['BirthDate']['output']>;
    /** Место рождения ESIA */
    birthPlace?: Maybe<Scalars['String']['output']>;
    /** Ведомство ESIA */
    departmentDoc?: Maybe<Scalars['String']['output']>;
    /** Имя ESIA */
    firstName?: Maybe<Scalars['StrictString']['output']>;
    /** Пол клиента ESIA */
    gender?: Maybe<EGender>;
    hash?: Maybe<Scalars['String']['output']>;
    /** ИД документа ESIA */
    id: Scalars['String']['output'];
    /** Дата выдачи ESIA */
    issueDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Код подразделения ESIA */
    issueId?: Maybe<Scalars['IssueId']['output']>;
    /** Кем выдан паспорт ESIA */
    issuedBy?: Maybe<Scalars['String']['output']>;
    /** Фамилия ESIA */
    lastName?: Maybe<Scalars['String']['output']>;
    /** Отчество ESIA */
    middleName?: Maybe<Scalars['String']['output']>;
    /** Номер ESIA */
    number?: Maybe<Scalars['String']['output']>;
    /** Статус паспорта ESIA */
    passportStatus?: Maybe<Scalars['String']['output']>;
    /** Тип паспорта ESIA */
    passportType?: Maybe<Scalars['String']['output']>;
    profile?: Maybe<TProfile>;
    /** Дата получения документа ESIA */
    receiptDocDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Релевантность ESIA */
    relevance?: Maybe<EDocumentRelevance>;
    /** Серия ESIA */
    series?: Maybe<Scalars['String']['output']>;
    /** Статус ESIA */
    status?: Maybe<EDocumentStatus>;
    /** Тип документа ESIA */
    type?: Maybe<EDocumentType>;
    updated?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Дата валидации документа ESIA */
    validateDateDoc?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TEsiaPeople = {
    __typename?: 'ESIAPeople';
    /** В ручную подтвержденный адрес регистрации ЕСИА */
    approvedRegistrationHabitation?: Maybe<TEsiaHabitation>;
    /** Дата рождения ESIA */
    birthDate: Scalars['BirthDate']['output'];
    /** Место рождения ESIA */
    birthPlace?: Maybe<Scalars['String']['output']>;
    /** Электронная почта ESIA */
    email?: Maybe<Scalars['EmailAddress']['output']>;
    /** Имя ESIA */
    firstName: Scalars['StrictString']['output'];
    /** Пол клиента ESIA */
    gender?: Maybe<EGender>;
    hash?: Maybe<Scalars['String']['output']>;
    /** Идентификационный номер налогоплательщика (ИНН) ESIA */
    inn?: Maybe<Scalars['INN']['output']>;
    /** Фамилия ESIA */
    lastName: Scalars['StrictString']['output'];
    /** Адрес  проживания (ЕСИА) */
    livingHabitation?: Maybe<TEsiaHabitation>;
    /** Отчество ESIA */
    middleName?: Maybe<Scalars['StrictString']['output']>;
    /** Мобильный ESIA */
    mobilePhone?: Maybe<Scalars['PhoneNumber']['output']>;
    /** Владелец профиля ЕСИА */
    profile?: Maybe<TProfile>;
    /** Адрес  регистрации (ЕСИА) */
    registrationHabitation?: Maybe<TEsiaHabitation>;
    /** Страховой номер индивидуального лицевого счёта (СНИЛС) ESIA */
    snils: Scalars['SNILS']['output'];
    updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TEsiaVehicle = {
    __typename?: 'ESIAVehicle';
    /** Дата выдачи Одобрения типа ТС */
    approveDocumentDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Кем выдано Одобрение типа ТС */
    approveIssueAgency?: Maybe<Scalars['String']['output']>;
    /** Серия и номер Одобрения типа ТС */
    approveSerNum?: Maybe<Scalars['String']['output']>;
    /** Цвет кузова */
    carcaseColor?: Maybe<Scalars['String']['output']>;
    /** Номер кузова */
    carcaseNumber?: Maybe<Scalars['String']['output']>;
    /** Номер шасси */
    chassisNumber?: Maybe<Scalars['String']['output']>;
    /** Таможенная декларация */
    customsDeclaration?: Maybe<Scalars['String']['output']>;
    /** Привод */
    driveUnitType?: Maybe<Scalars['String']['output']>;
    /** Экологический класс */
    ecologyClass?: Maybe<Scalars['String']['output']>;
    /** Модель двигателя */
    engineModel?: Maybe<Scalars['String']['output']>;
    /** Номер двигателя */
    engineNum?: Maybe<Scalars['String']['output']>;
    /** Мощность двигателя (л/с) */
    enginePowerHorse?: Maybe<Scalars['Float']['output']>;
    /** Можность двигателя (кВт) */
    enginePowerkVt?: Maybe<Scalars['Float']['output']>;
    /** Тип двигателя */
    engineType?: Maybe<Scalars['String']['output']>;
    /** Мощность двигателя */
    engineVolume?: Maybe<Scalars['Int']['output']>;
    /** Установлено ли ГБО */
    gazEnvironmentFlag?: Maybe<Scalars['Boolean']['output']>;
    /** Рег. знак */
    govRegNumber?: Maybe<Scalars['String']['output']>;
    /** Хеш */
    hash?: Maybe<Scalars['String']['output']>;
    /** ID */
    id?: Maybe<Scalars['Int']['output']>;
    /** Код страны-изготовителя */
    issueCountryCode?: Maybe<Scalars['String']['output']>;
    /** Страна-изготовитель */
    issueCountryName?: Maybe<Scalars['String']['output']>;
    /** Год выпуска */
    manufacturedYear?: Maybe<Scalars['Int']['output']>;
    /** Производитель */
    manufacturer?: Maybe<Scalars['String']['output']>;
    /** Марка */
    markName?: Maybe<Scalars['String']['output']>;
    /** Максимальный вес */
    maxWeight?: Maybe<Scalars['Int']['output']>;
    /** Модель */
    modelName?: Maybe<Scalars['String']['output']>;
    owner?: Maybe<TNestedEsiaVehicleOwner>;
    ownerPeriod?: Maybe<Array<Maybe<TNestedEsiaVehicleOwnerPeriod>>>;
    /** Цена автомобиля */
    price?: Maybe<Scalars['Float']['output']>;
    /** Транспортное средство (ESIA) */
    profile?: Maybe<TProfile>;
    pts?: Maybe<TNestedEsiaVehiclePts>;
    regAction?: Maybe<Array<Maybe<TNestedEsiaVehicleRegAction>>>;
    registrationDoc?: Maybe<TNestedEsiaVehicleRegistrationDoc>;
    /** Ограничения */
    restrictions?: Maybe<Scalars['String']['output']>;
    /** Статус */
    status?: Maybe<Scalars['String']['output']>;
    /** Дата оформления таможенной декларации */
    tdtpoDocumentDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Таможенный орган */
    tdtpoIssueAgency?: Maybe<Scalars['String']['output']>;
    /** Коробка передач */
    transmissionType?: Maybe<Scalars['String']['output']>;
    /** Дата обновления данных  */
    updated?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Статус оплаты утильсбора */
    utilizStatus?: Maybe<Scalars['String']['output']>;
    /** Категория авто */
    vehicleCategory?: Maybe<Scalars['String']['output']>;
    /** Тип авто */
    vehicleType?: Maybe<Scalars['String']['output']>;
    /** Класс авто */
    vehicleTypeTAM?: Maybe<Scalars['String']['output']>;
    /** VIN */
    vin: Scalars['String']['output'];
    /** VIN2 */
    vin2?: Maybe<Scalars['String']['output']>;
    /** Вес без загрузки */
    weightWithoutLoading?: Maybe<Scalars['Int']['output']>;
    /** Расположение руля */
    wheelLocation?: Maybe<Scalars['String']['output']>;
};

export enum EUnemploymentReason {
    Other = 'other',
    PensionerByAge = 'pensionerByAge',
    PensionerByDisability = 'pensionerByDisability',
    RetiredJudge = 'retiredJudge',
    SearchJob = 'searchJob',
    Spouse = 'spouse',
}

export enum EWorkExperience {
    BetweenOneAndThreeYears = 'betweenOneAndThreeYears',
    BetweenThreeAndTenYears = 'betweenThreeAndTenYears',
    LessOneYear = 'lessOneYear',
    MoreTenYears = 'moreTenYears',
}

export type TEsiaClientFlags = {
    __typename?: 'EsiaClientFlags';
    /** Флаг даты и времени, когда клиент дал ответы на вопросы AML */
    AMLQuestionsDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Флаг даты и времени присоединения клиента к правилам платформы МПЛ */
    MPLRegistration?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Причина блокировки клиента */
    blockReason?: Maybe<Scalars['String']['output']>;
    /** Флаг даты и времени, когда клиента заблокировали */
    blockedDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Hash флаг */
    hash?: Maybe<Scalars['String']['output']>;
    /** Флаги id */
    id: Scalars['String']['output'];
    /** Флаг даты и времени прохождения клиентом идентификации */
    identificationDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Флаги клиента ЕСИА */
    profile?: Maybe<TProfile>;
};

export type THabitation = {
    __typename?: 'Habitation';
    address: TNestedHabitationAddress;
    /** Псевдоним объекта */
    alias?: Maybe<Scalars['String']['output']>;
    /** Вручную подтвержденный адрес регистрации в CRM */
    approvedPassport?: Maybe<TPassport>;
    creditSelection?: Maybe<TNestedHabitationCreditSelection>;
    /** Адрес залога в кредитах */
    creditSelectionPledgers?: Maybe<Array<Maybe<TPeople>>>;
    /** Уникальный хеш адреса клиента */
    hash?: Maybe<Scalars['String']['output']>;
    /** Адрес страхования недвижимости */
    insurer?: Maybe<TPeople>;
    /** Адрес проживания */
    livingPeople?: Maybe<Array<Maybe<TPeople>>>;
    /** Адрес ипотеки */
    mortgager?: Maybe<TPeople>;
    /** Владелец недвижимости */
    owner?: Maybe<TPeople>;
    /** Владельцы недвижимости */
    owners?: Maybe<Array<Maybe<TPeople>>>;
    /** Домашний Телефон */
    phone?: Maybe<Scalars['PhoneNumber']['output']>;
    /** Адрес залога в кредитах */
    pledgers?: Maybe<Array<Maybe<TPeople>>>;
    /** Руками введенный адрес после регистрации ЕСИА */
    profile?: Maybe<TProfile>;
    /** Введенная вручную вне какого-либо продукта недвижимость */
    profileFromManualInput?: Maybe<TProfile>;
    properties?: Maybe<TNestedHabitationProperties>;
    /** Адрес регистрации! */
    registeredPeople?: Maybe<Array<Maybe<TPeople>>>;
    /** Тип недвижимости */
    type?: Maybe<EPropertyType>;
    updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TInputCar = {
    /** Псевдоним объекта */
    alias?: InputMaybe<Scalars['String']['input']>;
    /** Цвет кузова */
    bodyColor?: InputMaybe<Scalars['String']['input']>;
    /** Номер кузова */
    bodyNumber?: InputMaybe<Scalars['String']['input']>;
    /** Id кузова в справочнике */
    bodyTypeId?: InputMaybe<Scalars['String']['input']>;
    /** Владелец авто */
    carOwner?: InputMaybe<TInputPeople>;
    /** Тип кузова */
    carcase?: InputMaybe<Scalars['String']['input']>;
    /** id типа кузова */
    carcaseid?: InputMaybe<Scalars['String']['input']>;
    casco?: InputMaybe<TInputNestedCarCasco>;
    /** Страхователь в расчете КАСКО */
    cascoInsurer?: InputMaybe<TInputPeople>;
    /** Категория авто по тех регламенту (A, B, C...) */
    category?: InputMaybe<ECarCategory>;
    /** Номер шасси */
    chassisNumber?: InputMaybe<Scalars['String']['input']>;
    /** Залоговое авто в кредитах */
    creditSelectionPledgeForPeople?: InputMaybe<Array<InputMaybe<TInputPeople>>>;
    diagnosticCard?: InputMaybe<TInputNestedCarDiagnosticCard>;
    /** Привод автомобиля */
    drive?: InputMaybe<ECarDrive>;
    /** Водители */
    drivers?: InputMaybe<Array<InputMaybe<TInputPeople>>>;
    ePts?: InputMaybe<TInputNestedCarEPts>;
    /** Объём двигателя */
    enginevolume?: InputMaybe<Scalars['String']['input']>;
    /** КПП */
    kpp?: InputMaybe<EKpp>;
    /** Идентификатор КПП */
    kppId?: InputMaybe<Scalars['String']['input']>;
    /** Ссылка на логотип марки автомобиля */
    logoLink?: InputMaybe<Scalars['String']['input']>;
    /** Марка */
    mark: Scalars['String']['input'];
    /** Id марки в справочнике ОСАГО */
    markId?: InputMaybe<Scalars['String']['input']>;
    /** Модель */
    model: Scalars['String']['input'];
    /** Id модели в справочнике ОСАГО */
    modelId?: InputMaybe<Scalars['String']['input']>;
    /** Название модификации машины */
    modification?: InputMaybe<Scalars['String']['input']>;
    osago?: InputMaybe<TInputNestedCarOsago>;
    /** Страхователь авто в ОСАГО */
    osagoInsurer?: InputMaybe<TInputPeople>;
    /** Водители в расчете ОСАГО */
    osagoSelectedDrivers?: InputMaybe<Array<InputMaybe<TInputPeople>>>;
    /** Залоговое авто в кредитах */
    pledger?: InputMaybe<TInputPeople>;
    /** Мощность двигателя */
    power?: InputMaybe<Scalars['String']['input']>;
    /** Введенный вручную вне какого-либо продукта автомобиль */
    profileFromManualInput?: InputMaybe<TInputCommon>;
    pts?: InputMaybe<TInputNestedCarPts>;
    /** Регистрационный знак */
    registrationPlate?: InputMaybe<Scalars['RegistrationPlateScalar']['input']>;
    /** Год начала владения */
    startOwnershipYear?: InputMaybe<Scalars['year']['input']>;
    sts?: InputMaybe<TInputNestedCarSts>;
    /** Срок владения автомобилем */
    tenure?: InputMaybe<Scalars['String']['input']>;
    /** Тестовая ссылка */
    testo2o?: InputMaybe<TInputPeople>;
    updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Идентификационный номер транспортного средства (VIN)  */
    vin?: InputMaybe<Scalars['String']['input']>;
    /** Год выпуска */
    year: Scalars['year']['input'];
};

export type TInputCommon = {
    /** Вручную подтвержденный паспорт в CRM */
    approvedPassport?: InputMaybe<TInputPassport>;
    /** credit people */
    creditPeople?: InputMaybe<TInputPeople>;
    /** mfo people */
    creditRatingPeople?: InputMaybe<TInputPeople>;
    /** Введенный вручную вне какого-либо продукта автомобиль */
    manualInputCars?: InputMaybe<Array<InputMaybe<TInputCar>>>;
    /** Руками введенный адрес после регистрации ЕСИА */
    manualInputESIARegistrationHabitation?: InputMaybe<TInputHabitation>;
    /** Введенная вручную вне какого-либо продукта недвижимость */
    manualInputHabitations?: InputMaybe<Array<InputMaybe<TInputHabitation>>>;
    /** mfo people */
    mfoPeople?: InputMaybe<TInputPeople>;
    /** mpl people */
    mplPeople?: InputMaybe<TInputPeople>;
    /** OSAGO People */
    osagoPeople?: InputMaybe<TInputPeople>;
    /** Red people */
    redPeople?: InputMaybe<TInputPeople>;
};

export type TInputDeleteProfileObjectParams = {
    throwIfNotFound?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TInputDeleteProfileParams = {
    deletionReason?: InputMaybe<Scalars['String']['input']>;
    notSaveAsDeleted?: InputMaybe<Scalars['Boolean']['input']>;
    removedByUserId?: InputMaybe<Scalars['Int']['input']>;
};

export type TInputEsiaDriverLicense = {
    /** Дата рождения ESIA */
    birthDate?: InputMaybe<Scalars['BirthDate']['input']>;
    /** Место рождения ESIA */
    birthPlace?: InputMaybe<Scalars['String']['input']>;
    /** Категории ESIA */
    categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Страна выдачи ВУ ESIA */
    countryName?: InputMaybe<Scalars['String']['input']>;
    /** Ведомство ESIA */
    departmentDoc?: InputMaybe<Scalars['String']['input']>;
    /** Год начала стажа by ESIA */
    experience?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Действителен до ESIA */
    expiryDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Имя ESIA */
    firstName?: InputMaybe<Scalars['StrictString']['input']>;
    hash?: InputMaybe<Scalars['String']['input']>;
    /** ИД документа ESIA */
    id: Scalars['String']['input'];
    /** Дата выдачи ESIA */
    issueDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Кем выдан документ ESIA */
    issuedBy?: InputMaybe<Scalars['String']['input']>;
    /** Фамилия ESIA */
    lastName?: InputMaybe<Scalars['String']['input']>;
    /** Отчество ESIA */
    middleName?: InputMaybe<Scalars['String']['input']>;
    /** Номер ESIA */
    number?: InputMaybe<Scalars['String']['input']>;
    /** Водительское удостоверение (ЕСИА) */
    profile?: InputMaybe<TInputEsia>;
    /** Дата получения документа ESIA */
    receiptDocDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Релевантность ESIA */
    relevance?: InputMaybe<EDocumentRelevance>;
    /** Серия ESIA */
    series?: InputMaybe<Scalars['String']['input']>;
    /** Статус ESIA */
    status?: InputMaybe<EDocumentStatus>;
    /** Тип документа ESIA */
    type?: InputMaybe<EDocumentType>;
    updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Дата валидации документа ESIA */
    validateDateDoc?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TInputEsiaeWorkbook = {
    /** ЕСИА */
    departmentDoc?: InputMaybe<Scalars['String']['input']>;
    events?: InputMaybe<Array<InputMaybe<TInputNestedEsiaeWorkbookEvents>>>;
    /** ЕСИА */
    hash?: InputMaybe<Scalars['String']['input']>;
    /** Идентификатор электронной книжки ЕСИА */
    id: Scalars['String']['input'];
    /** Электронная книжка ЕСИА */
    profile?: InputMaybe<TInputEsia>;
    /** Трудовая книжка ЕСИА */
    receiptDocDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** ЕСИА */
    relevance?: InputMaybe<EDocumentRelevance>;
    /** ЕСИА */
    status?: InputMaybe<EDocumentStatus>;
    updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Трудовая книжка ЕСИА */
    validateDateDoc?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TInputEsiaHabitation = {
    address: TInputNestedEsiaHabitationAddress;
    /** В ручную подтвержденный адрес регистрации ЕСИА */
    approvedRegisteredPeople?: InputMaybe<TInputEsia>;
    hash?: InputMaybe<Scalars['String']['input']>;
    /** Адрес  проживания (ЕСИА) */
    livingPeople?: InputMaybe<TInputEsia>;
    /** Адрес  регистрации (ЕСИА) */
    registeredPeople?: InputMaybe<TInputEsia>;
    updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TInputEsiaPassport = {
    /** Дата рождения ESIA */
    birthDate?: InputMaybe<Scalars['BirthDate']['input']>;
    /** Место рождения ESIA */
    birthPlace?: InputMaybe<Scalars['String']['input']>;
    /** Ведомство ESIA */
    departmentDoc?: InputMaybe<Scalars['String']['input']>;
    /** Имя ESIA */
    firstName?: InputMaybe<Scalars['StrictString']['input']>;
    /** Пол клиента ESIA */
    gender?: InputMaybe<EGender>;
    hash?: InputMaybe<Scalars['String']['input']>;
    /** ИД документа ESIA */
    id: Scalars['String']['input'];
    /** Дата выдачи ESIA */
    issueDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Код подразделения ESIA */
    issueId?: InputMaybe<Scalars['IssueId']['input']>;
    /** Кем выдан паспорт ESIA */
    issuedBy?: InputMaybe<Scalars['String']['input']>;
    /** Фамилия ESIA */
    lastName?: InputMaybe<Scalars['String']['input']>;
    /** Отчество ESIA */
    middleName?: InputMaybe<Scalars['String']['input']>;
    /** Номер ESIA */
    number?: InputMaybe<Scalars['String']['input']>;
    /** Статус паспорта ESIA */
    passportStatus?: InputMaybe<Scalars['String']['input']>;
    /** Тип паспорта ESIA */
    passportType?: InputMaybe<Scalars['String']['input']>;
    profile?: InputMaybe<TInputEsia>;
    /** Дата получения документа ESIA */
    receiptDocDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Релевантность ESIA */
    relevance?: InputMaybe<EDocumentRelevance>;
    /** Серия ESIA */
    series?: InputMaybe<Scalars['String']['input']>;
    /** Статус ESIA */
    status?: InputMaybe<EDocumentStatus>;
    /** Тип документа ESIA */
    type?: InputMaybe<EDocumentType>;
    updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Дата валидации документа ESIA */
    validateDateDoc?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TInputEsiaPeople = {
    /** В ручную подтвержденный адрес регистрации ЕСИА */
    approvedRegistrationHabitation?: InputMaybe<TInputEsiaHabitation>;
    /** Дата рождения ESIA */
    birthDate: Scalars['BirthDate']['input'];
    /** Место рождения ESIA */
    birthPlace?: InputMaybe<Scalars['String']['input']>;
    /** Электронная почта ESIA */
    email?: InputMaybe<Scalars['EmailAddress']['input']>;
    /** Имя ESIA */
    firstName: Scalars['StrictString']['input'];
    /** Пол клиента ESIA */
    gender?: InputMaybe<EGender>;
    hash?: InputMaybe<Scalars['String']['input']>;
    /** Идентификационный номер налогоплательщика (ИНН) ESIA */
    inn?: InputMaybe<Scalars['INN']['input']>;
    /** Фамилия ESIA */
    lastName: Scalars['StrictString']['input'];
    /** Адрес  проживания (ЕСИА) */
    livingHabitation?: InputMaybe<TInputEsiaHabitation>;
    /** Отчество ESIA */
    middleName?: InputMaybe<Scalars['StrictString']['input']>;
    /** Мобильный ESIA */
    mobilePhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
    /** Владелец профиля ЕСИА */
    profile?: InputMaybe<TInputEsia>;
    /** Адрес  регистрации (ЕСИА) */
    registrationHabitation?: InputMaybe<TInputEsiaHabitation>;
    /** Страховой номер индивидуального лицевого счёта (СНИЛС) ESIA */
    snils: Scalars['SNILS']['input'];
    updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TInputEsiaVehicle = {
    /** Дата выдачи Одобрения типа ТС */
    approveDocumentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Кем выдано Одобрение типа ТС */
    approveIssueAgency?: InputMaybe<Scalars['String']['input']>;
    /** Серия и номер Одобрения типа ТС */
    approveSerNum?: InputMaybe<Scalars['String']['input']>;
    /** Цвет кузова */
    carcaseColor?: InputMaybe<Scalars['String']['input']>;
    /** Номер кузова */
    carcaseNumber?: InputMaybe<Scalars['String']['input']>;
    /** Номер шасси */
    chassisNumber?: InputMaybe<Scalars['String']['input']>;
    /** Таможенная декларация */
    customsDeclaration?: InputMaybe<Scalars['String']['input']>;
    /** Привод */
    driveUnitType?: InputMaybe<Scalars['String']['input']>;
    /** Экологический класс */
    ecologyClass?: InputMaybe<Scalars['String']['input']>;
    /** Модель двигателя */
    engineModel?: InputMaybe<Scalars['String']['input']>;
    /** Номер двигателя */
    engineNum?: InputMaybe<Scalars['String']['input']>;
    /** Мощность двигателя (л/с) */
    enginePowerHorse?: InputMaybe<Scalars['Float']['input']>;
    /** Можность двигателя (кВт) */
    enginePowerkVt?: InputMaybe<Scalars['Float']['input']>;
    /** Тип двигателя */
    engineType?: InputMaybe<Scalars['String']['input']>;
    /** Мощность двигателя */
    engineVolume?: InputMaybe<Scalars['Int']['input']>;
    /** Установлено ли ГБО */
    gazEnvironmentFlag?: InputMaybe<Scalars['Boolean']['input']>;
    /** Рег. знак */
    govRegNumber?: InputMaybe<Scalars['String']['input']>;
    /** Хеш */
    hash?: InputMaybe<Scalars['String']['input']>;
    /** ID */
    id?: InputMaybe<Scalars['Int']['input']>;
    /** Код страны-изготовителя */
    issueCountryCode?: InputMaybe<Scalars['String']['input']>;
    /** Страна-изготовитель */
    issueCountryName?: InputMaybe<Scalars['String']['input']>;
    /** Год выпуска */
    manufacturedYear?: InputMaybe<Scalars['Int']['input']>;
    /** Производитель */
    manufacturer?: InputMaybe<Scalars['String']['input']>;
    /** Марка */
    markName?: InputMaybe<Scalars['String']['input']>;
    /** Максимальный вес */
    maxWeight?: InputMaybe<Scalars['Int']['input']>;
    /** Модель */
    modelName?: InputMaybe<Scalars['String']['input']>;
    owner?: InputMaybe<TInputNestedEsiaVehicleOwner>;
    ownerPeriod?: InputMaybe<Array<InputMaybe<TInputNestedEsiaVehicleOwnerPeriod>>>;
    /** Цена автомобиля */
    price?: InputMaybe<Scalars['Float']['input']>;
    /** Транспортное средство (ESIA) */
    profile?: InputMaybe<TInputEsia>;
    pts?: InputMaybe<TInputNestedEsiaVehiclePts>;
    regAction?: InputMaybe<Array<InputMaybe<TInputNestedEsiaVehicleRegAction>>>;
    registrationDoc?: InputMaybe<TInputNestedEsiaVehicleRegistrationDoc>;
    /** Ограничения */
    restrictions?: InputMaybe<Scalars['String']['input']>;
    /** Статус */
    status?: InputMaybe<Scalars['String']['input']>;
    /** Дата оформления таможенной декларации */
    tdtpoDocumentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Таможенный орган */
    tdtpoIssueAgency?: InputMaybe<Scalars['String']['input']>;
    /** Коробка передач */
    transmissionType?: InputMaybe<Scalars['String']['input']>;
    /** Дата обновления данных  */
    updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Статус оплаты утильсбора */
    utilizStatus?: InputMaybe<Scalars['String']['input']>;
    /** Категория авто */
    vehicleCategory?: InputMaybe<Scalars['String']['input']>;
    /** Тип авто */
    vehicleType?: InputMaybe<Scalars['String']['input']>;
    /** Класс авто */
    vehicleTypeTAM?: InputMaybe<Scalars['String']['input']>;
    /** VIN */
    vin: Scalars['String']['input'];
    /** VIN2 */
    vin2?: InputMaybe<Scalars['String']['input']>;
    /** Вес без загрузки */
    weightWithoutLoading?: InputMaybe<Scalars['Int']['input']>;
    /** Расположение руля */
    wheelLocation?: InputMaybe<Scalars['String']['input']>;
};

export type TInputEsia = {
    /** Водительское удостоверение (ЕСИА) */
    ESIADriverLicense?: InputMaybe<TInputEsiaDriverLicense>;
    /** Электронная книжка ЕСИА */
    ESIAEWorkbook?: InputMaybe<TInputEsiaeWorkbook>;
    /** Владелец профиля ЕСИА */
    ESIAOwner?: InputMaybe<TInputEsiaPeople>;
    /** Паспорт гражданина РФ (ЕСИА) */
    ESIAPassport?: InputMaybe<TInputEsiaPassport>;
    /** В ручную подтвержденный паспорт ЕСИА */
    ESIAPassportApproved?: InputMaybe<TInputEsiaPassport>;
    ESIAPassportHistory?: InputMaybe<Array<InputMaybe<TInputEsiaPassport>>>;
    /** Транспортное средство (ESIA) */
    ESIAVehicle?: InputMaybe<Array<InputMaybe<TInputEsiaVehicle>>>;
    /** Справка 2-НДФЛ */
    NDFL2?: InputMaybe<Array<InputMaybe<TInputNdfl2>>>;
    /** В ручную подтвержденный адрес регистрации ЕСИА */
    approvedRegistrationHabitation?: InputMaybe<TInputEsiaHabitation>;
    /** Адрес  проживания (ЕСИА) */
    livingHabitation?: InputMaybe<TInputEsiaHabitation>;
    /** Адрес  регистрации (ЕСИА) */
    registrationHabitation?: InputMaybe<TInputEsiaHabitation>;
};

export type TInputEsiaClientFlags = {
    /** Флаг даты и времени, когда клиент дал ответы на вопросы AML */
    AMLQuestionsDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Флаг даты и времени присоединения клиента к правилам платформы МПЛ */
    MPLRegistration?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Причина блокировки клиента */
    blockReason?: InputMaybe<Scalars['String']['input']>;
    /** Флаг даты и времени, когда клиента заблокировали */
    blockedDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Флаги id */
    id: Scalars['String']['input'];
    /** Флаг даты и времени прохождения клиентом идентификации */
    identificationDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Флаги клиента ЕСИА */
    profile?: InputMaybe<TInputEsia>;
};

export type TInputHabitation = {
    address: TInputNestedHabitationAddress;
    /** Псевдоним объекта */
    alias?: InputMaybe<Scalars['String']['input']>;
    /** Вручную подтвержденный адрес регистрации в CRM */
    approvedPassport?: InputMaybe<TInputPassport>;
    creditSelection?: InputMaybe<TInputNestedHabitationCreditSelection>;
    /** Адрес залога в кредитах */
    creditSelectionPledgers?: InputMaybe<Array<InputMaybe<TInputPeople>>>;
    /** Адрес страхования недвижимости */
    insurer?: InputMaybe<TInputPeople>;
    /** Адрес проживания */
    livingPeople?: InputMaybe<Array<InputMaybe<TInputPeople>>>;
    /** Адрес ипотеки */
    mortgager?: InputMaybe<TInputPeople>;
    /** Владелец недвижимости */
    owner?: InputMaybe<TInputPeople>;
    /** Владельцы недвижимости */
    owners?: InputMaybe<Array<InputMaybe<TInputPeople>>>;
    /** Домашний Телефон */
    phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
    /** Адрес залога в кредитах */
    pledgers?: InputMaybe<Array<InputMaybe<TInputPeople>>>;
    /** Руками введенный адрес после регистрации ЕСИА */
    profile?: InputMaybe<TInputCommon>;
    /** Введенная вручную вне какого-либо продукта недвижимость */
    profileFromManualInput?: InputMaybe<TInputCommon>;
    properties?: InputMaybe<TInputNestedHabitationProperties>;
    /** Адрес регистрации! */
    registeredPeople?: InputMaybe<Array<InputMaybe<TInputPeople>>>;
    /** Тип недвижимости */
    type?: InputMaybe<EPropertyType>;
    updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TInputHabitationFields = {
    address?: InputMaybe<TInputHabitationFieldsAddress>;
    hash: Scalars['String']['input'];
};

export type TInputHabitationFieldsAddress = {
    flat?: InputMaybe<Scalars['String']['input']>;
    floorsNumber?: InputMaybe<Scalars['String']['input']>;
    roomsCount?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNdfl2 = {
    /** Номер корректировки */
    correctionNumber: Scalars['String']['input'];
    /** Дата формирования справки */
    formationDate: Scalars['DateTimeISO']['input'];
    income?: InputMaybe<Array<InputMaybe<TInputNestedNdfl2Income>>>;
    person?: InputMaybe<TInputNestedNdfl2Person>;
    /** Справка 2-НДФЛ */
    profile?: InputMaybe<TInputEsia>;
    /** Источник справки */
    source: Scalars['String']['input'];
    taxAgent?: InputMaybe<TInputNestedNdfl2TaxAgent>;
    /** Отчетный год */
    year: Scalars['Int']['input'];
};

export type TInputNestedCarCasco = {
    /** Тип документа при вводе данных в каско */
    documentType?: InputMaybe<Scalars['String']['input']>;
    /** Залоговый ли автомобиль */
    isPledge?: InputMaybe<Scalars['String']['input']>;
    /** Количество ключей у автомобиля */
    keysNumber?: InputMaybe<Scalars['String']['input']>;
    /** Пробег автомобиля */
    mileage?: InputMaybe<Scalars['String']['input']>;
    /** Id банка, в котором автомобиль оставлен в залог */
    pledgeBankId?: InputMaybe<Scalars['String']['input']>;
    /** Номер полиса КАСКО */
    policyNumber?: InputMaybe<Scalars['String']['input']>;
    /** Оригинал ли птс или дубликат */
    ptsIsOriginal?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedCarDiagnosticCard = {
    /** Дата окончания действия диагностической карты */
    expirationDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Номер диагностической карты */
    number?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedCarEPts = {
    /** Дата выдачи еПТС */
    issueDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Номер еПТС */
    number?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedCarOsago = {
    /** Тип документа при вводе данных в осаго */
    documentType?: InputMaybe<EOsagoCarDocumentType>;
    /** Номер полиса ОСАГО */
    policyNumber?: InputMaybe<Scalars['String']['input']>;
    /** Дата начала действия полиса осаго */
    policyStartDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Id продукта, который пользователь выбрал на выдаче */
    productId?: InputMaybe<Scalars['String']['input']>;
    /** Id выдачи в сервисе осаго */
    searchId?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedCarPts = {
    /** Дата выдачи ПТС */
    issueDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Серия и номер ПТС */
    number?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedCarSts = {
    /** Дата выдачи СТС */
    issueDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Номер СТС */
    number?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedEsiaeWorkbookEvents = {
    /** ЕСИА */
    date?: InputMaybe<Scalars['DateTimeISO']['input']>;
    dismissalBasis?: InputMaybe<TInputNestedEsiaeWorkbookEventsDismissalBasis>;
    /** ЕСИА */
    dismissalReason?: InputMaybe<Scalars['String']['input']>;
    /** ЕСИА */
    division?: InputMaybe<Scalars['String']['input']>;
    /** ЕСИА */
    isPartTimeJob?: InputMaybe<Scalars['Boolean']['input']>;
    organization?: InputMaybe<TInputNestedEsiaeWorkbookEventsOrganization>;
    /** ЕСИА */
    position?: InputMaybe<Scalars['String']['input']>;
    reason?: InputMaybe<Array<InputMaybe<TInputNestedEsiaeWorkbookEventsReason>>>;
    /** ЕСИА */
    type?: InputMaybe<Scalars['Int']['input']>;
    /** ЕСИА */
    uuid?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedEsiaeWorkbookEventsDismissalBasis = {
    /** ЕСИА */
    basisItem?: InputMaybe<Scalars['String']['input']>;
    /** ЕСИА */
    basisParagraph?: InputMaybe<Scalars['String']['input']>;
    /** ЕСИА dismissalBasis basisPart */
    basisPart?: InputMaybe<Scalars['String']['input']>;
    /** ЕСИА */
    normativeDocument?: InputMaybe<Scalars['String']['input']>;
    /** ЕСИА */
    type?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedEsiaeWorkbookEventsOrganization = {
    /** ЕСИА */
    inn?: InputMaybe<Scalars['INN']['input']>;
    /** ЕСИА */
    kpp?: InputMaybe<Scalars['String']['input']>;
    /** ЕСИА */
    orgName?: InputMaybe<Scalars['String']['input']>;
    /** ЕСИА */
    regNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedEsiaeWorkbookEventsReason = {
    /** ЕСИА events reason date */
    date?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** ЕСИА events reason document */
    document?: InputMaybe<Scalars['String']['input']>;
    /** ЕСИА events reason number */
    number?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedEsiaHabitationAddress = {
    /** Район в регионе */
    area?: InputMaybe<Scalars['String']['input']>;
    /** Тип района в регионе */
    areaType?: InputMaybe<Scalars['String']['input']>;
    /** Здание ESIA */
    block?: InputMaybe<Scalars['String']['input']>;
    blockType?: InputMaybe<Scalars['String']['input']>;
    /** Кадастровый номер ESIA */
    cadastralNumber?: InputMaybe<Scalars['CadastralNumber']['input']>;
    /** Город / населенный пункт */
    city?: InputMaybe<Scalars['String']['input']>;
    cityType?: InputMaybe<Scalars['String']['input']>;
    /** Год постройки дома */
    constructionYear?: InputMaybe<Scalars['year']['input']>;
    /** Код страны ESIA */
    countryId?: InputMaybe<Scalars['String']['input']>;
    /** Адрес ESIA */
    dadata?: InputMaybe<Scalars['String']['input']>;
    /** Код ФИАС ESIA */
    fiasId: Scalars['String']['input'];
    /** Уровень детализации, до которого адрес найден в ФИАС. ESIA */
    fiasLevel?: InputMaybe<Scalars['String']['input']>;
    /** Помещение ESIA */
    flat?: InputMaybe<Scalars['String']['input']>;
    /** Площадь */
    flatArea?: InputMaybe<Scalars['String']['input']>;
    /** Код ФИАС квартиры ЕСИА */
    flatFiasId?: InputMaybe<Scalars['String']['input']>;
    flatType?: InputMaybe<Scalars['String']['input']>;
    /** Номер этажа */
    floorNumber?: InputMaybe<Scalars['String']['input']>;
    /** Количество этажей */
    floorsNumber?: InputMaybe<Scalars['String']['input']>;
    /** Дом ESIA */
    house?: InputMaybe<Scalars['String']['input']>;
    houseType?: InputMaybe<Scalars['String']['input']>;
    /** КЛАДР-код адреса */
    kladrId?: InputMaybe<Scalars['String']['input']>;
    /** Индекс ESIA */
    postalCode?: InputMaybe<Scalars['String']['input']>;
    /** Регион ESIA */
    region?: InputMaybe<Scalars['String']['input']>;
    regionType?: InputMaybe<Scalars['String']['input']>;
    /** Количество комнат */
    roomsCount?: InputMaybe<Scalars['String']['input']>;
    /** Населенный пункт */
    settlement?: InputMaybe<Scalars['String']['input']>;
    /** Тип населенного пункт */
    settlementType?: InputMaybe<Scalars['String']['input']>;
    /** Улица ESIA */
    street?: InputMaybe<Scalars['String']['input']>;
    streetType?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedEsiaVehicleOwner = {
    /** День рождения владельца машины */
    birthDate?: InputMaybe<Scalars['BirthDate']['input']>;
    /** Место рождения */
    birthPlace?: InputMaybe<Scalars['String']['input']>;
    /** Имя */
    firstName?: InputMaybe<Scalars['String']['input']>;
    /** Фамилия */
    lastName?: InputMaybe<Scalars['String']['input']>;
    /** Отчество */
    middleName?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedEsiaVehicleOwnerPeriod = {
    /** Дата окончания */
    dateEnd?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Дата начала */
    dateStart?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Тип владельца */
    ownerType?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedEsiaVehiclePts = {
    /** Дата выдачи */
    ptsDocumentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Кем выдан */
    ptsIssueAgency?: InputMaybe<Scalars['String']['input']>;
    /** Серия и номер */
    ptsSeriesNumber?: InputMaybe<Scalars['String']['input']>;
    /** Доп инфа по выдаче */
    ptsSpecialTags?: InputMaybe<Scalars['String']['input']>;
    /** Тип */
    ptsType?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedEsiaVehicleRegAction = {
    /** confidentSign */
    confidentSign?: InputMaybe<Scalars['Boolean']['input']>;
    /** Признак лизинга */
    leasingFlag?: InputMaybe<Scalars['Boolean']['input']>;
    /** Причина регистрации */
    regActionName?: InputMaybe<Scalars['String']['input']>;
    /** Дата выдачи */
    regDocumentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Кем выдан */
    regIssueAgency?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedEsiaVehicleRegistrationDoc = {
    /** Дата выдачи */
    regDocDocumentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Кем выдан */
    regDocIssueAgency?: InputMaybe<Scalars['String']['input']>;
    /** Серия и номер */
    regDocSeriesNumber?: InputMaybe<Scalars['String']['input']>;
    /** Тип */
    regDocType?: InputMaybe<Scalars['String']['input']>;
    /** Комментарий */
    specialTags?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedHabitationAddress = {
    area?: InputMaybe<Scalars['String']['input']>;
    areaType?: InputMaybe<Scalars['String']['input']>;
    /** Корпус */
    block?: InputMaybe<Scalars['String']['input']>;
    blockType?: InputMaybe<Scalars['String']['input']>;
    /** Кадастровый номер */
    cadastralNumber?: InputMaybe<Scalars['CadastralNumber']['input']>;
    /** Город / населенный пункт */
    city?: InputMaybe<Scalars['String']['input']>;
    cityType?: InputMaybe<Scalars['String']['input']>;
    /** Год постройки дома */
    constructionYear?: InputMaybe<Scalars['year']['input']>;
    /** Код страны */
    countryId?: InputMaybe<Scalars['String']['input']>;
    /** Адрес */
    dadata?: InputMaybe<Scalars['String']['input']>;
    /** ФИАС-код адреса (идентификатор ФИАС) */
    fiasId: Scalars['String']['input'];
    /** Уровень детализации, до которого адрес найден в ФИАС */
    fiasLevel?: InputMaybe<Scalars['String']['input']>;
    /** Квартира */
    flat?: InputMaybe<Scalars['String']['input']>;
    /** Площадь */
    flatArea?: InputMaybe<Scalars['String']['input']>;
    /** Код ФИАС квартиры */
    flatFiasId?: InputMaybe<Scalars['String']['input']>;
    flatType?: InputMaybe<Scalars['String']['input']>;
    /** Номер этажа */
    floorNumber?: InputMaybe<Scalars['String']['input']>;
    /** Количество этажей */
    floorsNumber?: InputMaybe<Scalars['String']['input']>;
    /** Дом */
    house?: InputMaybe<Scalars['String']['input']>;
    houseType?: InputMaybe<Scalars['String']['input']>;
    /** КЛАДР-код адреса */
    kladrId?: InputMaybe<Scalars['String']['input']>;
    /** Роут адреса из сервиса locations */
    locationRoute?: InputMaybe<Scalars['String']['input']>;
    /** Почтовый индекс РФ */
    postalCode?: InputMaybe<Scalars['String']['input']>;
    /** Регион */
    region?: InputMaybe<Scalars['String']['input']>;
    regionType?: InputMaybe<Scalars['String']['input']>;
    /** Количество комнат */
    roomsCount?: InputMaybe<Scalars['String']['input']>;
    settlement?: InputMaybe<Scalars['String']['input']>;
    settlementType?: InputMaybe<Scalars['String']['input']>;
    /** Улица */
    street?: InputMaybe<Scalars['String']['input']>;
    /** Тип улицы */
    streetType?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedHabitationCreditSelection = {
    pledgeOwnerFamilyStatus?: InputMaybe<EFamilyStatus>;
    pledgeOwnerFirstName?: InputMaybe<Scalars['StrictString']['input']>;
    pledgeOwnerLastName?: InputMaybe<Scalars['String']['input']>;
    pledgeOwnerMiddleName?: InputMaybe<Scalars['String']['input']>;
    pledgeOwnerPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
    pledgeOwnerRelationship?: InputMaybe<ERelationStatus>;
    pledgeOwnerSingle?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedHabitationProperties = {
    /** Газификация */
    hasGas?: InputMaybe<Scalars['Boolean']['input']>;
    /** Наличие перепланировки */
    hasRedevelopment?: InputMaybe<Scalars['Boolean']['input']>;
    /** Имеются ли деревянные перекрытия */
    hasWoodenFloors?: InputMaybe<Scalars['Boolean']['input']>;
    /** Есть ли газ в помещении */
    isGasified?: InputMaybe<Scalars['Boolean']['input']>;
    /** Материал перекрытий */
    overlapMaterial?: InputMaybe<Scalars['String']['input']>;
    /** Материал стен */
    wallMaterial?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedNdfl2Income = {
    /** Доходы, облагаемые по ставке: Код бюджетной классификации */
    budgetClassificationCode?: InputMaybe<Scalars['String']['input']>;
    incomeTaxableByMonth?: InputMaybe<Array<InputMaybe<TInputNestedNdfl2IncomeIncomeTaxableByMonth>>>;
    /** Доходы, облагаемые по ставке: Ставка */
    taxRate?: InputMaybe<Scalars['Int']['input']>;
    total?: InputMaybe<TInputNestedNdfl2IncomeTotal>;
};

export type TInputNestedNdfl2IncomeIncomeTaxableByMonth = {
    /** Доходы, облагаемые по ставке: Сумма дохода */
    amount?: InputMaybe<Scalars['Float']['input']>;
    /** Доходы, облагаемые по ставке: Код дохода */
    code?: InputMaybe<Scalars['String']['input']>;
    deduction?: InputMaybe<TInputNestedNdfl2IncomeIncomeTaxableByMonthDeduction>;
    /** Доходы, облагаемые по ставке: месяц */
    month?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedNdfl2IncomeIncomeTaxableByMonthDeduction = {
    /** Доходы, облагаемые по ставке: Сумма вычета */
    amount?: InputMaybe<Scalars['Float']['input']>;
    /** Доходы, облагаемые по ставке: Код вычета */
    code?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedNdfl2IncomeTotal = {
    /** Общие суммы дохода и налога: Общая сумма доходов */
    amount?: InputMaybe<Scalars['Float']['input']>;
    /** Общие суммы дохода и налога: Сумма фиксированных  авансовых платежей */
    fixedAdvancePaymentAmount?: InputMaybe<Scalars['Float']['input']>;
    /** Общие суммы дохода и налога: Сумма налога исчисленная  */
    taxAmount?: InputMaybe<Scalars['Float']['input']>;
    /** Общие суммы дохода и налога: Сумма налога перечисленная */
    taxAmountPaid?: InputMaybe<Scalars['Float']['input']>;
    /** Общие суммы дохода и налога: Налоговая база */
    taxBase?: InputMaybe<Scalars['Float']['input']>;
    /** Общие суммы дохода и налога: Сумма налога на прибыль организаций, подлежащая зачету */
    taxOrganizationAmount?: InputMaybe<Scalars['Float']['input']>;
    /** Общие суммы дохода и налога: Сумма налога, излишне  удержанная налоговым агентом  */
    taxOverWithheld?: InputMaybe<Scalars['Float']['input']>;
    /** Общие суммы дохода и налога: Сумма налога удержанная  */
    taxWithholding?: InputMaybe<Scalars['Float']['input']>;
};

export type TInputNestedNdfl2Person = {
    /** Получатель доходов: дата рождения налогоплательщика */
    birthDate?: InputMaybe<Scalars['BirthDate']['input']>;
    /** Получатель доходов: гражданство налогоплательщика */
    citizenship?: InputMaybe<Scalars['String']['input']>;
    document?: InputMaybe<TInputNestedNdfl2PersonDocument>;
    /** Получатель доходов: Имя */
    firstName?: InputMaybe<Scalars['String']['input']>;
    /** Получатель доходов: Фамилия */
    lastName?: InputMaybe<Scalars['String']['input']>;
    /** Получатель доходов: Отчество */
    middleName?: InputMaybe<Scalars['String']['input']>;
    /** Получатель доходов: статус налогоплательщика */
    status?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedNdfl2PersonDocument = {
    /** Получатель доходов: код документа, подтвержающего личность */
    code?: InputMaybe<Scalars['String']['input']>;
    /** Получатель доходов: серия и номер документа, подтвержающего личность */
    seriesNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedNdfl2TaxAgent = {
    legalEntity?: InputMaybe<TInputNestedNdfl2TaxAgentLegalEntity>;
    naturalPerson?: InputMaybe<TInputNestedNdfl2TaxAgentNaturalPerson>;
    /** Данные о налоговом агенте: ОКТМО */
    oktmo?: InputMaybe<Scalars['String']['input']>;
    /** Данные о налоговом агенте: Телефон */
    phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
};

export type TInputNestedNdfl2TaxAgentLegalEntity = {
    /** Данные о налоговом агенте (юридическое лицо): ИНН */
    inn?: InputMaybe<Scalars['INN']['input']>;
    /** Данные о налоговом агенте (юридическое лицо): КПП */
    kpp?: InputMaybe<Scalars['String']['input']>;
    reorganizedInfo?: InputMaybe<TInputNestedNdfl2TaxAgentLegalEntityReorganizedInfo>;
    /** Данные о налоговом агенте (юридическое лицо): наименование организации */
    title?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedNdfl2TaxAgentLegalEntityReorganizedInfo = {
    /** Код формы реорганизации (ликвидации)/закрытия */
    form?: InputMaybe<Scalars['String']['input']>;
    /** ИНН реорганизованной организации/закрытого подразделения */
    inn?: InputMaybe<Scalars['INN']['input']>;
    /** КПП реорганизованной организации/закрытого подразделения */
    kpp?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedNdfl2TaxAgentNaturalPerson = {
    /** Данные о налоговом агенте (физическое лицо): Имя */
    firstName?: InputMaybe<Scalars['String']['input']>;
    /** Данные о налоговом агенте (физическое лицо): ИНН */
    inn?: InputMaybe<Scalars['String']['input']>;
    /** Данные о налоговом агенте (физическое лицо): Фамилия */
    lastName?: InputMaybe<Scalars['String']['input']>;
    /** Данные о налоговом агенте (физическое лицо): Отчество */
    middleName?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedPeopleCreditRating = {
    /** Дата обновления анкеты кредитного рейтинга */
    dateUpdate?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TInputNestedPeopleCreditSelection = {
    /** Сумма кредита */
    amount?: InputMaybe<Scalars['String']['input']>;
    /** Марка автомобиля, на который берется кредит */
    autoBrand?: InputMaybe<Scalars['String']['input']>;
    /** Модель автомобиля, на который берется кредит */
    autoModel?: InputMaybe<Scalars['String']['input']>;
    /** Год автомобиля, на который берется кредит */
    autoYear?: InputMaybe<Scalars['year']['input']>;
    /** Залог */
    creditSecurity?: InputMaybe<ECreditSecurity>;
    /** Сумма личных средств */
    initialFee?: InputMaybe<Scalars['String']['input']>;
    /** id города получения кредита */
    obtainCityId?: InputMaybe<Scalars['String']['input']>;
    /** Роут города получения кредита из сервиса locations */
    obtainCityLocationRoute?: InputMaybe<Scalars['String']['input']>;
    /** Срок кредита */
    period?: InputMaybe<Scalars['String']['input']>;
    /** Госномер машины, которая в собственности у пользователя */
    propertyAutoRegistrationPlate?: InputMaybe<Scalars['RegistrationPlateScalar']['input']>;
    /** Цель кредита */
    purpose?: InputMaybe<ECreditPurpose>;
    /** Город получения кредита */
    region?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedPeopleDriverLicence = {
    /** Дата начала стажа по категории A */
    experienceAStartDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Дата начала стажа */
    experienceStartDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Дата выдачи первых прав */
    firstIssueDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Дата выдачи текущих прав */
    issueDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Серия и номер водительского удостоверения */
    number?: InputMaybe<Scalars['SerialNumber']['input']>;
    /** Серия и номер предыдущего водительского удостоверения */
    previousNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedPeopleInternationalPassport = {
    /** Место рождения */
    birthPlace?: InputMaybe<Scalars['String']['input']>;
    /** Дата окончания срока действия */
    expirationDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Имя в заграничном паспорте */
    firstName?: InputMaybe<Scalars['String']['input']>;
    /** Дата выдачи заграничного паспорта */
    issueDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Орган, выдавший заграничный паспорт */
    issuedBy?: InputMaybe<Scalars['String']['input']>;
    /** Фамилия в заграничном паспорте */
    lastName?: InputMaybe<Scalars['String']['input']>;
    /** Серия и номер заграничного паспорта */
    number?: InputMaybe<Scalars['SerialNumberInternationalPassport']['input']>;
};

export type TInputNestedPeopleJob = {
    address?: InputMaybe<TInputNestedPeopleJobAddress>;
    /** Дата начала работы на текущем месте */
    lastExperienceStartDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    organization?: InputMaybe<TInputNestedPeopleJobOrganization>;
    /** Название должности */
    title?: InputMaybe<Scalars['String']['input']>;
    /** Тип должности */
    type?: InputMaybe<EJobPosition>;
};

export type TInputNestedPeopleJobAddress = {
    area?: InputMaybe<Scalars['String']['input']>;
    areaType?: InputMaybe<Scalars['String']['input']>;
    /** Корпус организации */
    block?: InputMaybe<Scalars['String']['input']>;
    blockType?: InputMaybe<Scalars['String']['input']>;
    /** Город / населенный пункт организации */
    city?: InputMaybe<Scalars['String']['input']>;
    cityType?: InputMaybe<Scalars['String']['input']>;
    /** Год постройки дома организации */
    constructionYear?: InputMaybe<Scalars['String']['input']>;
    /** Код страны */
    countryId?: InputMaybe<Scalars['String']['input']>;
    /** Адрес организации */
    dadata?: InputMaybe<Scalars['String']['input']>;
    /** ФИАС-код адреса (идентификатор ФИАС) */
    fiasId?: InputMaybe<Scalars['String']['input']>;
    /** Уровень детализации, до которого адрес найден в ФИАС */
    fiasLevel?: InputMaybe<Scalars['String']['input']>;
    /** Квартира организации */
    flat?: InputMaybe<Scalars['String']['input']>;
    /** Площадь квартиры организации */
    flatArea?: InputMaybe<Scalars['String']['input']>;
    flatType?: InputMaybe<Scalars['String']['input']>;
    /** Номер этажа */
    floorNumber?: InputMaybe<Scalars['String']['input']>;
    /** Количество этажей в доме организации */
    floorsNumber?: InputMaybe<Scalars['String']['input']>;
    /** Дом организации */
    house?: InputMaybe<Scalars['String']['input']>;
    houseType?: InputMaybe<Scalars['String']['input']>;
    /** КЛАДР-код адреса организации */
    kladrId?: InputMaybe<Scalars['String']['input']>;
    /** Роут адреса работы из сервиса locations */
    locationRoute?: InputMaybe<Scalars['String']['input']>;
    /** Почтовый индекс РФ организации */
    postalCode?: InputMaybe<Scalars['String']['input']>;
    /** Регион организации */
    region?: InputMaybe<Scalars['String']['input']>;
    regionType?: InputMaybe<Scalars['String']['input']>;
    /** Количество комнат в доме организации */
    roomsCount?: InputMaybe<Scalars['String']['input']>;
    settlement?: InputMaybe<Scalars['String']['input']>;
    settlementType?: InputMaybe<Scalars['String']['input']>;
    /** Улица организации */
    street?: InputMaybe<Scalars['String']['input']>;
    /** Тип улицы */
    streetType?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedPeopleJobOrganization = {
    /** Сфера деятельности организации */
    activity?: InputMaybe<EOrganizationActivity>;
    /** Идентификационный номер налогоплательщика (ИНН) */
    inn?: InputMaybe<Scalars['INN']['input']>;
    /** Наименование организации, где работает клиент */
    name?: InputMaybe<Scalars['String']['input']>;
    /** Основной государственный регистрационный номер (ОГРН) */
    ogrn?: InputMaybe<Scalars['OGRN']['input']>;
    /** Код ОКВЭД */
    okved?: InputMaybe<Scalars['String']['input']>;
    /** Рабочий телефон */
    phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
    /** Организационно-правовая форма организации */
    type?: InputMaybe<EEmployerType>;
    /** Код ОПФ */
    typeCode?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedPeopleMfo = {
    /** Сумма займа */
    amount?: InputMaybe<Scalars['String']['input']>;
    /** Идентификатор диалога в реакт анкете */
    conversationId?: InputMaybe<Scalars['String']['input']>;
    /** Срок займа */
    dateTo?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Срок займа */
    period?: InputMaybe<Scalars['String']['input']>;
};

export type TInputNestedPeopleMortgageInsurance = {
    /** Место заключения кредитного договора */
    agreementAddress?: InputMaybe<Scalars['String']['input']>;
    /** Ежемесячный платеж по кредиту */
    annuityPayment?: InputMaybe<Scalars['Float']['input']>;
    /** Процентная ставка при отказе от страхования */
    cancellationCreditRate?: InputMaybe<Scalars['Float']['input']>;
    /** Дата заключения кредитного договора */
    contractAgreementDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Дата окончания кредитного договора */
    contractAgreementEndDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Номер кредитного договора */
    contractNumber?: InputMaybe<Scalars['String']['input']>;
    /** Начальная сумма кредита */
    initialAmount?: InputMaybe<Scalars['Float']['input']>;
    /** Процентная ставка */
    rate?: InputMaybe<Scalars['Float']['input']>;
};

export type TInputNestedPeopleOsago = {
    /** КБМ */
    kbm?: InputMaybe<Scalars['String']['input']>;
    /** Дата сохранения предыдущих прав в процессе оформления ОСАГО */
    previousLicenceInputDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TInputNestedPeoplePassport = {
    /** Дата регистрации */
    addressRegistrationDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Дата выдачи паспорта РФ */
    issueDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Орган, выдавший паспорт РФ */
    issuedBy?: InputMaybe<Scalars['String']['input']>;
    /** Код подразделения организации, выдавшей паспорт РФ */
    issuedByCode?: InputMaybe<Scalars['IssueId']['input']>;
    /** Серия и номер паспорта РФ */
    number?: InputMaybe<Scalars['SerialNumber']['input']>;
};

export type TInputNestedPeoplePhysiology = {
    /** Нижнее давление */
    arterialRangeDown?: InputMaybe<Scalars['Int']['input']>;
    /** Верхнее давление */
    arterialRangeUp?: InputMaybe<Scalars['Int']['input']>;
    /** Рост */
    height?: InputMaybe<Scalars['Float']['input']>;
    /** Вес */
    weight?: InputMaybe<Scalars['Float']['input']>;
};

export type TInputOrganization = {
    /** Идентификационный номер налогоплательщика организации (ИНН) */
    inn: Scalars['INN']['input'];
    /** Название организации */
    name?: InputMaybe<Scalars['String']['input']>;
    /** Список организаций пользователя (как владельца) */
    owner?: InputMaybe<TInputPeople>;
};

export type TInputPassport = {
    /** Вручную подтвержденный адрес регистрации в CRM */
    approvedRegistration?: InputMaybe<TInputHabitation>;
    /** Дата рождения */
    birthDate?: InputMaybe<Scalars['BirthDate']['input']>;
    /** Место рождения */
    birthPlace?: InputMaybe<Scalars['String']['input']>;
    /** Ведомство */
    departmentDoc?: InputMaybe<Scalars['String']['input']>;
    /** Имя */
    firstName?: InputMaybe<Scalars['StrictString']['input']>;
    /** Пол клиента */
    gender?: InputMaybe<EGender>;
    /** Дата выдачи */
    issueDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Код подразделения */
    issueId?: InputMaybe<Scalars['IssueId']['input']>;
    /** Кем выдан паспорт */
    issuedBy?: InputMaybe<Scalars['String']['input']>;
    /** Фамилия */
    lastName?: InputMaybe<Scalars['String']['input']>;
    /** Отчество */
    middleName?: InputMaybe<Scalars['String']['input']>;
    /** Номер */
    number: Scalars['PassportNumber']['input'];
    /** Тип паспорта */
    passportType?: InputMaybe<Scalars['String']['input']>;
    /** Вручную подтвержденный паспорт в CRM */
    profile?: InputMaybe<TInputCommon>;
    /** Дата получения документа */
    receiptDocDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Серия */
    series: Scalars['PassportSeries']['input'];
    /** Источник паспорта (default или approved) */
    source: EPassportSource;
    /** Тип документа */
    type?: InputMaybe<EDocumentType>;
    updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TInputPeople = {
    /** Дополнительный доход, руб. */
    additionalIncome?: InputMaybe<Scalars['String']['input']>;
    /** Дополнительный телефон */
    additionalPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
    /** Владелец дополнительного телефона */
    additionalPhoneOwner?: InputMaybe<EAdditionalPhoneOwner>;
    additionalPhoneOwnerFIO?: InputMaybe<Scalars['String']['input']>;
    /** Псевдоним объекта */
    alias?: InputMaybe<Scalars['String']['input']>;
    /** Дата рождения */
    birthDate: Scalars['BirthDate']['input'];
    /** Место рождения */
    birthPlace?: InputMaybe<Scalars['String']['input']>;
    /** Страхователь в расчете КАСКО */
    cascoInsuredCars?: InputMaybe<Array<InputMaybe<TInputCar>>>;
    /** Гражданство */
    citizenship?: InputMaybe<ECitizenship>;
    creditRating?: InputMaybe<TInputNestedPeopleCreditRating>;
    creditSelection?: InputMaybe<TInputNestedPeopleCreditSelection>;
    /** Залоговое авто в кредитах */
    creditSelectionPledgeCar?: InputMaybe<TInputCar>;
    /** Адрес залога в кредитах */
    creditSelectionPledgeHabitation?: InputMaybe<TInputHabitation>;
    /** Количество иждивенцев */
    dependentsCount?: InputMaybe<EDependents>;
    /** Водители */
    driveCars?: InputMaybe<Array<InputMaybe<TInputCar>>>;
    driverLicence?: InputMaybe<TInputNestedPeopleDriverLicence>;
    /** Уровень образования */
    education?: InputMaybe<EEducation>;
    /** Email. Адрес электронной почты */
    email?: InputMaybe<Scalars['EmailAddress']['input']>;
    /** Тип занятости */
    employmentType?: InputMaybe<EEmploymentType>;
    /** Количество детей */
    exactDependentsNumber?: InputMaybe<Scalars['String']['input']>;
    /** Имя */
    firstName: Scalars['StrictString']['input'];
    /** Стоимость аренды квартиры, руб. */
    flatRentAmount?: InputMaybe<Scalars['String']['input']>;
    /** Пол */
    gender?: InputMaybe<EGender>;
    /** Уникальный хеш клиента */
    hash?: InputMaybe<Scalars['String']['input']>;
    /** Флаг наличия квартиры */
    haveApartment?: InputMaybe<EPropertyType>;
    /** Идентификационный номер налогоплательщика (ИНН) */
    inn?: InputMaybe<Scalars['INN']['input']>;
    /** Адрес страхования недвижимости */
    insuredHabitations?: InputMaybe<Array<InputMaybe<TInputHabitation>>>;
    internationalPassport?: InputMaybe<TInputNestedPeopleInternationalPassport>;
    job?: InputMaybe<TInputNestedPeopleJob>;
    /** Фамилия */
    lastName: Scalars['StrictString']['input'];
    /** Адрес проживания */
    livingHabitation?: InputMaybe<TInputHabitation>;
    /** Суммарный платеж по действующим кредитам, руб. */
    loanPaymentTotal?: InputMaybe<Scalars['String']['input']>;
    /** Семейное положение */
    maritalStatus?: InputMaybe<EFamilyStatus>;
    mfo?: InputMaybe<TInputNestedPeopleMfo>;
    /** Отчество */
    middleName?: InputMaybe<Scalars['StrictString']['input']>;
    /** Ежемесячный доход, руб. */
    monthlyIncome?: InputMaybe<Scalars['String']['input']>;
    /** Адрес ипотеки */
    mortgageHabitations?: InputMaybe<TInputHabitation>;
    mortgageInsurance?: InputMaybe<TInputNestedPeopleMortgageInsurance>;
    /** Список организаций пользователя (как владельца) */
    organizations?: InputMaybe<Array<InputMaybe<TInputOrganization>>>;
    osago?: InputMaybe<TInputNestedPeopleOsago>;
    /** Страхователь авто в ОСАГО */
    osagoInsuredCars?: InputMaybe<Array<InputMaybe<TInputCar>>>;
    /** Водители в расчете ОСАГО */
    osagoSelectedCars?: InputMaybe<Array<InputMaybe<TInputCar>>>;
    /** Владелец авто */
    ownCars?: InputMaybe<Array<InputMaybe<TInputCar>>>;
    /** Владельцы недвижимости */
    ownHabitations?: InputMaybe<Array<InputMaybe<TInputHabitation>>>;
    /** Принадлежность к владельцу профиля */
    ownerRelation?: InputMaybe<ERelationStatus>;
    passport?: InputMaybe<TInputNestedPeoplePassport>;
    /** Телефон */
    phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
    physiology?: InputMaybe<TInputNestedPeoplePhysiology>;
    /** Залоговое авто в кредитах */
    pledgeCar?: InputMaybe<Array<InputMaybe<TInputCar>>>;
    /** Адрес залога в кредитах */
    pledgeHabitations?: InputMaybe<Array<InputMaybe<TInputHabitation>>>;
    /** Бывшее имя */
    previousFirstName?: InputMaybe<Scalars['String']['input']>;
    /** Бывшая фамилия */
    previousLastName?: InputMaybe<Scalars['String']['input']>;
    /** Бывшее отчество */
    previousMiddleName?: InputMaybe<Scalars['String']['input']>;
    /** credit people */
    profileFromCreditPeople?: InputMaybe<TInputCommon>;
    /** mfo people */
    profileFromCreditRatingPeople?: InputMaybe<TInputCommon>;
    /** mfo people */
    profileFromMfoPeople?: InputMaybe<TInputCommon>;
    /** mpl people */
    profileFromMplPeople?: InputMaybe<TInputCommon>;
    /** OSAGO People */
    profileFromOsagoPeople?: InputMaybe<TInputCommon>;
    /** Red people */
    redPeople?: InputMaybe<TInputCommon>;
    /** Адрес регистрации! */
    registrationHabitation?: InputMaybe<TInputHabitation>;
    /** Расходы на аренду и ЖКХ в месяц, руб. */
    rentCost?: InputMaybe<Scalars['String']['input']>;
    /** Заработная плата, руб. */
    salary?: InputMaybe<Scalars['String']['input']>;
    /** Банк, в котором ведется зарплатный проект */
    salaryBank?: InputMaybe<Scalars['String']['input']>;
    /** Страховой номер индивидуального лицевого счёта (СНИЛС) */
    snils?: InputMaybe<Scalars['SNILS']['input']>;
    /** Документ, подтверждающий платежеспособность */
    solvencyProof?: InputMaybe<EIncomeConfirmation>;
    /** Тестовая ссылка */
    testo2o?: InputMaybe<TInputCar>;
    /** Причина отсутствия занятости */
    unemploymentReason?: InputMaybe<EUnemploymentReason>;
    /** Тестовое описание причины отсутствия занятости */
    unemploymentReasonText?: InputMaybe<Scalars['String']['input']>;
    updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
    /** Общий рабочий стаж */
    workExperience?: InputMaybe<EWorkExperience>;
};

export type TInputProductMeta = {
    meta?: InputMaybe<Scalars['String']['input']>;
};

export type TInputProfile = {
    /** Водительское удостоверение (ЕСИА) */
    ESIADriverLicense?: InputMaybe<TInputEsiaDriverLicense>;
    /** Электронная книжка ЕСИА */
    ESIAEWorkbook?: InputMaybe<TInputEsiaeWorkbook>;
    /** Владелец профиля ЕСИА */
    ESIAOwner?: InputMaybe<TInputEsiaPeople>;
    /** Паспорт гражданина РФ (ЕСИА) */
    ESIAPassport?: InputMaybe<TInputEsiaPassport>;
    /** В ручную подтвержденный паспорт ЕСИА */
    ESIAPassportApproved?: InputMaybe<TInputEsiaPassport>;
    ESIAPassportHistory?: InputMaybe<Array<InputMaybe<TInputEsiaPassport>>>;
    /** Транспортное средство (ESIA) */
    ESIAVehicle?: InputMaybe<Array<InputMaybe<TInputEsiaVehicle>>>;
    /** Справка 2-НДФЛ */
    NDFL2?: InputMaybe<Array<InputMaybe<TInputNdfl2>>>;
    /** Я принимаю оферту сервиса и условия обработки персональных данных */
    acceptOffer?: InputMaybe<Scalars['Boolean']['input']>;
    /** Дата и время согласия с офертой и условиями обработки персональных данных */
    acceptOfferTime?: InputMaybe<Scalars['String']['input']>;
    /** Вручную подтвержденный паспорт в CRM */
    approvedPassport?: InputMaybe<TInputPassport>;
    /** Аватар профиля */
    avatar?: InputMaybe<Scalars['String']['input']>;
    /** credit people */
    creditPeople?: InputMaybe<TInputPeople>;
    /** mfo people */
    creditRatingPeople?: InputMaybe<TInputPeople>;
    /** Email клиента */
    email?: InputMaybe<Scalars['EmailAddress']['input']>;
    emailConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
    /** Имя identity */
    firstName?: InputMaybe<Scalars['String']['input']>;
    /** Фамилия identity */
    lastName?: InputMaybe<Scalars['String']['input']>;
    /** Введенный вручную вне какого-либо продукта автомобиль */
    manualInputCars?: InputMaybe<Array<InputMaybe<TInputCar>>>;
    /** Руками введенный адрес после регистрации ЕСИА */
    manualInputESIARegistrationHabitation?: InputMaybe<TInputHabitation>;
    /** Введенная вручную вне какого-либо продукта недвижимость */
    manualInputHabitations?: InputMaybe<Array<InputMaybe<TInputHabitation>>>;
    /** mfo people */
    mfoPeople?: InputMaybe<TInputPeople>;
    /** mpl people */
    mplPeople?: InputMaybe<TInputPeople>;
    /** OSAGO People */
    osagoPeople?: InputMaybe<TInputPeople>;
    phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
    phoneConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
    /** Red people */
    redPeople?: InputMaybe<TInputPeople>;
    /** Источник установки приложения */
    sourceInstallApp?: InputMaybe<Scalars['String']['input']>;
};

export type TInputProfileRaw = {
    /** Я принимаю оферту сервиса и условия обработки персональных данных */
    acceptOffer?: InputMaybe<Scalars['Boolean']['input']>;
    /** Дата и время согласия с офертой и условиями обработки персональных данных */
    acceptOfferTime?: InputMaybe<Scalars['String']['input']>;
    /** Аватар профиля */
    avatar?: InputMaybe<Scalars['String']['input']>;
    /** Email клиента */
    email?: InputMaybe<Scalars['EmailAddress']['input']>;
    emailConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
    /** Имя identity */
    firstName?: InputMaybe<Scalars['String']['input']>;
    /** Фамилия identity */
    lastName?: InputMaybe<Scalars['String']['input']>;
    phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
    phoneConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
    /** Источник установки приложения */
    sourceInstallApp?: InputMaybe<Scalars['String']['input']>;
};

export type TMutation = {
    __typename?: 'Mutation';
    deleteProfile?: Maybe<TDeleteProfileResult>;
    deleteProfileObject?: Maybe<Scalars['Void']['output']>;
    saveCar?: Maybe<TCar>;
    saveData?: Maybe<TProfile>;
    saveESIA?: Maybe<TProfile>;
    saveESIAFlags?: Maybe<TEsiaClientFlags>;
    saveHabitation?: Maybe<THabitation>;
    saveHabitationFields?: Maybe<THabitation>;
    saveOwner?: Maybe<TPeople>;
    savePeople?: Maybe<TPeople>;
    saveProductMeta?: Maybe<Scalars['Void']['output']>;
    saveProfile?: Maybe<TProfile>;
    saveProfileState?: Maybe<TProfile>;
    saveTinkoffTmp?: Maybe<Scalars['Void']['output']>;
};

export type TMutationDeleteProfileArgs = {
    options?: InputMaybe<TInputDeleteProfileParams>;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationDeleteProfileObjectArgs = {
    objectHash: Scalars['String']['input'];
    objectTypeName: EObjectTypeName;
    options?: InputMaybe<TInputDeleteProfileObjectParams>;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSaveCarArgs = {
    data: TInputCar;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSaveDataArgs = {
    data: TInputCommon;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSaveEsiaArgs = {
    data: TInputEsia;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSaveEsiaFlagsArgs = {
    data: TInputEsiaClientFlags;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSaveHabitationArgs = {
    data: TInputHabitation;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSaveHabitationFieldsArgs = {
    data: TInputHabitationFields;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSaveOwnerArgs = {
    data: TInputPeople;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSavePeopleArgs = {
    data: TInputPeople;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSaveProductMetaArgs = {
    data: TInputProductMeta;
    key: Scalars['String']['input'];
    ownerId: Scalars['Int']['input'];
};

export type TMutationSaveProfileArgs = {
    data: TInputProfileRaw;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSaveProfileStateArgs = {
    data: TInputProfile;
    form: Scalars['String']['input'];
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TMutationSaveTinkoffTmpArgs = {
    data: Scalars['String']['input'];
    ownerId: Scalars['Int']['input'];
    type: Scalars['String']['input'];
};

export type TNdfl2 = {
    __typename?: 'NDFL2';
    /** Номер корректировки */
    correctionNumber: Scalars['String']['output'];
    /** Дата формирования справки */
    formationDate: Scalars['DateTimeISO']['output'];
    /** - */
    hash?: Maybe<Scalars['String']['output']>;
    income?: Maybe<Array<Maybe<TNestedNdfl2Income>>>;
    person?: Maybe<TNestedNdfl2Person>;
    /** Справка 2-НДФЛ */
    profile?: Maybe<TProfile>;
    /** Источник справки */
    source: Scalars['String']['output'];
    taxAgent?: Maybe<TNestedNdfl2TaxAgent>;
    /** Отчетный год */
    year: Scalars['Int']['output'];
};

export type TNestedCarCasco = {
    __typename?: 'NestedCarCasco';
    /** Тип документа при вводе данных в каско */
    documentType?: Maybe<Scalars['String']['output']>;
    /** Залоговый ли автомобиль */
    isPledge?: Maybe<Scalars['String']['output']>;
    /** Количество ключей у автомобиля */
    keysNumber?: Maybe<Scalars['String']['output']>;
    /** Пробег автомобиля */
    mileage?: Maybe<Scalars['String']['output']>;
    /** Id банка, в котором автомобиль оставлен в залог */
    pledgeBankId?: Maybe<Scalars['String']['output']>;
    /** Номер полиса КАСКО */
    policyNumber?: Maybe<Scalars['String']['output']>;
    /** Оригинал ли птс или дубликат */
    ptsIsOriginal?: Maybe<Scalars['String']['output']>;
};

export type TNestedCarDiagnosticCard = {
    __typename?: 'NestedCarDiagnosticCard';
    /** Дата окончания действия диагностической карты */
    expirationDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Номер диагностической карты */
    number?: Maybe<Scalars['String']['output']>;
};

export type TNestedCarEPts = {
    __typename?: 'NestedCarEPts';
    /** Дата выдачи еПТС */
    issueDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Номер еПТС */
    number?: Maybe<Scalars['String']['output']>;
};

export type TNestedCarOsago = {
    __typename?: 'NestedCarOsago';
    /** Тип документа при вводе данных в осаго */
    documentType?: Maybe<EOsagoCarDocumentType>;
    /** Номер полиса ОСАГО */
    policyNumber?: Maybe<Scalars['String']['output']>;
    /** Дата начала действия полиса осаго */
    policyStartDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Id продукта, который пользователь выбрал на выдаче */
    productId?: Maybe<Scalars['String']['output']>;
    /** Id выдачи в сервисе осаго */
    searchId?: Maybe<Scalars['String']['output']>;
};

export type TNestedCarPts = {
    __typename?: 'NestedCarPts';
    /** Дата выдачи ПТС */
    issueDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Серия и номер ПТС */
    number?: Maybe<Scalars['String']['output']>;
};

export type TNestedCarSts = {
    __typename?: 'NestedCarSts';
    /** Дата выдачи СТС */
    issueDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Номер СТС */
    number?: Maybe<Scalars['String']['output']>;
};

export type TNestedEsiaeWorkbookEvents = {
    __typename?: 'NestedESIAEWorkbookEvents';
    /** ЕСИА */
    date?: Maybe<Scalars['DateTimeISO']['output']>;
    dismissalBasis?: Maybe<TNestedEsiaeWorkbookEventsDismissalBasis>;
    /** ЕСИА */
    dismissalReason?: Maybe<Scalars['String']['output']>;
    /** ЕСИА */
    division?: Maybe<Scalars['String']['output']>;
    /** ЕСИА */
    isPartTimeJob?: Maybe<Scalars['Boolean']['output']>;
    organization?: Maybe<TNestedEsiaeWorkbookEventsOrganization>;
    /** ЕСИА */
    position?: Maybe<Scalars['String']['output']>;
    reason?: Maybe<Array<Maybe<TNestedEsiaeWorkbookEventsReason>>>;
    /** ЕСИА */
    type?: Maybe<Scalars['Int']['output']>;
    /** ЕСИА */
    uuid?: Maybe<Scalars['String']['output']>;
};

export type TNestedEsiaeWorkbookEventsDismissalBasis = {
    __typename?: 'NestedESIAEWorkbookEventsDismissalBasis';
    /** ЕСИА */
    basisItem?: Maybe<Scalars['String']['output']>;
    /** ЕСИА */
    basisParagraph?: Maybe<Scalars['String']['output']>;
    /** ЕСИА dismissalBasis basisPart */
    basisPart?: Maybe<Scalars['String']['output']>;
    /** ЕСИА */
    normativeDocument?: Maybe<Scalars['String']['output']>;
    /** ЕСИА */
    type?: Maybe<Scalars['String']['output']>;
};

export type TNestedEsiaeWorkbookEventsOrganization = {
    __typename?: 'NestedESIAEWorkbookEventsOrganization';
    /** ЕСИА */
    inn?: Maybe<Scalars['INN']['output']>;
    /** ЕСИА */
    kpp?: Maybe<Scalars['String']['output']>;
    /** ЕСИА */
    orgName?: Maybe<Scalars['String']['output']>;
    /** ЕСИА */
    regNumber?: Maybe<Scalars['String']['output']>;
};

export type TNestedEsiaeWorkbookEventsReason = {
    __typename?: 'NestedESIAEWorkbookEventsReason';
    /** ЕСИА events reason date */
    date?: Maybe<Scalars['DateTimeISO']['output']>;
    /** ЕСИА events reason document */
    document?: Maybe<Scalars['String']['output']>;
    /** ЕСИА events reason number */
    number?: Maybe<Scalars['String']['output']>;
};

export type TNestedEsiaHabitationAddress = {
    __typename?: 'NestedESIAHabitationAddress';
    /** Район в регионе */
    area?: Maybe<Scalars['String']['output']>;
    /** Тип района в регионе */
    areaType?: Maybe<Scalars['String']['output']>;
    /** Здание ESIA */
    block?: Maybe<Scalars['String']['output']>;
    blockType?: Maybe<Scalars['String']['output']>;
    /** Кадастровый номер ESIA */
    cadastralNumber?: Maybe<Scalars['CadastralNumber']['output']>;
    /** Город / населенный пункт */
    city?: Maybe<Scalars['String']['output']>;
    cityType?: Maybe<Scalars['String']['output']>;
    /** Год постройки дома */
    constructionYear?: Maybe<Scalars['year']['output']>;
    /** Код страны ESIA */
    countryId?: Maybe<Scalars['String']['output']>;
    /** Адрес ESIA */
    dadata?: Maybe<Scalars['String']['output']>;
    /** Код ФИАС ESIA */
    fiasId: Scalars['String']['output'];
    /** Уровень детализации, до которого адрес найден в ФИАС. ESIA */
    fiasLevel?: Maybe<Scalars['String']['output']>;
    /** Помещение ESIA */
    flat?: Maybe<Scalars['String']['output']>;
    /** Площадь */
    flatArea?: Maybe<Scalars['String']['output']>;
    /** Код ФИАС квартиры ЕСИА */
    flatFiasId?: Maybe<Scalars['String']['output']>;
    flatType?: Maybe<Scalars['String']['output']>;
    /** Номер этажа */
    floorNumber?: Maybe<Scalars['String']['output']>;
    /** Количество этажей */
    floorsNumber?: Maybe<Scalars['String']['output']>;
    /**
     * Корпус ESIA
     * @deprecated Не использовать.
     */
    frame?: Maybe<Scalars['String']['output']>;
    /** Дом ESIA */
    house?: Maybe<Scalars['String']['output']>;
    houseType?: Maybe<Scalars['String']['output']>;
    /** КЛАДР-код адреса */
    kladrId?: Maybe<Scalars['String']['output']>;
    /** Индекс ESIA */
    postalCode?: Maybe<Scalars['String']['output']>;
    /** Регион ESIA */
    region?: Maybe<Scalars['String']['output']>;
    regionType?: Maybe<Scalars['String']['output']>;
    /** Количество комнат */
    roomsCount?: Maybe<Scalars['String']['output']>;
    /** Населенный пункт */
    settlement?: Maybe<Scalars['String']['output']>;
    /** Тип населенного пункт */
    settlementType?: Maybe<Scalars['String']['output']>;
    /** Улица ESIA */
    street?: Maybe<Scalars['String']['output']>;
    streetType?: Maybe<Scalars['String']['output']>;
};

export type TNestedEsiaVehicleOwner = {
    __typename?: 'NestedESIAVehicleOwner';
    /** День рождения владельца машины */
    birthDate?: Maybe<Scalars['BirthDate']['output']>;
    /** Место рождения */
    birthPlace?: Maybe<Scalars['String']['output']>;
    /** Имя */
    firstName?: Maybe<Scalars['String']['output']>;
    /** Фамилия */
    lastName?: Maybe<Scalars['String']['output']>;
    /** Отчество */
    middleName?: Maybe<Scalars['String']['output']>;
};

export type TNestedEsiaVehicleOwnerPeriod = {
    __typename?: 'NestedESIAVehicleOwnerPeriod';
    /** Дата окончания */
    dateEnd?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Дата начала */
    dateStart?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Тип владельца */
    ownerType?: Maybe<Scalars['String']['output']>;
};

export type TNestedEsiaVehiclePts = {
    __typename?: 'NestedESIAVehiclePts';
    /** Дата выдачи */
    ptsDocumentDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Кем выдан */
    ptsIssueAgency?: Maybe<Scalars['String']['output']>;
    /** Серия и номер */
    ptsSeriesNumber?: Maybe<Scalars['String']['output']>;
    /** Доп инфа по выдаче */
    ptsSpecialTags?: Maybe<Scalars['String']['output']>;
    /** Тип */
    ptsType?: Maybe<Scalars['String']['output']>;
};

export type TNestedEsiaVehicleRegAction = {
    __typename?: 'NestedESIAVehicleRegAction';
    /** confidentSign */
    confidentSign?: Maybe<Scalars['Boolean']['output']>;
    /** Признак лизинга */
    leasingFlag?: Maybe<Scalars['Boolean']['output']>;
    /** Причина регистрации */
    regActionName?: Maybe<Scalars['String']['output']>;
    /** Дата выдачи */
    regDocumentDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Кем выдан */
    regIssueAgency?: Maybe<Scalars['String']['output']>;
};

export type TNestedEsiaVehicleRegistrationDoc = {
    __typename?: 'NestedESIAVehicleRegistrationDoc';
    /** Дата выдачи */
    regDocDocumentDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Кем выдан */
    regDocIssueAgency?: Maybe<Scalars['String']['output']>;
    /** Серия и номер */
    regDocSeriesNumber?: Maybe<Scalars['String']['output']>;
    /** Тип */
    regDocType?: Maybe<Scalars['String']['output']>;
    /** Комментарий */
    specialTags?: Maybe<Scalars['String']['output']>;
};

export type TNestedHabitationAddress = {
    __typename?: 'NestedHabitationAddress';
    area?: Maybe<Scalars['String']['output']>;
    areaType?: Maybe<Scalars['String']['output']>;
    /** Корпус */
    block?: Maybe<Scalars['String']['output']>;
    blockType?: Maybe<Scalars['String']['output']>;
    /** Кадастровый номер */
    cadastralNumber?: Maybe<Scalars['CadastralNumber']['output']>;
    /** Город / населенный пункт */
    city?: Maybe<Scalars['String']['output']>;
    cityType?: Maybe<Scalars['String']['output']>;
    /** Год постройки дома */
    constructionYear?: Maybe<Scalars['year']['output']>;
    /** Код страны */
    countryId?: Maybe<Scalars['String']['output']>;
    /** Адрес */
    dadata?: Maybe<Scalars['String']['output']>;
    /** ФИАС-код адреса (идентификатор ФИАС) */
    fiasId: Scalars['String']['output'];
    /** Уровень детализации, до которого адрес найден в ФИАС */
    fiasLevel?: Maybe<Scalars['String']['output']>;
    /** Квартира */
    flat?: Maybe<Scalars['String']['output']>;
    /** Площадь */
    flatArea?: Maybe<Scalars['String']['output']>;
    /** Код ФИАС квартиры */
    flatFiasId?: Maybe<Scalars['String']['output']>;
    flatType?: Maybe<Scalars['String']['output']>;
    /** Номер этажа */
    floorNumber?: Maybe<Scalars['String']['output']>;
    /** Количество этажей */
    floorsNumber?: Maybe<Scalars['String']['output']>;
    /** Дом */
    house?: Maybe<Scalars['String']['output']>;
    houseType?: Maybe<Scalars['String']['output']>;
    /** КЛАДР-код адреса */
    kladrId?: Maybe<Scalars['String']['output']>;
    /** Роут адреса из сервиса locations */
    locationRoute?: Maybe<Scalars['String']['output']>;
    /** Почтовый индекс РФ */
    postalCode?: Maybe<Scalars['String']['output']>;
    /** Регион */
    region?: Maybe<Scalars['String']['output']>;
    regionType?: Maybe<Scalars['String']['output']>;
    /**
     * Дата регистрации
     * @deprecated Поле относится к паспорту, используйте People.passport.addressRegistrationDate
     */
    registrationDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Количество комнат */
    roomsCount?: Maybe<Scalars['String']['output']>;
    settlement?: Maybe<Scalars['String']['output']>;
    settlementType?: Maybe<Scalars['String']['output']>;
    /** Улица */
    street?: Maybe<Scalars['String']['output']>;
    /** Тип улицы */
    streetType?: Maybe<Scalars['String']['output']>;
};

export type TNestedHabitationCreditSelection = {
    __typename?: 'NestedHabitationCreditSelection';
    pledgeOwnerFamilyStatus?: Maybe<EFamilyStatus>;
    pledgeOwnerFirstName?: Maybe<Scalars['StrictString']['output']>;
    pledgeOwnerLastName?: Maybe<Scalars['String']['output']>;
    pledgeOwnerMiddleName?: Maybe<Scalars['String']['output']>;
    pledgeOwnerPhone?: Maybe<Scalars['PhoneNumber']['output']>;
    pledgeOwnerRelationship?: Maybe<ERelationStatus>;
    pledgeOwnerSingle?: Maybe<Scalars['String']['output']>;
};

export type TNestedHabitationProperties = {
    __typename?: 'NestedHabitationProperties';
    /** Газификация */
    hasGas?: Maybe<Scalars['Boolean']['output']>;
    /** Наличие перепланировки */
    hasRedevelopment?: Maybe<Scalars['Boolean']['output']>;
    /** Имеются ли деревянные перекрытия */
    hasWoodenFloors?: Maybe<Scalars['Boolean']['output']>;
    /** Есть ли газ в помещении */
    isGasified?: Maybe<Scalars['Boolean']['output']>;
    /** Материал перекрытий */
    overlapMaterial?: Maybe<Scalars['String']['output']>;
    /** Материал стен */
    wallMaterial?: Maybe<Scalars['String']['output']>;
};

export type TNestedNdfl2Income = {
    __typename?: 'NestedNDFL2Income';
    /** Доходы, облагаемые по ставке: Код бюджетной классификации */
    budgetClassificationCode?: Maybe<Scalars['String']['output']>;
    incomeTaxableByMonth?: Maybe<Array<Maybe<TNestedNdfl2IncomeIncomeTaxableByMonth>>>;
    /** Доходы, облагаемые по ставке: Ставка */
    taxRate?: Maybe<Scalars['Int']['output']>;
    total?: Maybe<TNestedNdfl2IncomeTotal>;
};

export type TNestedNdfl2IncomeIncomeTaxableByMonth = {
    __typename?: 'NestedNDFL2IncomeIncomeTaxableByMonth';
    /** Доходы, облагаемые по ставке: Сумма дохода */
    amount?: Maybe<Scalars['Float']['output']>;
    /** Доходы, облагаемые по ставке: Код дохода */
    code?: Maybe<Scalars['String']['output']>;
    deduction?: Maybe<TNestedNdfl2IncomeIncomeTaxableByMonthDeduction>;
    /** Доходы, облагаемые по ставке: месяц */
    month?: Maybe<Scalars['String']['output']>;
};

export type TNestedNdfl2IncomeIncomeTaxableByMonthDeduction = {
    __typename?: 'NestedNDFL2IncomeIncomeTaxableByMonthDeduction';
    /** Доходы, облагаемые по ставке: Сумма вычета */
    amount?: Maybe<Scalars['Float']['output']>;
    /** Доходы, облагаемые по ставке: Код вычета */
    code?: Maybe<Scalars['String']['output']>;
};

export type TNestedNdfl2IncomeTotal = {
    __typename?: 'NestedNDFL2IncomeTotal';
    /** Общие суммы дохода и налога: Общая сумма доходов */
    amount?: Maybe<Scalars['Float']['output']>;
    /** Общие суммы дохода и налога: Сумма фиксированных  авансовых платежей */
    fixedAdvancePaymentAmount?: Maybe<Scalars['Float']['output']>;
    /** Общие суммы дохода и налога: Сумма налога исчисленная  */
    taxAmount?: Maybe<Scalars['Float']['output']>;
    /** Общие суммы дохода и налога: Сумма налога перечисленная */
    taxAmountPaid?: Maybe<Scalars['Float']['output']>;
    /** Общие суммы дохода и налога: Налоговая база */
    taxBase?: Maybe<Scalars['Float']['output']>;
    /** Общие суммы дохода и налога: Сумма налога на прибыль организаций, подлежащая зачету */
    taxOrganizationAmount?: Maybe<Scalars['Float']['output']>;
    /** Общие суммы дохода и налога: Сумма налога, излишне  удержанная налоговым агентом  */
    taxOverWithheld?: Maybe<Scalars['Float']['output']>;
    /** Общие суммы дохода и налога: Сумма налога удержанная  */
    taxWithholding?: Maybe<Scalars['Float']['output']>;
};

export type TNestedNdfl2Person = {
    __typename?: 'NestedNDFL2Person';
    /** Получатель доходов: дата рождения налогоплательщика */
    birthDate?: Maybe<Scalars['BirthDate']['output']>;
    /** Получатель доходов: гражданство налогоплательщика */
    citizenship?: Maybe<Scalars['String']['output']>;
    document?: Maybe<TNestedNdfl2PersonDocument>;
    /** Получатель доходов: Имя */
    firstName?: Maybe<Scalars['String']['output']>;
    /** Получатель доходов: Фамилия */
    lastName?: Maybe<Scalars['String']['output']>;
    /** Получатель доходов: Отчество */
    middleName?: Maybe<Scalars['String']['output']>;
    /** Получатель доходов: статус налогоплательщика */
    status?: Maybe<Scalars['String']['output']>;
};

export type TNestedNdfl2PersonDocument = {
    __typename?: 'NestedNDFL2PersonDocument';
    /** Получатель доходов: код документа, подтвержающего личность */
    code?: Maybe<Scalars['String']['output']>;
    /** Получатель доходов: серия и номер документа, подтвержающего личность */
    seriesNumber?: Maybe<Scalars['String']['output']>;
};

export type TNestedNdfl2TaxAgent = {
    __typename?: 'NestedNDFL2TaxAgent';
    legalEntity?: Maybe<TNestedNdfl2TaxAgentLegalEntity>;
    naturalPerson?: Maybe<TNestedNdfl2TaxAgentNaturalPerson>;
    /** Данные о налоговом агенте: ОКТМО */
    oktmo?: Maybe<Scalars['String']['output']>;
    /** Данные о налоговом агенте: Телефон */
    phone?: Maybe<Scalars['PhoneNumber']['output']>;
};

export type TNestedNdfl2TaxAgentLegalEntity = {
    __typename?: 'NestedNDFL2TaxAgentLegalEntity';
    /** Данные о налоговом агенте (юридическое лицо): ИНН */
    inn?: Maybe<Scalars['INN']['output']>;
    /** Данные о налоговом агенте (юридическое лицо): КПП */
    kpp?: Maybe<Scalars['String']['output']>;
    reorganizedInfo?: Maybe<TNestedNdfl2TaxAgentLegalEntityReorganizedInfo>;
    /** Данные о налоговом агенте (юридическое лицо): наименование организации */
    title?: Maybe<Scalars['String']['output']>;
};

export type TNestedNdfl2TaxAgentLegalEntityReorganizedInfo = {
    __typename?: 'NestedNDFL2TaxAgentLegalEntityReorganizedInfo';
    /** Код формы реорганизации (ликвидации)/закрытия */
    form?: Maybe<Scalars['String']['output']>;
    /** ИНН реорганизованной организации/закрытого подразделения */
    inn?: Maybe<Scalars['INN']['output']>;
    /** КПП реорганизованной организации/закрытого подразделения */
    kpp?: Maybe<Scalars['String']['output']>;
};

export type TNestedNdfl2TaxAgentNaturalPerson = {
    __typename?: 'NestedNDFL2TaxAgentNaturalPerson';
    /** Данные о налоговом агенте (физическое лицо): Имя */
    firstName?: Maybe<Scalars['String']['output']>;
    /** Данные о налоговом агенте (физическое лицо): ИНН */
    inn?: Maybe<Scalars['String']['output']>;
    /** Данные о налоговом агенте (физическое лицо): Фамилия */
    lastName?: Maybe<Scalars['String']['output']>;
    /** Данные о налоговом агенте (физическое лицо): Отчество */
    middleName?: Maybe<Scalars['String']['output']>;
};

export type TNestedPeopleCreditRating = {
    __typename?: 'NestedPeopleCreditRating';
    /** Дата обновления анкеты кредитного рейтинга */
    dateUpdate?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TNestedPeopleCreditSelection = {
    __typename?: 'NestedPeopleCreditSelection';
    /** Сумма кредита */
    amount?: Maybe<Scalars['String']['output']>;
    /** Марка автомобиля, на который берется кредит */
    autoBrand?: Maybe<Scalars['String']['output']>;
    /** Модель автомобиля, на который берется кредит */
    autoModel?: Maybe<Scalars['String']['output']>;
    /** Год автомобиля, на который берется кредит */
    autoYear?: Maybe<Scalars['year']['output']>;
    /** Залог */
    creditSecurity?: Maybe<ECreditSecurity>;
    /** Сумма личных средств */
    initialFee?: Maybe<Scalars['String']['output']>;
    /** id города получения кредита */
    obtainCityId?: Maybe<Scalars['String']['output']>;
    /** Роут города получения кредита из сервиса locations */
    obtainCityLocationRoute?: Maybe<Scalars['String']['output']>;
    /** Срок кредита */
    period?: Maybe<Scalars['String']['output']>;
    /** Госномер машины, которая в собственности у пользователя */
    propertyAutoRegistrationPlate?: Maybe<Scalars['RegistrationPlateScalar']['output']>;
    /** Цель кредита */
    purpose?: Maybe<ECreditPurpose>;
    /** Город получения кредита */
    region?: Maybe<Scalars['String']['output']>;
};

export type TNestedPeopleDriverLicence = {
    __typename?: 'NestedPeopleDriverLicence';
    /** Дата начала стажа по категории A */
    experienceAStartDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Дата начала стажа */
    experienceStartDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Дата выдачи первых прав */
    firstIssueDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Дата выдачи текущих прав */
    issueDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Серия и номер водительского удостоверения */
    number?: Maybe<Scalars['SerialNumber']['output']>;
    /** Серия и номер предыдущего водительского удостоверения */
    previousNumber?: Maybe<Scalars['String']['output']>;
};

export type TNestedPeopleInternationalPassport = {
    __typename?: 'NestedPeopleInternationalPassport';
    /** Место рождения */
    birthPlace?: Maybe<Scalars['String']['output']>;
    /** Дата окончания срока действия */
    expirationDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Имя в заграничном паспорте */
    firstName?: Maybe<Scalars['String']['output']>;
    /** Дата выдачи заграничного паспорта */
    issueDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Орган, выдавший заграничный паспорт */
    issuedBy?: Maybe<Scalars['String']['output']>;
    /** Фамилия в заграничном паспорте */
    lastName?: Maybe<Scalars['String']['output']>;
    /** Серия и номер заграничного паспорта */
    number?: Maybe<Scalars['SerialNumberInternationalPassport']['output']>;
};

export type TNestedPeopleJob = {
    __typename?: 'NestedPeopleJob';
    address?: Maybe<TNestedPeopleJobAddress>;
    /** Дата начала работы на текущем месте */
    lastExperienceStartDate?: Maybe<Scalars['DateTimeISO']['output']>;
    organization?: Maybe<TNestedPeopleJobOrganization>;
    /** Название должности */
    title?: Maybe<Scalars['String']['output']>;
    /** Тип должности */
    type?: Maybe<EJobPosition>;
};

export type TNestedPeopleJobAddress = {
    __typename?: 'NestedPeopleJobAddress';
    area?: Maybe<Scalars['String']['output']>;
    areaType?: Maybe<Scalars['String']['output']>;
    /** Корпус организации */
    block?: Maybe<Scalars['String']['output']>;
    blockType?: Maybe<Scalars['String']['output']>;
    /** Город / населенный пункт организации */
    city?: Maybe<Scalars['String']['output']>;
    cityType?: Maybe<Scalars['String']['output']>;
    /** Год постройки дома организации */
    constructionYear?: Maybe<Scalars['String']['output']>;
    /** Код страны */
    countryId?: Maybe<Scalars['String']['output']>;
    /** Адрес организации */
    dadata?: Maybe<Scalars['String']['output']>;
    /** ФИАС-код адреса (идентификатор ФИАС) */
    fiasId?: Maybe<Scalars['String']['output']>;
    /** Уровень детализации, до которого адрес найден в ФИАС */
    fiasLevel?: Maybe<Scalars['String']['output']>;
    /** Квартира организации */
    flat?: Maybe<Scalars['String']['output']>;
    /** Площадь квартиры организации */
    flatArea?: Maybe<Scalars['String']['output']>;
    flatType?: Maybe<Scalars['String']['output']>;
    /** Номер этажа */
    floorNumber?: Maybe<Scalars['String']['output']>;
    /** Количество этажей в доме организации */
    floorsNumber?: Maybe<Scalars['String']['output']>;
    /** Дом организации */
    house?: Maybe<Scalars['String']['output']>;
    houseType?: Maybe<Scalars['String']['output']>;
    /** КЛАДР-код адреса организации */
    kladrId?: Maybe<Scalars['String']['output']>;
    /** Роут адреса работы из сервиса locations */
    locationRoute?: Maybe<Scalars['String']['output']>;
    /** Почтовый индекс РФ организации */
    postalCode?: Maybe<Scalars['String']['output']>;
    /** Регион организации */
    region?: Maybe<Scalars['String']['output']>;
    regionType?: Maybe<Scalars['String']['output']>;
    /** Количество комнат в доме организации */
    roomsCount?: Maybe<Scalars['String']['output']>;
    settlement?: Maybe<Scalars['String']['output']>;
    settlementType?: Maybe<Scalars['String']['output']>;
    /** Улица организации */
    street?: Maybe<Scalars['String']['output']>;
    /** Тип улицы */
    streetType?: Maybe<Scalars['String']['output']>;
};

export type TNestedPeopleJobOrganization = {
    __typename?: 'NestedPeopleJobOrganization';
    /** Сфера деятельности организации */
    activity?: Maybe<EOrganizationActivity>;
    /** Идентификационный номер налогоплательщика (ИНН) */
    inn?: Maybe<Scalars['INN']['output']>;
    /** Наименование организации, где работает клиент */
    name?: Maybe<Scalars['String']['output']>;
    /** Основной государственный регистрационный номер (ОГРН) */
    ogrn?: Maybe<Scalars['OGRN']['output']>;
    /** Код ОКВЭД */
    okved?: Maybe<Scalars['String']['output']>;
    /** Рабочий телефон */
    phone?: Maybe<Scalars['PhoneNumber']['output']>;
    /** Организационно-правовая форма организации */
    type?: Maybe<EEmployerType>;
    /** Код ОПФ */
    typeCode?: Maybe<Scalars['String']['output']>;
};

export type TNestedPeopleMfo = {
    __typename?: 'NestedPeopleMfo';
    /** Сумма займа */
    amount?: Maybe<Scalars['String']['output']>;
    /** Идентификатор диалога в реакт анкете */
    conversationId?: Maybe<Scalars['String']['output']>;
    /** Срок займа */
    dateTo?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Срок займа */
    period?: Maybe<Scalars['String']['output']>;
};

export type TNestedPeopleMortgageInsurance = {
    __typename?: 'NestedPeopleMortgageInsurance';
    /** Место заключения кредитного договора */
    agreementAddress?: Maybe<Scalars['String']['output']>;
    /** Ежемесячный платеж по кредиту */
    annuityPayment?: Maybe<Scalars['Float']['output']>;
    /** Процентная ставка при отказе от страхования */
    cancellationCreditRate?: Maybe<Scalars['Float']['output']>;
    /** Дата заключения кредитного договора */
    contractAgreementDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Дата окончания кредитного договора */
    contractAgreementEndDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Номер кредитного договора */
    contractNumber?: Maybe<Scalars['String']['output']>;
    /** Начальная сумма кредита */
    initialAmount?: Maybe<Scalars['Float']['output']>;
    /** Процентная ставка */
    rate?: Maybe<Scalars['Float']['output']>;
};

export type TNestedPeopleOsago = {
    __typename?: 'NestedPeopleOsago';
    /** КБМ */
    kbm?: Maybe<Scalars['String']['output']>;
    /** Дата сохранения предыдущих прав в процессе оформления ОСАГО */
    previousLicenceInputDate?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TNestedPeoplePassport = {
    __typename?: 'NestedPeoplePassport';
    /** Дата регистрации */
    addressRegistrationDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Дата выдачи паспорта РФ */
    issueDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Орган, выдавший паспорт РФ */
    issuedBy?: Maybe<Scalars['String']['output']>;
    /** Код подразделения организации, выдавшей паспорт РФ */
    issuedByCode?: Maybe<Scalars['IssueId']['output']>;
    /** Серия и номер паспорта РФ */
    number?: Maybe<Scalars['SerialNumber']['output']>;
};

export type TNestedPeoplePhysiology = {
    __typename?: 'NestedPeoplePhysiology';
    /** Нижнее давление */
    arterialRangeDown?: Maybe<Scalars['Int']['output']>;
    /** Верхнее давление */
    arterialRangeUp?: Maybe<Scalars['Int']['output']>;
    /** Рост */
    height?: Maybe<Scalars['Float']['output']>;
    /** Вес */
    weight?: Maybe<Scalars['Float']['output']>;
};

export type TOrganization = {
    __typename?: 'Organization';
    hash?: Maybe<Scalars['String']['output']>;
    /** Идентификационный номер налогоплательщика организации (ИНН) */
    inn: Scalars['INN']['output'];
    /** Название организации */
    name?: Maybe<Scalars['String']['output']>;
    /** Список организаций пользователя (как владельца) */
    owner?: Maybe<TPeople>;
};

export type TPassport = {
    __typename?: 'Passport';
    /** Вручную подтвержденный адрес регистрации в CRM */
    approvedRegistration?: Maybe<THabitation>;
    /** Дата рождения */
    birthDate?: Maybe<Scalars['BirthDate']['output']>;
    /** Место рождения */
    birthPlace?: Maybe<Scalars['String']['output']>;
    /** Ведомство */
    departmentDoc?: Maybe<Scalars['String']['output']>;
    /** Имя */
    firstName?: Maybe<Scalars['StrictString']['output']>;
    /** Пол клиента */
    gender?: Maybe<EGender>;
    hash?: Maybe<Scalars['String']['output']>;
    /** Дата выдачи */
    issueDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Код подразделения */
    issueId?: Maybe<Scalars['IssueId']['output']>;
    /** Кем выдан паспорт */
    issuedBy?: Maybe<Scalars['String']['output']>;
    /** Фамилия */
    lastName?: Maybe<Scalars['String']['output']>;
    /** Отчество */
    middleName?: Maybe<Scalars['String']['output']>;
    /** Номер */
    number: Scalars['PassportNumber']['output'];
    /** Тип паспорта */
    passportType?: Maybe<Scalars['String']['output']>;
    /** Вручную подтвержденный паспорт в CRM */
    profile?: Maybe<TProfile>;
    /** Дата получения документа */
    receiptDocDate?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Серия */
    series: Scalars['PassportSeries']['output'];
    /** Источник паспорта (default или approved) */
    source: EPassportSource;
    /** Тип документа */
    type?: Maybe<EDocumentType>;
    updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TPeople = {
    __typename?: 'People';
    /** Дополнительный доход, руб. */
    additionalIncome?: Maybe<Scalars['String']['output']>;
    /** Дополнительный телефон */
    additionalPhone?: Maybe<Scalars['PhoneNumber']['output']>;
    /** Владелец дополнительного телефона */
    additionalPhoneOwner?: Maybe<EAdditionalPhoneOwner>;
    additionalPhoneOwnerFIO?: Maybe<Scalars['String']['output']>;
    /** Псевдоним объекта */
    alias?: Maybe<Scalars['String']['output']>;
    /** Дата рождения */
    birthDate: Scalars['BirthDate']['output'];
    /** Место рождения */
    birthPlace?: Maybe<Scalars['String']['output']>;
    /** Страхователь в расчете КАСКО */
    cascoInsuredCars?: Maybe<Array<Maybe<TCar>>>;
    /** Гражданство */
    citizenship?: Maybe<ECitizenship>;
    creditRating?: Maybe<TNestedPeopleCreditRating>;
    creditSelection?: Maybe<TNestedPeopleCreditSelection>;
    /** Залоговое авто в кредитах */
    creditSelectionPledgeCar?: Maybe<TCar>;
    /** Адрес залога в кредитах */
    creditSelectionPledgeHabitation?: Maybe<THabitation>;
    /** Количество иждивенцев */
    dependentsCount?: Maybe<EDependents>;
    /** Водители */
    driveCars?: Maybe<Array<Maybe<TCar>>>;
    driverLicence?: Maybe<TNestedPeopleDriverLicence>;
    /** Уровень образования */
    education?: Maybe<EEducation>;
    /** Email. Адрес электронной почты */
    email?: Maybe<Scalars['EmailAddress']['output']>;
    /**
     * Email подтвержден?
     * @deprecated Подтвержденный email только в объекте Profile
     */
    emailConfirmed?: Maybe<Scalars['Boolean']['output']>;
    /** Тип занятости */
    employmentType?: Maybe<EEmploymentType>;
    /** Количество детей */
    exactDependentsNumber?: Maybe<Scalars['String']['output']>;
    /** Имя */
    firstName: Scalars['StrictString']['output'];
    /**
     * Имя
     * @deprecated This field is deprecated. Never use it
     */
    firstName2?: Maybe<Scalars['String']['output']>;
    /** Стоимость аренды квартиры, руб. */
    flatRentAmount?: Maybe<Scalars['String']['output']>;
    /** Пол */
    gender?: Maybe<EGender>;
    /** Уникальный хеш клиента */
    hash?: Maybe<Scalars['String']['output']>;
    /** Флаг наличия квартиры */
    haveApartment?: Maybe<EPropertyType>;
    /**
     * Флаг наличия автомобиля
     * @deprecated Не используйте для определения наличия машины
     */
    haveCar?: Maybe<EHaveCar>;
    /** Идентификационный номер налогоплательщика (ИНН) */
    inn?: Maybe<Scalars['INN']['output']>;
    /** Адрес страхования недвижимости */
    insuredHabitations?: Maybe<Array<Maybe<THabitation>>>;
    internationalPassport?: Maybe<TNestedPeopleInternationalPassport>;
    job?: Maybe<TNestedPeopleJob>;
    /** Фамилия */
    lastName: Scalars['StrictString']['output'];
    /** Адрес проживания */
    livingHabitation?: Maybe<THabitation>;
    /** Суммарный платеж по действующим кредитам, руб. */
    loanPaymentTotal?: Maybe<Scalars['String']['output']>;
    /** Семейное положение */
    maritalStatus?: Maybe<EFamilyStatus>;
    mfo?: Maybe<TNestedPeopleMfo>;
    /** Отчество */
    middleName?: Maybe<Scalars['StrictString']['output']>;
    /** Ежемесячный доход, руб. */
    monthlyIncome?: Maybe<Scalars['String']['output']>;
    /** Адрес ипотеки */
    mortgageHabitations?: Maybe<THabitation>;
    mortgageInsurance?: Maybe<TNestedPeopleMortgageInsurance>;
    /** Список организаций пользователя (как владельца) */
    organizations?: Maybe<Array<Maybe<TOrganization>>>;
    osago?: Maybe<TNestedPeopleOsago>;
    /** Страхователь авто в ОСАГО */
    osagoInsuredCars?: Maybe<Array<Maybe<TCar>>>;
    /** Водители в расчете ОСАГО */
    osagoSelectedCars?: Maybe<Array<Maybe<TCar>>>;
    /** Владелец авто */
    ownCars?: Maybe<Array<Maybe<TCar>>>;
    /** Владельцы недвижимости */
    ownHabitations?: Maybe<Array<Maybe<THabitation>>>;
    /** Принадлежность к владельцу профиля */
    ownerRelation?: Maybe<ERelationStatus>;
    passport?: Maybe<TNestedPeoplePassport>;
    /** Телефон */
    phone?: Maybe<Scalars['PhoneNumber']['output']>;
    /**
     * Телефон подтвержден?
     * @deprecated Подтвержденный phone только в объекте Profile
     */
    phoneConfirmed?: Maybe<Scalars['Boolean']['output']>;
    physiology?: Maybe<TNestedPeoplePhysiology>;
    /** Залоговое авто в кредитах */
    pledgeCar?: Maybe<Array<Maybe<TCar>>>;
    /** Адрес залога в кредитах */
    pledgeHabitations?: Maybe<Array<Maybe<THabitation>>>;
    /** Бывшее имя */
    previousFirstName?: Maybe<Scalars['String']['output']>;
    /** Бывшая фамилия */
    previousLastName?: Maybe<Scalars['String']['output']>;
    /** Бывшее отчество */
    previousMiddleName?: Maybe<Scalars['String']['output']>;
    /** Владелец профиля */
    profile?: Maybe<TProfile>;
    /** credit people */
    profileFromCreditPeople?: Maybe<TProfile>;
    /** mfo people */
    profileFromCreditRatingPeople?: Maybe<TProfile>;
    /** mfo people */
    profileFromMfoPeople?: Maybe<TProfile>;
    /** mpl people */
    profileFromMplPeople?: Maybe<TProfile>;
    /** OSAGO People */
    profileFromOsagoPeople?: Maybe<TProfile>;
    /** Red people */
    redPeople?: Maybe<TProfile>;
    /** Адрес регистрации! */
    registrationHabitation?: Maybe<THabitation>;
    /** Расходы на аренду и ЖКХ в месяц, руб. */
    rentCost?: Maybe<Scalars['String']['output']>;
    /** Заработная плата, руб. */
    salary?: Maybe<Scalars['String']['output']>;
    /** Банк, в котором ведется зарплатный проект */
    salaryBank?: Maybe<Scalars['String']['output']>;
    /** Страховой номер индивидуального лицевого счёта (СНИЛС) */
    snils?: Maybe<Scalars['SNILS']['output']>;
    /** Документ, подтверждающий платежеспособность */
    solvencyProof?: Maybe<EIncomeConfirmation>;
    /** Тестовая ссылка */
    testo2o?: Maybe<TCar>;
    /** Причина отсутствия занятости */
    unemploymentReason?: Maybe<EUnemploymentReason>;
    /** Тестовое описание причины отсутствия занятости */
    unemploymentReasonText?: Maybe<Scalars['String']['output']>;
    updated?: Maybe<Scalars['DateTimeISO']['output']>;
    /** Общий рабочий стаж */
    workExperience?: Maybe<EWorkExperience>;
};

export type TProductMeta = {
    __typename?: 'ProductMeta';
    meta?: Maybe<Scalars['String']['output']>;
};

export type TProfile = {
    __typename?: 'Profile';
    /** Водительское удостоверение (ЕСИА) */
    ESIADriverLicense?: Maybe<TEsiaDriverLicense>;
    /** Электронная книжка ЕСИА */
    ESIAEWorkbook?: Maybe<TEsiaeWorkbook>;
    /** Флаги клиента ЕСИА */
    ESIAFlags?: Maybe<TEsiaClientFlags>;
    /** Владелец профиля ЕСИА */
    ESIAOwner?: Maybe<TEsiaPeople>;
    /** Паспорт гражданина РФ (ЕСИА) */
    ESIAPassport?: Maybe<TEsiaPassport>;
    /** В ручную подтвержденный паспорт ЕСИА */
    ESIAPassportApproved?: Maybe<TEsiaPassport>;
    ESIAPassportHistory?: Maybe<Array<Maybe<TEsiaPassport>>>;
    /** Транспортное средство (ESIA) */
    ESIAVehicle?: Maybe<Array<Maybe<TEsiaVehicle>>>;
    /** Справка 2-НДФЛ */
    NDFL2?: Maybe<Array<Maybe<TNdfl2>>>;
    /** Я принимаю оферту сервиса и условия обработки персональных данных */
    acceptOffer?: Maybe<Scalars['Boolean']['output']>;
    /** Дата и время согласия с офертой и условиями обработки персональных данных */
    acceptOfferTime?: Maybe<Scalars['String']['output']>;
    /** Вручную подтвержденный паспорт в CRM */
    approvedPassport?: Maybe<TPassport>;
    /** Аватар профиля */
    avatar?: Maybe<Scalars['String']['output']>;
    /** credit people */
    creditPeople?: Maybe<TPeople>;
    /** mfo people */
    creditRatingPeople?: Maybe<TPeople>;
    /** Email клиента */
    email?: Maybe<Scalars['EmailAddress']['output']>;
    emailConfirmed?: Maybe<Scalars['Boolean']['output']>;
    /** Имя identity */
    firstName?: Maybe<Scalars['String']['output']>;
    /** Фамилия identity */
    lastName?: Maybe<Scalars['String']['output']>;
    /** Введенный вручную вне какого-либо продукта автомобиль */
    manualInputCars?: Maybe<Array<Maybe<TCar>>>;
    /** Руками введенный адрес после регистрации ЕСИА */
    manualInputESIARegistrationHabitation?: Maybe<THabitation>;
    /** Введенная вручную вне какого-либо продукта недвижимость */
    manualInputHabitations?: Maybe<Array<Maybe<THabitation>>>;
    /** mfo people */
    mfoPeople?: Maybe<TPeople>;
    /** mpl people */
    mplPeople?: Maybe<TPeople>;
    /** OSAGO People */
    osagoPeople?: Maybe<TPeople>;
    /** Владелец профиля */
    owner?: Maybe<TPeople>;
    phone?: Maybe<Scalars['PhoneNumber']['output']>;
    phoneConfirmed?: Maybe<Scalars['Boolean']['output']>;
    /** Red people */
    redPeople?: Maybe<TPeople>;
    /** Источник установки приложения */
    sourceInstallApp?: Maybe<Scalars['String']['output']>;
};

/**
 * got_mort_without_ins: Данные из кредитного рейтинга: Есть ипотека и подходит дата и у нас не страхуется;
 * only_mfo: МФО FD (не интересуются другими продуктами вообще);
 * low_income_mfo: Интересовался кредитами и низкий доход, показывать только MFO FD;
 * low_revenue_high_load: Данные из кредитного рейтинга: Небольшой доход и большая нагрузка;
 * car_want_only_credit: Клиент, отправлявший заявки в подборе на кредиты, есть авто (в анкете), нет других купленных продуктов;
 * osago_bought_polis: Клиент ОСАГО, купивший полис у нас;
 *
 */
export type TProfileChars = {
    __typename?: 'ProfileChars';
    /** Характеристика профиля */
    char?: Maybe<EProfileChars>;
};

export type TQuery = {
    __typename?: 'Query';
    ESIAHabitations?: Maybe<Array<Maybe<TEsiaHabitation>>>;
    ESIAPeople?: Maybe<Array<Maybe<TEsiaPeople>>>;
    cars?: Maybe<Array<Maybe<TCar>>>;
    habitations?: Maybe<Array<Maybe<THabitation>>>;
    people?: Maybe<Array<Maybe<TPeople>>>;
    productMeta?: Maybe<TProductMeta>;
    profile?: Maybe<TProfile>;
    profileChar?: Maybe<TProfileChars>;
    profileState?: Maybe<TProfile>;
};

export type TQueryEsiaHabitationsArgs = {
    hash?: InputMaybe<Scalars['String']['input']>;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TQueryEsiaPeopleArgs = {
    hash?: InputMaybe<Scalars['String']['input']>;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TQueryCarsArgs = {
    hash?: InputMaybe<Scalars['String']['input']>;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TQueryHabitationsArgs = {
    hash?: InputMaybe<Scalars['String']['input']>;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TQueryPeopleArgs = {
    hash?: InputMaybe<Scalars['String']['input']>;
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TQueryProductMetaArgs = {
    key: Scalars['String']['input'];
    ownerId: Scalars['Int']['input'];
};

export type TQueryProfileArgs = {
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TQueryProfileCharArgs = {
    ownerId: Scalars['Int']['input'];
};

export type TQueryProfileStateArgs = {
    ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export type TJobAddressFieldsFragment = {
    __typename?: 'NestedPeopleJobAddress';
    dadata?: string | null;
    street?: string | null;
    locationRoute?: string | null;
    house?: string | null;
    flat?: string | null;
    region?: string | null;
    block?: string | null;
    blockType?: string | null;
};

export type TAddressFieldsFragment = {
    __typename?: 'NestedHabitationAddress';
    area?: string | null;
    areaType?: string | null;
    block?: string | null;
    blockType?: string | null;
    city?: string | null;
    cityType?: string | null;
    dadata?: string | null;
    fiasId: string;
    fiasLevel?: string | null;
    flat?: string | null;
    flatType?: string | null;
    house?: string | null;
    houseType?: string | null;
    kladrId?: string | null;
    locationRoute?: string | null;
    postalCode?: string | null;
    region?: string | null;
    regionType?: string | null;
    settlement?: string | null;
    settlementType?: string | null;
    street?: string | null;
    streetType?: string | null;
};

export type TEsiaAddressFieldsFragment = {
    __typename?: 'NestedESIAHabitationAddress';
    area?: string | null;
    areaType?: string | null;
    block?: string | null;
    blockType?: string | null;
    city?: string | null;
    cityType?: string | null;
    dadata?: string | null;
    fiasId: string;
    fiasLevel?: string | null;
    flat?: string | null;
    flatType?: string | null;
    house?: string | null;
    houseType?: string | null;
    kladrId?: string | null;
    postalCode?: string | null;
    region?: string | null;
    regionType?: string | null;
    settlement?: string | null;
    settlementType?: string | null;
    street?: string | null;
    streetType?: string | null;
};

export type TPeopleFieldsFragment = {
    __typename?: 'People';
    firstName: any;
    lastName: any;
    middleName?: any | null;
    birthDate: any;
    updated?: any | null;
    hash?: string | null;
    additionalIncome?: string | null;
    additionalPhone?: any | null;
    additionalPhoneOwner?: EAdditionalPhoneOwner | null;
    additionalPhoneOwnerFIO?: string | null;
    birthPlace?: string | null;
    dependentsCount?: EDependents | null;
    education?: EEducation | null;
    email?: any | null;
    employmentType?: EEmploymentType | null;
    flatRentAmount?: string | null;
    gender?: EGender | null;
    loanPaymentTotal?: string | null;
    maritalStatus?: EFamilyStatus | null;
    monthlyIncome?: string | null;
    rentCost?: string | null;
    salary?: string | null;
    snils?: any | null;
    solvencyProof?: EIncomeConfirmation | null;
    unemploymentReason?: EUnemploymentReason | null;
    unemploymentReasonText?: string | null;
    workExperience?: EWorkExperience | null;
    haveCar?: EHaveCar | null;
    haveApartment?: EPropertyType | null;
    creditSelection?: {
        __typename?: 'NestedPeopleCreditSelection';
        amount?: string | null;
        autoBrand?: string | null;
        autoModel?: string | null;
        autoYear?: any | null;
        creditSecurity?: ECreditSecurity | null;
        initialFee?: string | null;
        obtainCityId?: string | null;
        obtainCityLocationRoute?: string | null;
        period?: string | null;
        propertyAutoRegistrationPlate?: any | null;
        purpose?: ECreditPurpose | null;
        region?: string | null;
    } | null;
    creditSelectionPledgeCar?: {
        __typename?: 'Car';
        registrationPlate?: any | null;
        modelId?: string | null;
        model: string;
        markId?: string | null;
        mark: string;
        power?: string | null;
        year: any;
        hash?: string | null;
        vin?: string | null;
    } | null;
    creditSelectionPledgeHabitation?: {
        __typename?: 'Habitation';
        updated?: any | null;
        hash?: string | null;
        address: {
            __typename?: 'NestedHabitationAddress';
            dadata?: string | null;
            house?: string | null;
            locationRoute?: string | null;
            street?: string | null;
            flat?: string | null;
            block?: string | null;
            region?: string | null;
            city?: string | null;
            regionType?: string | null;
            area?: string | null;
            areaType?: string | null;
            cityType?: string | null;
            settlementType?: string | null;
            settlement?: string | null;
            streetType?: string | null;
        };
        creditSelection?: {
            __typename?: 'NestedHabitationCreditSelection';
            pledgeOwnerFamilyStatus?: EFamilyStatus | null;
            pledgeOwnerFirstName?: any | null;
            pledgeOwnerLastName?: string | null;
            pledgeOwnerMiddleName?: string | null;
            pledgeOwnerPhone?: any | null;
            pledgeOwnerRelationship?: ERelationStatus | null;
            pledgeOwnerSingle?: string | null;
        } | null;
    } | null;
    job?: {
        __typename?: 'NestedPeopleJob';
        lastExperienceStartDate?: any | null;
        title?: string | null;
        type?: EJobPosition | null;
        address?: {
            __typename?: 'NestedPeopleJobAddress';
            dadata?: string | null;
            street?: string | null;
            locationRoute?: string | null;
            house?: string | null;
            flat?: string | null;
            region?: string | null;
            block?: string | null;
            blockType?: string | null;
        } | null;
        organization?: {
            __typename?: 'NestedPeopleJobOrganization';
            activity?: EOrganizationActivity | null;
            inn?: any | null;
            name?: string | null;
            ogrn?: any | null;
            phone?: any | null;
            type?: EEmployerType | null;
            typeCode?: string | null;
        } | null;
    } | null;
    livingHabitation?: {
        __typename?: 'Habitation';
        hash?: string | null;
        address: {
            __typename?: 'NestedHabitationAddress';
            area?: string | null;
            areaType?: string | null;
            block?: string | null;
            blockType?: string | null;
            city?: string | null;
            cityType?: string | null;
            dadata?: string | null;
            fiasId: string;
            fiasLevel?: string | null;
            flat?: string | null;
            flatType?: string | null;
            house?: string | null;
            houseType?: string | null;
            kladrId?: string | null;
            locationRoute?: string | null;
            postalCode?: string | null;
            region?: string | null;
            regionType?: string | null;
            settlement?: string | null;
            settlementType?: string | null;
            street?: string | null;
            streetType?: string | null;
        };
    } | null;
    registrationHabitation?: {
        __typename?: 'Habitation';
        phone?: any | null;
        hash?: string | null;
        address: {
            __typename?: 'NestedHabitationAddress';
            area?: string | null;
            areaType?: string | null;
            block?: string | null;
            blockType?: string | null;
            city?: string | null;
            cityType?: string | null;
            dadata?: string | null;
            fiasId: string;
            fiasLevel?: string | null;
            flat?: string | null;
            flatType?: string | null;
            house?: string | null;
            houseType?: string | null;
            kladrId?: string | null;
            locationRoute?: string | null;
            postalCode?: string | null;
            region?: string | null;
            regionType?: string | null;
            settlement?: string | null;
            settlementType?: string | null;
            street?: string | null;
            streetType?: string | null;
        };
    } | null;
    passport?: {
        __typename?: 'NestedPeoplePassport';
        addressRegistrationDate?: any | null;
        issueDate?: any | null;
        issuedBy?: string | null;
        issuedByCode?: any | null;
        number?: any | null;
    } | null;
};

export type TEsiaPeopleFieldsFragment = {
    __typename?: 'ESIAPeople';
    updated?: any | null;
    hash?: string | null;
    firstName: any;
    lastName: any;
    middleName?: any | null;
    gender?: EGender | null;
    email?: any | null;
    birthDate: any;
    birthPlace?: string | null;
    snils: any;
    registrationHabitation?: {
        __typename?: 'ESIAHabitation';
        hash?: string | null;
        address: {
            __typename?: 'NestedESIAHabitationAddress';
            area?: string | null;
            areaType?: string | null;
            block?: string | null;
            blockType?: string | null;
            city?: string | null;
            cityType?: string | null;
            dadata?: string | null;
            fiasId: string;
            fiasLevel?: string | null;
            flat?: string | null;
            flatType?: string | null;
            house?: string | null;
            houseType?: string | null;
            kladrId?: string | null;
            postalCode?: string | null;
            region?: string | null;
            regionType?: string | null;
            settlement?: string | null;
            settlementType?: string | null;
            street?: string | null;
            streetType?: string | null;
        };
    } | null;
    livingHabitation?: {
        __typename?: 'ESIAHabitation';
        hash?: string | null;
        address: {
            __typename?: 'NestedESIAHabitationAddress';
            area?: string | null;
            areaType?: string | null;
            block?: string | null;
            blockType?: string | null;
            city?: string | null;
            cityType?: string | null;
            dadata?: string | null;
            fiasId: string;
            fiasLevel?: string | null;
            flat?: string | null;
            flatType?: string | null;
            house?: string | null;
            houseType?: string | null;
            kladrId?: string | null;
            postalCode?: string | null;
            region?: string | null;
            regionType?: string | null;
            settlement?: string | null;
            settlementType?: string | null;
            street?: string | null;
            streetType?: string | null;
        };
    } | null;
};

export type TEsiaPassportFieldsFragment = {
    __typename?: 'ESIAPassport';
    updated?: any | null;
    hash?: string | null;
    series?: string | null;
    number?: string | null;
    issuedBy?: string | null;
    issueDate?: any | null;
    issueId?: any | null;
};

export type TCreditSelectionFrontendLoadDataQueryVariables = Exact<{
    ownerId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TCreditSelectionFrontendLoadDataQuery = {
    __typename?: 'Query';
    profile?: {
        __typename?: 'Profile';
        phone?: any | null;
        ESIAPassport?: {
            __typename?: 'ESIAPassport';
            updated?: any | null;
            hash?: string | null;
            series?: string | null;
            number?: string | null;
            issuedBy?: string | null;
            issueDate?: any | null;
            issueId?: any | null;
        } | null;
        ESIAOwner?: {
            __typename?: 'ESIAPeople';
            updated?: any | null;
            hash?: string | null;
            firstName: any;
            lastName: any;
            middleName?: any | null;
            gender?: EGender | null;
            email?: any | null;
            birthDate: any;
            birthPlace?: string | null;
            snils: any;
            registrationHabitation?: {
                __typename?: 'ESIAHabitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedESIAHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            livingHabitation?: {
                __typename?: 'ESIAHabitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedESIAHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
        } | null;
        owner?: {
            __typename?: 'People';
            firstName: any;
            lastName: any;
            middleName?: any | null;
            birthDate: any;
            updated?: any | null;
            hash?: string | null;
            additionalIncome?: string | null;
            additionalPhone?: any | null;
            additionalPhoneOwner?: EAdditionalPhoneOwner | null;
            additionalPhoneOwnerFIO?: string | null;
            birthPlace?: string | null;
            dependentsCount?: EDependents | null;
            education?: EEducation | null;
            email?: any | null;
            employmentType?: EEmploymentType | null;
            flatRentAmount?: string | null;
            gender?: EGender | null;
            loanPaymentTotal?: string | null;
            maritalStatus?: EFamilyStatus | null;
            monthlyIncome?: string | null;
            rentCost?: string | null;
            salary?: string | null;
            snils?: any | null;
            solvencyProof?: EIncomeConfirmation | null;
            unemploymentReason?: EUnemploymentReason | null;
            unemploymentReasonText?: string | null;
            workExperience?: EWorkExperience | null;
            haveCar?: EHaveCar | null;
            haveApartment?: EPropertyType | null;
            creditSelection?: {
                __typename?: 'NestedPeopleCreditSelection';
                amount?: string | null;
                autoBrand?: string | null;
                autoModel?: string | null;
                autoYear?: any | null;
                creditSecurity?: ECreditSecurity | null;
                initialFee?: string | null;
                obtainCityId?: string | null;
                obtainCityLocationRoute?: string | null;
                period?: string | null;
                propertyAutoRegistrationPlate?: any | null;
                purpose?: ECreditPurpose | null;
                region?: string | null;
            } | null;
            creditSelectionPledgeCar?: {
                __typename?: 'Car';
                registrationPlate?: any | null;
                modelId?: string | null;
                model: string;
                markId?: string | null;
                mark: string;
                power?: string | null;
                year: any;
                hash?: string | null;
                vin?: string | null;
            } | null;
            creditSelectionPledgeHabitation?: {
                __typename?: 'Habitation';
                updated?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    dadata?: string | null;
                    house?: string | null;
                    locationRoute?: string | null;
                    street?: string | null;
                    flat?: string | null;
                    block?: string | null;
                    region?: string | null;
                    city?: string | null;
                    regionType?: string | null;
                    area?: string | null;
                    areaType?: string | null;
                    cityType?: string | null;
                    settlementType?: string | null;
                    settlement?: string | null;
                    streetType?: string | null;
                };
                creditSelection?: {
                    __typename?: 'NestedHabitationCreditSelection';
                    pledgeOwnerFamilyStatus?: EFamilyStatus | null;
                    pledgeOwnerFirstName?: any | null;
                    pledgeOwnerLastName?: string | null;
                    pledgeOwnerMiddleName?: string | null;
                    pledgeOwnerPhone?: any | null;
                    pledgeOwnerRelationship?: ERelationStatus | null;
                    pledgeOwnerSingle?: string | null;
                } | null;
            } | null;
            job?: {
                __typename?: 'NestedPeopleJob';
                lastExperienceStartDate?: any | null;
                title?: string | null;
                type?: EJobPosition | null;
                address?: {
                    __typename?: 'NestedPeopleJobAddress';
                    dadata?: string | null;
                    street?: string | null;
                    locationRoute?: string | null;
                    house?: string | null;
                    flat?: string | null;
                    region?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                } | null;
                organization?: {
                    __typename?: 'NestedPeopleJobOrganization';
                    activity?: EOrganizationActivity | null;
                    inn?: any | null;
                    name?: string | null;
                    ogrn?: any | null;
                    phone?: any | null;
                    type?: EEmployerType | null;
                    typeCode?: string | null;
                } | null;
            } | null;
            livingHabitation?: {
                __typename?: 'Habitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            registrationHabitation?: {
                __typename?: 'Habitation';
                phone?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            passport?: {
                __typename?: 'NestedPeoplePassport';
                addressRegistrationDate?: any | null;
                issueDate?: any | null;
                issuedBy?: string | null;
                issuedByCode?: any | null;
                number?: any | null;
            } | null;
        } | null;
        creditPeople?: {
            __typename?: 'People';
            firstName: any;
            lastName: any;
            middleName?: any | null;
            birthDate: any;
            updated?: any | null;
            hash?: string | null;
            additionalIncome?: string | null;
            additionalPhone?: any | null;
            additionalPhoneOwner?: EAdditionalPhoneOwner | null;
            additionalPhoneOwnerFIO?: string | null;
            birthPlace?: string | null;
            dependentsCount?: EDependents | null;
            education?: EEducation | null;
            email?: any | null;
            employmentType?: EEmploymentType | null;
            flatRentAmount?: string | null;
            gender?: EGender | null;
            loanPaymentTotal?: string | null;
            maritalStatus?: EFamilyStatus | null;
            monthlyIncome?: string | null;
            rentCost?: string | null;
            salary?: string | null;
            snils?: any | null;
            solvencyProof?: EIncomeConfirmation | null;
            unemploymentReason?: EUnemploymentReason | null;
            unemploymentReasonText?: string | null;
            workExperience?: EWorkExperience | null;
            haveCar?: EHaveCar | null;
            haveApartment?: EPropertyType | null;
            creditSelection?: {
                __typename?: 'NestedPeopleCreditSelection';
                amount?: string | null;
                autoBrand?: string | null;
                autoModel?: string | null;
                autoYear?: any | null;
                creditSecurity?: ECreditSecurity | null;
                initialFee?: string | null;
                obtainCityId?: string | null;
                obtainCityLocationRoute?: string | null;
                period?: string | null;
                propertyAutoRegistrationPlate?: any | null;
                purpose?: ECreditPurpose | null;
                region?: string | null;
            } | null;
            creditSelectionPledgeCar?: {
                __typename?: 'Car';
                registrationPlate?: any | null;
                modelId?: string | null;
                model: string;
                markId?: string | null;
                mark: string;
                power?: string | null;
                year: any;
                hash?: string | null;
                vin?: string | null;
            } | null;
            creditSelectionPledgeHabitation?: {
                __typename?: 'Habitation';
                updated?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    dadata?: string | null;
                    house?: string | null;
                    locationRoute?: string | null;
                    street?: string | null;
                    flat?: string | null;
                    block?: string | null;
                    region?: string | null;
                    city?: string | null;
                    regionType?: string | null;
                    area?: string | null;
                    areaType?: string | null;
                    cityType?: string | null;
                    settlementType?: string | null;
                    settlement?: string | null;
                    streetType?: string | null;
                };
                creditSelection?: {
                    __typename?: 'NestedHabitationCreditSelection';
                    pledgeOwnerFamilyStatus?: EFamilyStatus | null;
                    pledgeOwnerFirstName?: any | null;
                    pledgeOwnerLastName?: string | null;
                    pledgeOwnerMiddleName?: string | null;
                    pledgeOwnerPhone?: any | null;
                    pledgeOwnerRelationship?: ERelationStatus | null;
                    pledgeOwnerSingle?: string | null;
                } | null;
            } | null;
            job?: {
                __typename?: 'NestedPeopleJob';
                lastExperienceStartDate?: any | null;
                title?: string | null;
                type?: EJobPosition | null;
                address?: {
                    __typename?: 'NestedPeopleJobAddress';
                    dadata?: string | null;
                    street?: string | null;
                    locationRoute?: string | null;
                    house?: string | null;
                    flat?: string | null;
                    region?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                } | null;
                organization?: {
                    __typename?: 'NestedPeopleJobOrganization';
                    activity?: EOrganizationActivity | null;
                    inn?: any | null;
                    name?: string | null;
                    ogrn?: any | null;
                    phone?: any | null;
                    type?: EEmployerType | null;
                    typeCode?: string | null;
                } | null;
            } | null;
            livingHabitation?: {
                __typename?: 'Habitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            registrationHabitation?: {
                __typename?: 'Habitation';
                phone?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            passport?: {
                __typename?: 'NestedPeoplePassport';
                addressRegistrationDate?: any | null;
                issueDate?: any | null;
                issuedBy?: string | null;
                issuedByCode?: any | null;
                number?: any | null;
            } | null;
        } | null;
        mfoPeople?: {
            __typename?: 'People';
            firstName: any;
            lastName: any;
            middleName?: any | null;
            birthDate: any;
            updated?: any | null;
            hash?: string | null;
            additionalIncome?: string | null;
            additionalPhone?: any | null;
            additionalPhoneOwner?: EAdditionalPhoneOwner | null;
            additionalPhoneOwnerFIO?: string | null;
            birthPlace?: string | null;
            dependentsCount?: EDependents | null;
            education?: EEducation | null;
            email?: any | null;
            employmentType?: EEmploymentType | null;
            flatRentAmount?: string | null;
            gender?: EGender | null;
            loanPaymentTotal?: string | null;
            maritalStatus?: EFamilyStatus | null;
            monthlyIncome?: string | null;
            rentCost?: string | null;
            salary?: string | null;
            snils?: any | null;
            solvencyProof?: EIncomeConfirmation | null;
            unemploymentReason?: EUnemploymentReason | null;
            unemploymentReasonText?: string | null;
            workExperience?: EWorkExperience | null;
            haveCar?: EHaveCar | null;
            haveApartment?: EPropertyType | null;
            creditSelection?: {
                __typename?: 'NestedPeopleCreditSelection';
                amount?: string | null;
                autoBrand?: string | null;
                autoModel?: string | null;
                autoYear?: any | null;
                creditSecurity?: ECreditSecurity | null;
                initialFee?: string | null;
                obtainCityId?: string | null;
                obtainCityLocationRoute?: string | null;
                period?: string | null;
                propertyAutoRegistrationPlate?: any | null;
                purpose?: ECreditPurpose | null;
                region?: string | null;
            } | null;
            creditSelectionPledgeCar?: {
                __typename?: 'Car';
                registrationPlate?: any | null;
                modelId?: string | null;
                model: string;
                markId?: string | null;
                mark: string;
                power?: string | null;
                year: any;
                hash?: string | null;
                vin?: string | null;
            } | null;
            creditSelectionPledgeHabitation?: {
                __typename?: 'Habitation';
                updated?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    dadata?: string | null;
                    house?: string | null;
                    locationRoute?: string | null;
                    street?: string | null;
                    flat?: string | null;
                    block?: string | null;
                    region?: string | null;
                    city?: string | null;
                    regionType?: string | null;
                    area?: string | null;
                    areaType?: string | null;
                    cityType?: string | null;
                    settlementType?: string | null;
                    settlement?: string | null;
                    streetType?: string | null;
                };
                creditSelection?: {
                    __typename?: 'NestedHabitationCreditSelection';
                    pledgeOwnerFamilyStatus?: EFamilyStatus | null;
                    pledgeOwnerFirstName?: any | null;
                    pledgeOwnerLastName?: string | null;
                    pledgeOwnerMiddleName?: string | null;
                    pledgeOwnerPhone?: any | null;
                    pledgeOwnerRelationship?: ERelationStatus | null;
                    pledgeOwnerSingle?: string | null;
                } | null;
            } | null;
            job?: {
                __typename?: 'NestedPeopleJob';
                lastExperienceStartDate?: any | null;
                title?: string | null;
                type?: EJobPosition | null;
                address?: {
                    __typename?: 'NestedPeopleJobAddress';
                    dadata?: string | null;
                    street?: string | null;
                    locationRoute?: string | null;
                    house?: string | null;
                    flat?: string | null;
                    region?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                } | null;
                organization?: {
                    __typename?: 'NestedPeopleJobOrganization';
                    activity?: EOrganizationActivity | null;
                    inn?: any | null;
                    name?: string | null;
                    ogrn?: any | null;
                    phone?: any | null;
                    type?: EEmployerType | null;
                    typeCode?: string | null;
                } | null;
            } | null;
            livingHabitation?: {
                __typename?: 'Habitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            registrationHabitation?: {
                __typename?: 'Habitation';
                phone?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            passport?: {
                __typename?: 'NestedPeoplePassport';
                addressRegistrationDate?: any | null;
                issueDate?: any | null;
                issuedBy?: string | null;
                issuedByCode?: any | null;
                number?: any | null;
            } | null;
        } | null;
    } | null;
};

export type TCreditSelectionFrontendCallCenterLoadDataQueryVariables = Exact<{
    ownerId: Scalars['Int']['input'];
}>;

export type TCreditSelectionFrontendCallCenterLoadDataQuery = {
    __typename?: 'Query';
    profile?: {
        __typename?: 'Profile';
        phone?: any | null;
        ESIAPassport?: {
            __typename?: 'ESIAPassport';
            updated?: any | null;
            hash?: string | null;
            series?: string | null;
            number?: string | null;
            issuedBy?: string | null;
            issueDate?: any | null;
            issueId?: any | null;
        } | null;
        ESIAOwner?: {
            __typename?: 'ESIAPeople';
            updated?: any | null;
            hash?: string | null;
            firstName: any;
            lastName: any;
            middleName?: any | null;
            gender?: EGender | null;
            email?: any | null;
            birthDate: any;
            birthPlace?: string | null;
            snils: any;
            registrationHabitation?: {
                __typename?: 'ESIAHabitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedESIAHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            livingHabitation?: {
                __typename?: 'ESIAHabitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedESIAHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
        } | null;
        owner?: {
            __typename?: 'People';
            firstName: any;
            lastName: any;
            middleName?: any | null;
            birthDate: any;
            updated?: any | null;
            hash?: string | null;
            additionalIncome?: string | null;
            additionalPhone?: any | null;
            additionalPhoneOwner?: EAdditionalPhoneOwner | null;
            additionalPhoneOwnerFIO?: string | null;
            birthPlace?: string | null;
            dependentsCount?: EDependents | null;
            education?: EEducation | null;
            email?: any | null;
            employmentType?: EEmploymentType | null;
            flatRentAmount?: string | null;
            gender?: EGender | null;
            loanPaymentTotal?: string | null;
            maritalStatus?: EFamilyStatus | null;
            monthlyIncome?: string | null;
            rentCost?: string | null;
            salary?: string | null;
            snils?: any | null;
            solvencyProof?: EIncomeConfirmation | null;
            unemploymentReason?: EUnemploymentReason | null;
            unemploymentReasonText?: string | null;
            workExperience?: EWorkExperience | null;
            haveCar?: EHaveCar | null;
            haveApartment?: EPropertyType | null;
            creditSelection?: {
                __typename?: 'NestedPeopleCreditSelection';
                amount?: string | null;
                autoBrand?: string | null;
                autoModel?: string | null;
                autoYear?: any | null;
                creditSecurity?: ECreditSecurity | null;
                initialFee?: string | null;
                obtainCityId?: string | null;
                obtainCityLocationRoute?: string | null;
                period?: string | null;
                propertyAutoRegistrationPlate?: any | null;
                purpose?: ECreditPurpose | null;
                region?: string | null;
            } | null;
            creditSelectionPledgeCar?: {
                __typename?: 'Car';
                registrationPlate?: any | null;
                modelId?: string | null;
                model: string;
                markId?: string | null;
                mark: string;
                power?: string | null;
                year: any;
                hash?: string | null;
                vin?: string | null;
            } | null;
            creditSelectionPledgeHabitation?: {
                __typename?: 'Habitation';
                updated?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    dadata?: string | null;
                    house?: string | null;
                    locationRoute?: string | null;
                    street?: string | null;
                    flat?: string | null;
                    block?: string | null;
                    region?: string | null;
                    city?: string | null;
                    regionType?: string | null;
                    area?: string | null;
                    areaType?: string | null;
                    cityType?: string | null;
                    settlementType?: string | null;
                    settlement?: string | null;
                    streetType?: string | null;
                };
                creditSelection?: {
                    __typename?: 'NestedHabitationCreditSelection';
                    pledgeOwnerFamilyStatus?: EFamilyStatus | null;
                    pledgeOwnerFirstName?: any | null;
                    pledgeOwnerLastName?: string | null;
                    pledgeOwnerMiddleName?: string | null;
                    pledgeOwnerPhone?: any | null;
                    pledgeOwnerRelationship?: ERelationStatus | null;
                    pledgeOwnerSingle?: string | null;
                } | null;
            } | null;
            job?: {
                __typename?: 'NestedPeopleJob';
                lastExperienceStartDate?: any | null;
                title?: string | null;
                type?: EJobPosition | null;
                address?: {
                    __typename?: 'NestedPeopleJobAddress';
                    dadata?: string | null;
                    street?: string | null;
                    locationRoute?: string | null;
                    house?: string | null;
                    flat?: string | null;
                    region?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                } | null;
                organization?: {
                    __typename?: 'NestedPeopleJobOrganization';
                    activity?: EOrganizationActivity | null;
                    inn?: any | null;
                    name?: string | null;
                    ogrn?: any | null;
                    phone?: any | null;
                    type?: EEmployerType | null;
                    typeCode?: string | null;
                } | null;
            } | null;
            livingHabitation?: {
                __typename?: 'Habitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            registrationHabitation?: {
                __typename?: 'Habitation';
                phone?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            passport?: {
                __typename?: 'NestedPeoplePassport';
                addressRegistrationDate?: any | null;
                issueDate?: any | null;
                issuedBy?: string | null;
                issuedByCode?: any | null;
                number?: any | null;
            } | null;
        } | null;
        creditPeople?: {
            __typename?: 'People';
            firstName: any;
            lastName: any;
            middleName?: any | null;
            birthDate: any;
            updated?: any | null;
            hash?: string | null;
            additionalIncome?: string | null;
            additionalPhone?: any | null;
            additionalPhoneOwner?: EAdditionalPhoneOwner | null;
            additionalPhoneOwnerFIO?: string | null;
            birthPlace?: string | null;
            dependentsCount?: EDependents | null;
            education?: EEducation | null;
            email?: any | null;
            employmentType?: EEmploymentType | null;
            flatRentAmount?: string | null;
            gender?: EGender | null;
            loanPaymentTotal?: string | null;
            maritalStatus?: EFamilyStatus | null;
            monthlyIncome?: string | null;
            rentCost?: string | null;
            salary?: string | null;
            snils?: any | null;
            solvencyProof?: EIncomeConfirmation | null;
            unemploymentReason?: EUnemploymentReason | null;
            unemploymentReasonText?: string | null;
            workExperience?: EWorkExperience | null;
            haveCar?: EHaveCar | null;
            haveApartment?: EPropertyType | null;
            creditSelection?: {
                __typename?: 'NestedPeopleCreditSelection';
                amount?: string | null;
                autoBrand?: string | null;
                autoModel?: string | null;
                autoYear?: any | null;
                creditSecurity?: ECreditSecurity | null;
                initialFee?: string | null;
                obtainCityId?: string | null;
                obtainCityLocationRoute?: string | null;
                period?: string | null;
                propertyAutoRegistrationPlate?: any | null;
                purpose?: ECreditPurpose | null;
                region?: string | null;
            } | null;
            creditSelectionPledgeCar?: {
                __typename?: 'Car';
                registrationPlate?: any | null;
                modelId?: string | null;
                model: string;
                markId?: string | null;
                mark: string;
                power?: string | null;
                year: any;
                hash?: string | null;
                vin?: string | null;
            } | null;
            creditSelectionPledgeHabitation?: {
                __typename?: 'Habitation';
                updated?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    dadata?: string | null;
                    house?: string | null;
                    locationRoute?: string | null;
                    street?: string | null;
                    flat?: string | null;
                    block?: string | null;
                    region?: string | null;
                    city?: string | null;
                    regionType?: string | null;
                    area?: string | null;
                    areaType?: string | null;
                    cityType?: string | null;
                    settlementType?: string | null;
                    settlement?: string | null;
                    streetType?: string | null;
                };
                creditSelection?: {
                    __typename?: 'NestedHabitationCreditSelection';
                    pledgeOwnerFamilyStatus?: EFamilyStatus | null;
                    pledgeOwnerFirstName?: any | null;
                    pledgeOwnerLastName?: string | null;
                    pledgeOwnerMiddleName?: string | null;
                    pledgeOwnerPhone?: any | null;
                    pledgeOwnerRelationship?: ERelationStatus | null;
                    pledgeOwnerSingle?: string | null;
                } | null;
            } | null;
            job?: {
                __typename?: 'NestedPeopleJob';
                lastExperienceStartDate?: any | null;
                title?: string | null;
                type?: EJobPosition | null;
                address?: {
                    __typename?: 'NestedPeopleJobAddress';
                    dadata?: string | null;
                    street?: string | null;
                    locationRoute?: string | null;
                    house?: string | null;
                    flat?: string | null;
                    region?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                } | null;
                organization?: {
                    __typename?: 'NestedPeopleJobOrganization';
                    activity?: EOrganizationActivity | null;
                    inn?: any | null;
                    name?: string | null;
                    ogrn?: any | null;
                    phone?: any | null;
                    type?: EEmployerType | null;
                    typeCode?: string | null;
                } | null;
            } | null;
            livingHabitation?: {
                __typename?: 'Habitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            registrationHabitation?: {
                __typename?: 'Habitation';
                phone?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            passport?: {
                __typename?: 'NestedPeoplePassport';
                addressRegistrationDate?: any | null;
                issueDate?: any | null;
                issuedBy?: string | null;
                issuedByCode?: any | null;
                number?: any | null;
            } | null;
        } | null;
        mfoPeople?: {
            __typename?: 'People';
            firstName: any;
            lastName: any;
            middleName?: any | null;
            birthDate: any;
            updated?: any | null;
            hash?: string | null;
            additionalIncome?: string | null;
            additionalPhone?: any | null;
            additionalPhoneOwner?: EAdditionalPhoneOwner | null;
            additionalPhoneOwnerFIO?: string | null;
            birthPlace?: string | null;
            dependentsCount?: EDependents | null;
            education?: EEducation | null;
            email?: any | null;
            employmentType?: EEmploymentType | null;
            flatRentAmount?: string | null;
            gender?: EGender | null;
            loanPaymentTotal?: string | null;
            maritalStatus?: EFamilyStatus | null;
            monthlyIncome?: string | null;
            rentCost?: string | null;
            salary?: string | null;
            snils?: any | null;
            solvencyProof?: EIncomeConfirmation | null;
            unemploymentReason?: EUnemploymentReason | null;
            unemploymentReasonText?: string | null;
            workExperience?: EWorkExperience | null;
            haveCar?: EHaveCar | null;
            haveApartment?: EPropertyType | null;
            creditSelection?: {
                __typename?: 'NestedPeopleCreditSelection';
                amount?: string | null;
                autoBrand?: string | null;
                autoModel?: string | null;
                autoYear?: any | null;
                creditSecurity?: ECreditSecurity | null;
                initialFee?: string | null;
                obtainCityId?: string | null;
                obtainCityLocationRoute?: string | null;
                period?: string | null;
                propertyAutoRegistrationPlate?: any | null;
                purpose?: ECreditPurpose | null;
                region?: string | null;
            } | null;
            creditSelectionPledgeCar?: {
                __typename?: 'Car';
                registrationPlate?: any | null;
                modelId?: string | null;
                model: string;
                markId?: string | null;
                mark: string;
                power?: string | null;
                year: any;
                hash?: string | null;
                vin?: string | null;
            } | null;
            creditSelectionPledgeHabitation?: {
                __typename?: 'Habitation';
                updated?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    dadata?: string | null;
                    house?: string | null;
                    locationRoute?: string | null;
                    street?: string | null;
                    flat?: string | null;
                    block?: string | null;
                    region?: string | null;
                    city?: string | null;
                    regionType?: string | null;
                    area?: string | null;
                    areaType?: string | null;
                    cityType?: string | null;
                    settlementType?: string | null;
                    settlement?: string | null;
                    streetType?: string | null;
                };
                creditSelection?: {
                    __typename?: 'NestedHabitationCreditSelection';
                    pledgeOwnerFamilyStatus?: EFamilyStatus | null;
                    pledgeOwnerFirstName?: any | null;
                    pledgeOwnerLastName?: string | null;
                    pledgeOwnerMiddleName?: string | null;
                    pledgeOwnerPhone?: any | null;
                    pledgeOwnerRelationship?: ERelationStatus | null;
                    pledgeOwnerSingle?: string | null;
                } | null;
            } | null;
            job?: {
                __typename?: 'NestedPeopleJob';
                lastExperienceStartDate?: any | null;
                title?: string | null;
                type?: EJobPosition | null;
                address?: {
                    __typename?: 'NestedPeopleJobAddress';
                    dadata?: string | null;
                    street?: string | null;
                    locationRoute?: string | null;
                    house?: string | null;
                    flat?: string | null;
                    region?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                } | null;
                organization?: {
                    __typename?: 'NestedPeopleJobOrganization';
                    activity?: EOrganizationActivity | null;
                    inn?: any | null;
                    name?: string | null;
                    ogrn?: any | null;
                    phone?: any | null;
                    type?: EEmployerType | null;
                    typeCode?: string | null;
                } | null;
            } | null;
            livingHabitation?: {
                __typename?: 'Habitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            registrationHabitation?: {
                __typename?: 'Habitation';
                phone?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            passport?: {
                __typename?: 'NestedPeoplePassport';
                addressRegistrationDate?: any | null;
                issueDate?: any | null;
                issuedBy?: string | null;
                issuedByCode?: any | null;
                number?: any | null;
            } | null;
        } | null;
    } | null;
};

export type TCreditSelectionFrontendUpdateDataMutationVariables = Exact<{
    ownerId?: InputMaybe<Scalars['Int']['input']>;
    data: TInputCommon;
}>;

export type TCreditSelectionFrontendUpdateDataMutation = {
    __typename?: 'Mutation';
    saveData?: {
        __typename?: 'Profile';
        creditPeople?: {
            __typename?: 'People';
            firstName: any;
            lastName: any;
            middleName?: any | null;
            birthDate: any;
            updated?: any | null;
            hash?: string | null;
            additionalIncome?: string | null;
            additionalPhone?: any | null;
            additionalPhoneOwner?: EAdditionalPhoneOwner | null;
            additionalPhoneOwnerFIO?: string | null;
            birthPlace?: string | null;
            dependentsCount?: EDependents | null;
            education?: EEducation | null;
            email?: any | null;
            employmentType?: EEmploymentType | null;
            flatRentAmount?: string | null;
            gender?: EGender | null;
            loanPaymentTotal?: string | null;
            maritalStatus?: EFamilyStatus | null;
            monthlyIncome?: string | null;
            rentCost?: string | null;
            salary?: string | null;
            snils?: any | null;
            solvencyProof?: EIncomeConfirmation | null;
            unemploymentReason?: EUnemploymentReason | null;
            unemploymentReasonText?: string | null;
            workExperience?: EWorkExperience | null;
            haveCar?: EHaveCar | null;
            haveApartment?: EPropertyType | null;
            creditSelection?: {
                __typename?: 'NestedPeopleCreditSelection';
                amount?: string | null;
                autoBrand?: string | null;
                autoModel?: string | null;
                autoYear?: any | null;
                creditSecurity?: ECreditSecurity | null;
                initialFee?: string | null;
                obtainCityId?: string | null;
                obtainCityLocationRoute?: string | null;
                period?: string | null;
                propertyAutoRegistrationPlate?: any | null;
                purpose?: ECreditPurpose | null;
                region?: string | null;
            } | null;
            creditSelectionPledgeCar?: {
                __typename?: 'Car';
                registrationPlate?: any | null;
                modelId?: string | null;
                model: string;
                markId?: string | null;
                mark: string;
                power?: string | null;
                year: any;
                hash?: string | null;
                vin?: string | null;
            } | null;
            creditSelectionPledgeHabitation?: {
                __typename?: 'Habitation';
                updated?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    dadata?: string | null;
                    house?: string | null;
                    locationRoute?: string | null;
                    street?: string | null;
                    flat?: string | null;
                    block?: string | null;
                    region?: string | null;
                    city?: string | null;
                    regionType?: string | null;
                    area?: string | null;
                    areaType?: string | null;
                    cityType?: string | null;
                    settlementType?: string | null;
                    settlement?: string | null;
                    streetType?: string | null;
                };
                creditSelection?: {
                    __typename?: 'NestedHabitationCreditSelection';
                    pledgeOwnerFamilyStatus?: EFamilyStatus | null;
                    pledgeOwnerFirstName?: any | null;
                    pledgeOwnerLastName?: string | null;
                    pledgeOwnerMiddleName?: string | null;
                    pledgeOwnerPhone?: any | null;
                    pledgeOwnerRelationship?: ERelationStatus | null;
                    pledgeOwnerSingle?: string | null;
                } | null;
            } | null;
            job?: {
                __typename?: 'NestedPeopleJob';
                lastExperienceStartDate?: any | null;
                title?: string | null;
                type?: EJobPosition | null;
                address?: {
                    __typename?: 'NestedPeopleJobAddress';
                    dadata?: string | null;
                    street?: string | null;
                    locationRoute?: string | null;
                    house?: string | null;
                    flat?: string | null;
                    region?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                } | null;
                organization?: {
                    __typename?: 'NestedPeopleJobOrganization';
                    activity?: EOrganizationActivity | null;
                    inn?: any | null;
                    name?: string | null;
                    ogrn?: any | null;
                    phone?: any | null;
                    type?: EEmployerType | null;
                    typeCode?: string | null;
                } | null;
            } | null;
            livingHabitation?: {
                __typename?: 'Habitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            registrationHabitation?: {
                __typename?: 'Habitation';
                phone?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            passport?: {
                __typename?: 'NestedPeoplePassport';
                addressRegistrationDate?: any | null;
                issueDate?: any | null;
                issuedBy?: string | null;
                issuedByCode?: any | null;
                number?: any | null;
            } | null;
        } | null;
    } | null;
};

export type TCreditSelectionFrontendCallCenterUpdateDataMutationVariables = Exact<{
    ownerId: Scalars['Int']['input'];
    data: TInputCommon;
}>;

export type TCreditSelectionFrontendCallCenterUpdateDataMutation = {
    __typename?: 'Mutation';
    saveData?: {
        __typename?: 'Profile';
        creditPeople?: {
            __typename?: 'People';
            firstName: any;
            lastName: any;
            middleName?: any | null;
            birthDate: any;
            updated?: any | null;
            hash?: string | null;
            additionalIncome?: string | null;
            additionalPhone?: any | null;
            additionalPhoneOwner?: EAdditionalPhoneOwner | null;
            additionalPhoneOwnerFIO?: string | null;
            birthPlace?: string | null;
            dependentsCount?: EDependents | null;
            education?: EEducation | null;
            email?: any | null;
            employmentType?: EEmploymentType | null;
            flatRentAmount?: string | null;
            gender?: EGender | null;
            loanPaymentTotal?: string | null;
            maritalStatus?: EFamilyStatus | null;
            monthlyIncome?: string | null;
            rentCost?: string | null;
            salary?: string | null;
            snils?: any | null;
            solvencyProof?: EIncomeConfirmation | null;
            unemploymentReason?: EUnemploymentReason | null;
            unemploymentReasonText?: string | null;
            workExperience?: EWorkExperience | null;
            haveCar?: EHaveCar | null;
            haveApartment?: EPropertyType | null;
            creditSelection?: {
                __typename?: 'NestedPeopleCreditSelection';
                amount?: string | null;
                autoBrand?: string | null;
                autoModel?: string | null;
                autoYear?: any | null;
                creditSecurity?: ECreditSecurity | null;
                initialFee?: string | null;
                obtainCityId?: string | null;
                obtainCityLocationRoute?: string | null;
                period?: string | null;
                propertyAutoRegistrationPlate?: any | null;
                purpose?: ECreditPurpose | null;
                region?: string | null;
            } | null;
            creditSelectionPledgeCar?: {
                __typename?: 'Car';
                registrationPlate?: any | null;
                modelId?: string | null;
                model: string;
                markId?: string | null;
                mark: string;
                power?: string | null;
                year: any;
                hash?: string | null;
                vin?: string | null;
            } | null;
            creditSelectionPledgeHabitation?: {
                __typename?: 'Habitation';
                updated?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    dadata?: string | null;
                    house?: string | null;
                    locationRoute?: string | null;
                    street?: string | null;
                    flat?: string | null;
                    block?: string | null;
                    region?: string | null;
                    city?: string | null;
                    regionType?: string | null;
                    area?: string | null;
                    areaType?: string | null;
                    cityType?: string | null;
                    settlementType?: string | null;
                    settlement?: string | null;
                    streetType?: string | null;
                };
                creditSelection?: {
                    __typename?: 'NestedHabitationCreditSelection';
                    pledgeOwnerFamilyStatus?: EFamilyStatus | null;
                    pledgeOwnerFirstName?: any | null;
                    pledgeOwnerLastName?: string | null;
                    pledgeOwnerMiddleName?: string | null;
                    pledgeOwnerPhone?: any | null;
                    pledgeOwnerRelationship?: ERelationStatus | null;
                    pledgeOwnerSingle?: string | null;
                } | null;
            } | null;
            job?: {
                __typename?: 'NestedPeopleJob';
                lastExperienceStartDate?: any | null;
                title?: string | null;
                type?: EJobPosition | null;
                address?: {
                    __typename?: 'NestedPeopleJobAddress';
                    dadata?: string | null;
                    street?: string | null;
                    locationRoute?: string | null;
                    house?: string | null;
                    flat?: string | null;
                    region?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                } | null;
                organization?: {
                    __typename?: 'NestedPeopleJobOrganization';
                    activity?: EOrganizationActivity | null;
                    inn?: any | null;
                    name?: string | null;
                    ogrn?: any | null;
                    phone?: any | null;
                    type?: EEmployerType | null;
                    typeCode?: string | null;
                } | null;
            } | null;
            livingHabitation?: {
                __typename?: 'Habitation';
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            registrationHabitation?: {
                __typename?: 'Habitation';
                phone?: any | null;
                hash?: string | null;
                address: {
                    __typename?: 'NestedHabitationAddress';
                    area?: string | null;
                    areaType?: string | null;
                    block?: string | null;
                    blockType?: string | null;
                    city?: string | null;
                    cityType?: string | null;
                    dadata?: string | null;
                    fiasId: string;
                    fiasLevel?: string | null;
                    flat?: string | null;
                    flatType?: string | null;
                    house?: string | null;
                    houseType?: string | null;
                    kladrId?: string | null;
                    locationRoute?: string | null;
                    postalCode?: string | null;
                    region?: string | null;
                    regionType?: string | null;
                    settlement?: string | null;
                    settlementType?: string | null;
                    street?: string | null;
                    streetType?: string | null;
                };
            } | null;
            passport?: {
                __typename?: 'NestedPeoplePassport';
                addressRegistrationDate?: any | null;
                issueDate?: any | null;
                issuedBy?: string | null;
                issuedByCode?: any | null;
                number?: any | null;
            } | null;
        } | null;
    } | null;
};

export const JobAddressFieldsFragmentDoc = gql`
    fragment jobAddressFields on NestedPeopleJobAddress {
        dadata
        street
        locationRoute
        house
        flat
        region
        block
        blockType
    }
`;
export const AddressFieldsFragmentDoc = gql`
    fragment addressFields on NestedHabitationAddress {
        area
        areaType
        block
        blockType
        city
        cityType
        dadata
        fiasId
        fiasLevel
        flat
        flatType
        house
        houseType
        kladrId
        locationRoute
        postalCode
        region
        regionType
        settlement
        settlementType
        street
        streetType
    }
`;
export const PeopleFieldsFragmentDoc = gql`
    fragment peopleFields on People {
        firstName
        lastName
        middleName
        birthDate
        updated
        hash
        additionalIncome
        additionalPhone
        additionalPhoneOwner
        additionalPhoneOwnerFIO
        birthPlace
        creditSelection {
            amount
            autoBrand
            autoModel
            autoYear
            creditSecurity
            initialFee
            obtainCityId
            obtainCityLocationRoute
            period
            propertyAutoRegistrationPlate
            purpose
            region
        }
        creditSelectionPledgeCar {
            registrationPlate
            modelId
            model
            markId
            mark
            power
            year
            hash
            vin
        }
        creditSelectionPledgeHabitation {
            updated
            hash
            address {
                dadata
                house
                locationRoute
                street
                flat
                block
                region
                city
                regionType
                area
                areaType
                cityType
                settlementType
                settlement
                streetType
                street
            }
            creditSelection {
                pledgeOwnerFamilyStatus
                pledgeOwnerFirstName
                pledgeOwnerLastName
                pledgeOwnerMiddleName
                pledgeOwnerPhone
                pledgeOwnerRelationship
                pledgeOwnerSingle
            }
        }
        dependentsCount
        education
        email
        employmentType
        flatRentAmount
        gender
        job {
            address {
                ...jobAddressFields
            }
            lastExperienceStartDate
            organization {
                activity
                inn
                name
                ogrn
                phone
                type
                typeCode
            }
            title
            type
        }
        livingHabitation {
            address {
                ...addressFields
            }
            hash
        }
        registrationHabitation {
            address {
                ...addressFields
            }
            phone
            hash
        }
        loanPaymentTotal
        maritalStatus
        monthlyIncome
        passport {
            addressRegistrationDate
            issueDate
            issuedBy
            issuedByCode
            number
        }
        rentCost
        salary
        snils
        solvencyProof
        unemploymentReason
        unemploymentReasonText
        workExperience
        haveCar
        haveApartment
    }
    ${JobAddressFieldsFragmentDoc}
    ${AddressFieldsFragmentDoc}
`;
export const EsiaAddressFieldsFragmentDoc = gql`
    fragment esiaAddressFields on NestedESIAHabitationAddress {
        area
        areaType
        block
        blockType
        city
        cityType
        dadata
        fiasId
        fiasLevel
        flat
        flatType
        house
        houseType
        kladrId
        postalCode
        region
        regionType
        settlement
        settlementType
        street
        streetType
    }
`;
export const EsiaPeopleFieldsFragmentDoc = gql`
    fragment esiaPeopleFields on ESIAPeople {
        updated
        hash
        firstName
        lastName
        middleName
        gender
        email
        birthDate
        birthPlace
        snils
        registrationHabitation {
            address {
                ...esiaAddressFields
            }
            hash
        }
        livingHabitation {
            address {
                ...esiaAddressFields
            }
            hash
        }
    }
    ${EsiaAddressFieldsFragmentDoc}
`;
export const EsiaPassportFieldsFragmentDoc = gql`
    fragment esiaPassportFields on ESIAPassport {
        updated
        hash
        series
        number
        issuedBy
        issueDate
        issueId
    }
`;
export const CreditSelectionFrontendLoadDataDocument = gql`
    query CreditSelectionFrontendLoadData($ownerId: Int) {
        profile(ownerId: $ownerId) {
            phone
            ESIAPassport {
                ...esiaPassportFields
            }
            ESIAOwner {
                ...esiaPeopleFields
            }
            owner {
                ...peopleFields
            }
            creditPeople {
                ...peopleFields
            }
            mfoPeople {
                ...peopleFields
            }
        }
    }
    ${EsiaPassportFieldsFragmentDoc}
    ${EsiaPeopleFieldsFragmentDoc}
    ${PeopleFieldsFragmentDoc}
`;
export const CreditSelectionFrontendCallCenterLoadDataDocument = gql`
    query CreditSelectionFrontendCallCenterLoadData($ownerId: Int!) {
        profile(ownerId: $ownerId) {
            phone
            ESIAPassport {
                ...esiaPassportFields
            }
            ESIAOwner {
                ...esiaPeopleFields
            }
            owner {
                ...peopleFields
            }
            creditPeople {
                ...peopleFields
            }
            mfoPeople {
                ...peopleFields
            }
        }
    }
    ${EsiaPassportFieldsFragmentDoc}
    ${EsiaPeopleFieldsFragmentDoc}
    ${PeopleFieldsFragmentDoc}
`;
export const CreditSelectionFrontendUpdateDataDocument = gql`
    mutation CreditSelectionFrontendUpdateData($ownerId: Int, $data: InputCommon!) {
        saveData(ownerId: $ownerId, data: $data) {
            creditPeople {
                ...peopleFields
            }
        }
    }
    ${PeopleFieldsFragmentDoc}
`;
export const CreditSelectionFrontendCallCenterUpdateDataDocument = gql`
    mutation CreditSelectionFrontendCallCenterUpdateData($ownerId: Int!, $data: InputCommon!) {
        saveData(ownerId: $ownerId, data: $data) {
            creditPeople {
                ...peopleFields
            }
        }
    }
    ${PeopleFieldsFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
    action: (requestHeaders?: Record<string, string>) => Promise<T>,
    operationName: string,
    operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
    return {
        CreditSelectionFrontendLoadData(
            variables?: TCreditSelectionFrontendLoadDataQueryVariables,
            requestHeaders?: Dom.RequestInit['headers'],
        ): Promise<TCreditSelectionFrontendLoadDataQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<TCreditSelectionFrontendLoadDataQuery>(
                        CreditSelectionFrontendLoadDataDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'CreditSelectionFrontendLoadData',
                'query',
            );
        },
        CreditSelectionFrontendCallCenterLoadData(
            variables: TCreditSelectionFrontendCallCenterLoadDataQueryVariables,
            requestHeaders?: Dom.RequestInit['headers'],
        ): Promise<TCreditSelectionFrontendCallCenterLoadDataQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<TCreditSelectionFrontendCallCenterLoadDataQuery>(
                        CreditSelectionFrontendCallCenterLoadDataDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'CreditSelectionFrontendCallCenterLoadData',
                'query',
            );
        },
        CreditSelectionFrontendUpdateData(
            variables: TCreditSelectionFrontendUpdateDataMutationVariables,
            requestHeaders?: Dom.RequestInit['headers'],
        ): Promise<TCreditSelectionFrontendUpdateDataMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<TCreditSelectionFrontendUpdateDataMutation>(
                        CreditSelectionFrontendUpdateDataDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'CreditSelectionFrontendUpdateData',
                'mutation',
            );
        },
        CreditSelectionFrontendCallCenterUpdateData(
            variables: TCreditSelectionFrontendCallCenterUpdateDataMutationVariables,
            requestHeaders?: Dom.RequestInit['headers'],
        ): Promise<TCreditSelectionFrontendCallCenterUpdateDataMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<TCreditSelectionFrontendCallCenterUpdateDataMutation>(
                        CreditSelectionFrontendCallCenterUpdateDataDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'CreditSelectionFrontendCallCenterUpdateData',
                'mutation',
            );
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
