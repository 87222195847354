import React, { useRef } from 'react';
import { useStyles, useBoolean, useMergedRef, usePositioner, error } from '@sravni/react-utils';
import { Portal } from '@sravni/react-design-system';
import { TooltipProps } from '@sravni/react-design-system/lib/Tooltip';
import { Fade } from '@sravni/react-design-system/lib/Transition';
import { Cross } from '@sravni/react-icons';
import styles from './styles.module.scss';

/**
 * @description The Tooltip component is used to show more content of a target
 *
 * @component
 * @example
 * ```jsx
 * <Tooltip title="prompt text">
 *   <span>Tooltip will show on mouse enter.</span>
 * </Tooltip>
 * ```
 */
export const Tooltip = React.forwardRef<
    HTMLSpanElement,
    Omit<TooltipProps, 'title' | 'content'> & {
        title?: React.ReactNode;
        bubbleContentClassName?: string;
        content?: React.ReactNode;
        withCrossIcon?: boolean;
    }
>((props, ref) => {
    const { title, content, placement, withCrossIcon, className, bubbleContentClassName, children, ...restProps } =
        props;

    const cx = useStyles(styles);
    const reference = React.useRef<Element | null>(null);
    const popper = React.useRef<HTMLElement | null>(null);
    const [visible, setVisible] = useBoolean(false);
    const { referenceRef, popperRef, arrowRef, getReferenceProps, getPopperProps } = usePositioner({
        placement,
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                },
            },
        ],
    });
    let triggerElement: React.ReactNode;

    // Ensure tooltip has only one child node
    const count = React.useMemo(() => React.Children.count(children), [children]);
    const condition = count === 0 || count > 1;
    const mergedRef = useMergedRef(referenceRef, ref);
    const timerRef = useRef<number>();

    const mouseEnterHandler: React.MouseEventHandler = () => {
        clearTimeout(timerRef.current);
        setVisible.on();
    };

    const mouseLeaveHandler: React.MouseEventHandler = () => {
        timerRef.current = setTimeout(() => {
            setVisible.off();
        }, 1000);
    };

    const onClickCrossIcon = () => {
        setVisible.off();
    };

    if (condition) {
        error({
            condition,
            message: 'Ensure tooltip has only one child node',
        })();

        triggerElement = children;
    } else {
        const child = React.Children.only(children) as React.ReactElement;

        const triggerProps = {
            ref: mergedRef,
            'data-qa': 'Tooltip',
            tabIndex: 0,
            className: cx(className, 'outlineNone'),
            ...restProps,
            ...getReferenceProps({}, reference),
        };

        triggerProps.onMouseEnter = mouseEnterHandler;
        triggerProps.onMouseLeave = mouseLeaveHandler;
        triggerProps.onTouchEnd = setVisible.toggle;

        triggerElement = React.cloneElement(child, triggerProps);
    }

    return (
        <React.Fragment>
            {triggerElement}
            <Portal>
                <Fade
                    ref={popperRef}
                    className={cx('bubble')}
                    visible={visible}
                    unmountOnExit
                    onMouseEnter={mouseEnterHandler}
                    onMouseLeave={mouseLeaveHandler}
                    {...getPopperProps({ style: { zIndex: 10 } }, popper)}>
                    <div ref={arrowRef} className={cx('arrow')} />
                    <div className={cx('contentContainer', bubbleContentClassName)}>
                        <div className={cx('title')}>
                            {title}
                            {withCrossIcon && <Cross className={cx('crossIcon')} onClick={onClickCrossIcon} />}
                        </div>
                        <div className={cx('content')}>{content}</div>
                    </div>
                </Fade>
            </Portal>
        </React.Fragment>
    );
});

Tooltip.defaultProps = {
    placement: 'top',
};

Tooltip.displayName = 'Tooltip';
