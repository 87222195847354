import React from 'react';

interface Props {
    if?: boolean;
    children: React.ReactNode;
}

export const NoIndex: React.FC<Props> = ({ if: condition, children }) => {
    return condition ? <noindex>{children}</noindex> : <>{children}</>;
};
