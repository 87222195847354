import { GUID_REGEXP } from '../constants';

export const setOrUpdateQueryParam = (pathname: string, searchString: string, paramName: string, newValue: string) => {
    const currentSearch = searchString.slice(1);
    const searchParams = new URLSearchParams(currentSearch);
    searchParams.set(paramName, newValue);
    const newSearch = searchParams.toString();
    if (newSearch !== currentSearch) {
        window.history.replaceState(null, '', [pathname, newSearch].filter(Boolean).join('?'));
    }
};

export const clearQueryParam = (pathname: string, searchString: string, paramName: string) => {
    const currentSearch = searchString.slice(1);
    const searchParams = new URLSearchParams(currentSearch);
    searchParams.delete(paramName);
    const newSearch = searchParams.toString();
    if (newSearch !== currentSearch) {
        window.history.replaceState(null, '', [pathname, newSearch].filter(Boolean).join('?'));
    }
};

/** Обновляет guid заявки в url для страниц callcenter / restore */
export const setLocationGuid = (guid: string) => {
    if (typeof window === 'undefined') {
        return;
    }

    const {
        location: { pathname, search },
        history,
    } = window;

    const newPathname = GUID_REGEXP.test(guid) ? pathname.replace(GUID_REGEXP, guid) : pathname;
    history.replaceState(null, '', [newPathname, search].join(''));
};
