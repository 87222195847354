import { createReducer, createAction } from 'redux-act';
import { Review } from '../../services/reviews/reviews.types';
import { IGlobalState } from '../index';
import { useSelector } from 'react-redux';

export type IState = Review[];

const initialState: IState = [];

export const setReviews = createAction<{ items: Review[] }>('set reviews');

export const reviewsReducer = createReducer<IState>({}, initialState).on(setReviews, (state, { items }) => items);

export function useReviews() {
    return useSelector((state: IGlobalState) => state.reviews);
}
