import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';
import { CREDIT_SELECTION_ABC_EXPERIMENT_IDS } from '../../constants';
import { IGlobalState } from '../index';

export type Experiments = Partial<Record<ValuesOf<typeof CREDIT_SELECTION_ABC_EXPERIMENT_IDS>, string>>;

interface State {
    experiments: Experiments;
    userId: string | null;
    isDefaultVariants: boolean;
}

export const DEFAULT_SELECTION_EXPERIMENTS_VARIANTS = {
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.NEW_FOCUSED_FORM]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.PROMOTION_GROUP_ID]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.DETAILS_BUTTON_TEXT_ID]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.PROMO_TINKOFF_BANNER]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.SUITABLE_OFFERS_BANNER]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.ADDITIONAL_QUESTIONS]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.EXACT_CONDITIONS]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.UTILIZATION_BANKS]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.NEW_MAIN_LANDING]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.SCORE_GEN7]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.SCORE_GEN7_PRODUCT]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.COLLECTIONS_ENDLESS_TEST]: '0',
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.AUTO_SEND_BANNER]: '0',
};

export const setAbData = createAction<State>('set ab data');
export const updateAbExperiments = createAction<Record<string, string>>('update ab experiments');
export const abDataReducer = createReducer<State>(
    {},
    {
        experiments: {},
        userId: null,
        isDefaultVariants: false,
    },
)
    .on(setAbData, (_, data) => data)
    .on(updateAbExperiments, (state, payload) => ({
        ...state,
        experiments: {
            ...state.experiments,
            ...payload,
        },
    }));

export const useExperiments = () => {
    return useSelector((state: IGlobalState) => state.abData.experiments);
};
