import React, { ComponentPropsWithoutRef, FC } from 'react';
import { useMetadata } from '../../../../store/reducers/metadata';
import { PreFooter as SeoPreFooter } from '@sravni/react-pre-footer';
import styles from './styles.module.scss';
import cn from 'classnames';

type Props = ComponentPropsWithoutRef<'div'>;

export const PreFooter: FC<Props> = (props) => {
    const { className } = props;
    const { prefooter = [] } = useMetadata();

    if (prefooter.length === 0) {
        return null;
    }

    return (
        <div className={cn(styles, className)}>
            <SeoPreFooter columns={prefooter} />
        </div>
    );
};
