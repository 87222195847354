import { load, ReCaptchaInstance } from 'recaptcha-v3';
import getConfig from 'next/config';

/** Класс для создания токенов от Google Captcha v3 на клиенте */
export class CaptchaClient {
    private static client: ReCaptchaInstance | null = null;

    private static loading: Promise<ReCaptchaInstance> | null = null;

    private static get clientSecret() {
        const { captchaClientSecret } = getConfig().publicRuntimeConfig;
        return captchaClientSecret || null;
    }

    static async load() {
        const { clientSecret } = this;

        if (!clientSecret) {
            return null;
        }

        this.loading = this.loading || (this.client && Promise.resolve(this.client)) || load(clientSecret);

        try {
            const client = await this.loading;
            client.hideBadge();
            return client;
        } finally {
            this.loading = null;
        }
    }

    static async execute(action: string) {
        this.client = this.client || (await this.load());
        return this.client && this.client.execute(action);
    }
}
