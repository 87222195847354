import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showMainSkeleton, hideMainSkeleton } from '../../../store/reducers/selection';

type Props = {
    shortSkeleton?: boolean;
};

export const MainSkeleton: React.FC<Props> = ({ shortSkeleton }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(showMainSkeleton({ shortSkeleton }));

        return () => {
            dispatch(hideMainSkeleton());
        };
    }, []);

    return null;
};
