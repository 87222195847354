import React, { ComponentPropsWithRef, FC, forwardRef } from 'react';
import classNames from 'classnames';
import { BadgeProps } from '@sravni/react-design-system/dist/types/components/Badge';
import { Badge, Spinner } from '@sravni/react-design-system';
import { MainSkeleton } from './main.skeleton';
import styles from './common.module.scss';

export { ErrorTextWrapper } from '../form/ui/input/errorTextWrapper';

export * from './ui';
export * from './grid';
export * from './text';
export * from './tooltip';
export * from './title';
export * from './hero';
export * from './banks.logo';
export * from './about.guard';
export * from './faq';
export * from './form.input';

export const Container: FC<ComponentPropsWithRef<'div'> & { clearStyles?: boolean }> = forwardRef(function Container(
    props,
    ref,
) {
    const { className, children, clearStyles = false, ...others } = props;
    return (
        <div {...others} className={classNames({ [styles.container]: !clearStyles }, className)} ref={ref}>
            {children}
        </div>
    );
});

export const BadgesList: FC<{ badges?: BadgeProps[]; badgeClassName?: string }> = ({ badges, badgeClassName }) => (
    <>
        {(badges || []).map((badge, i) => (
            <Badge color={badge.color} variant={badge.variant} text={badge.text} key={i} className={badgeClassName} />
        ))}
    </>
);

export const FormFallbackLoader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
    className,
    children,
    ...others
}) => {
    return (
        <Container {...others} className={styles.formFallbackLoader}>
            <MainSkeleton shortSkeleton />
        </Container>
    );
};

export const LandingLoaderWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
    className,
    children,
    ...others
}) => {
    return (
        <div className={classNames(styles['landing-loader-wrapper'], className)} {...others}>
            <Spinner size={28} />
        </div>
    );
};
