import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';
import { IGlobalState } from '../';

export type TDialogsState = {
    stack: Array<string>;
};

export const addDialog = createAction<{ id: string }>('add dialog id into stack');
export const removeDialog = createAction<{ id?: string }>('remove dialog from stack');

export const dialogsReducer = createReducer<TDialogsState>({}, { stack: [] })
    .on(addDialog, (state, { id }) => ({
        stack: [id, ...state.stack],
    }))
    .on(removeDialog, (state, { id }) => {
        if (id) {
            return {
                stack: state.stack.filter((v) => v !== id),
            };
        }
        const arr = [...state.stack];
        arr.shift();
        return {
            stack: arr,
        };
    });

export const useDialogsSelector = () => useSelector((state: IGlobalState) => state.dialogsStack);
