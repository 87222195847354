class OwnStorage implements Storage {
    private storage = new Map<string, string>();

    getItem(key: string): string | null {
        return this.storage.get(key) || null;
    }

    setItem(key: string, value: string) {
        this.storage.set(key, value);
    }

    removeItem(key: string) {
        this.storage.delete(key);
    }

    clear() {
        this.storage.clear();
    }

    key(index: number): string | null {
        return Array.from(this.storage.keys())[index] || null;
    }

    get length() {
        return this.storage.size;
    }
}

function isStorageAvailable(storageName: 'localStorage' | 'sessionStorage') {
    if (typeof window == 'undefined') {
        return false;
    }
    const test = 'test';
    try {
        const storage = window[storageName];
        storage.setItem(test, test);
        storage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

/** Производится проверка на доступность, если не доступен, используется собственный класс */
export const localStorage = isStorageAvailable('localStorage') ? window.localStorage : new OwnStorage();

/** Производится проверка на доступность, если не доступен, используется собственный класс */
export const sessionStorage = isStorageAvailable('sessionStorage') ? window.sessionStorage : new OwnStorage();
