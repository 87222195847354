import React from 'react';
import { Textarea as TextInputBase } from '@sravni/react-design-system';
import { ErrorTextWrapper } from '../common';

type Props = React.ComponentProps<typeof TextInputBase> & {
    errorMessage: React.ReactNode | undefined;
    rows?: number;
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
    const { errorMessage, rows, style, ...other } = props;
    return (
        <ErrorTextWrapper errorText={errorMessage}>
            <TextInputBase
                ref={ref}
                {...other}
                style={{ ...(rows ? { minHeight: `${rows * 1.25}em` } : {}), ...style }}
                invalid={Boolean(errorMessage)}
            />
        </ErrorTextWrapper>
    );
});

TextArea.displayName = 'TextArea';
