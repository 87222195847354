import { createReducer, createAction } from 'redux-act';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../index';
import { Seo } from '@sravni/types/lib/seo';

export type IState = {
    metadata: Partial<Seo>;
    showingSeoBlocks: {
        seoBlocks: boolean;
        prefooter: boolean;
        breadcrumbs: boolean;
        reviews: boolean;
    };
    seoBlocksHidden: boolean;
};

const initialState: IState = {
    metadata: {},
    showingSeoBlocks: {
        seoBlocks: false,
        prefooter: false,
        breadcrumbs: false,
        reviews: false,
    },
    seoBlocksHidden: false,
};

export const setMetadata = createAction<IState>('set metadata');

export const toggleSeoBlocks = createAction<{ show: boolean }>('toggle seo blocks showing');

export const metadataReducer = createReducer({}, initialState)
    .on(setMetadata, (_, data) => data)
    .on(toggleSeoBlocks, (state, payload) => ({
        ...state,
        seoBlocksHidden: !payload.show,
    }));

export const useMetadata = () => {
    return useSelector((state: IGlobalState) => state.metadata.metadata);
};

export const useShowingSeoBlocks = () => {
    return useSelector((state: IGlobalState) => state.metadata.showingSeoBlocks);
};

export const useSeoBlocksHidden = () => {
    return useSelector((state: IGlobalState) => state.metadata.seoBlocksHidden);
};
