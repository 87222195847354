import React, { FC, ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames';
import { Rows, Col, Row } from '../grid';
import { Typography } from '@sravni/react-design-system';
import { Badge } from '@sravni/react-design-system';
import { BadgeProps } from '@sravni/react-design-system/lib/Badge';
import styles from './hero.module.scss';

const { Heading, Text } = Typography;

type Props = ComponentPropsWithoutRef<'div'> & {
    header?: React.ReactNode;
    headerParams?: Partial<Typography.HeadingProps & { className?: string }>;
    subHeader?: React.ReactNode;
    subHeaderParams?: Partial<Typography.TextProps & { className?: string }>;
    button?: React.ReactNode;
    banner?: React.ReactNode;
    badges?: BadgeProps[];
    imageUrl?: string;
    imageClassName?: string;
};

export const Hero: FC<Props> = (props) => {
    const {
        badges,
        header,
        subHeader,
        headerParams,
        imageUrl,
        imageClassName,
        subHeaderParams,
        banner,
        button,
        className,
        children,
        ...others
    } = props;

    return (
        <Rows className={classNames(styles['hero-container'], className)} {...others}>
            {banner && <div className={styles['banner-container_mobile']}>{banner}</div>}
            <Row className={styles['row']}>
                <Col className={classNames(styles['col'], styles['text-col'])}>
                    {badges && (
                        <Row className={styles['badges-row']}>
                            {badges.map((badge, i) => (
                                <Col key={i}>
                                    <Badge color={badge.color} variant={badge.variant} text={badge.text} />
                                </Col>
                            ))}
                        </Row>
                    )}
                    {header && (
                        <Heading
                            level={headerParams?.level || 2}
                            className={classNames(styles['hero-title'], headerParams?.className)}>
                            {header}
                        </Heading>
                    )}
                    {subHeader && (
                        <Text size={16} className={classNames(styles['hero-description'], subHeaderParams?.className)}>
                            {subHeader}
                        </Text>
                    )}
                    {banner && <div className={styles['banner-container_desktop']}>{banner}</div>}
                    {button && <div className={styles['button-container']}>{button}</div>}
                </Col>
                <Col className={classNames(styles['col'], styles['image-col'])}>
                    {imageUrl && (
                        <img
                            className={classNames(styles['hero-image'], imageClassName)}
                            alt=""
                            src={imageUrl}
                            loading="lazy"
                        />
                    )}
                </Col>
            </Row>
        </Rows>
    );
};
