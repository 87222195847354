import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';
import { ILocation as ILocationBase } from 'services/location/location.types';
import { IGlobalState } from '../index';

export type ILocation = ILocationBase;

interface IState {
    regions: ILocation[];
    centers: ILocation[];
    currentLocation: ILocation;
}

export const CURRENT_LOCATION: Readonly<ILocation> = {
    id: 83,
    name: 'Москва',
    fullName: 'г Москва',
    nameLocative: 'в Москве',
    nameGenitive: 'Москвы',
    route: '6.83.',
    alias: 'moskva',
    localityType: 'город',
    shortLocationType: 'г.',
    latitude: 55.753676,
    longitude: 37.619899,
};

const initState: IState = {
    regions: [],
    centers: [],
    currentLocation: CURRENT_LOCATION,
};

export const useCurrentLocation = () =>
    useSelector((state: IGlobalState) => state.locations.currentLocation as ILocation);

export const setCurrentLocation = createAction<ILocation>('set current location');
export const setLocations = createAction<{ regions: ILocation[]; centers: ILocation[] }>('set locations');

export const locationsReducer = createReducer<IState>({}, initState)
    .on(setCurrentLocation, (state, currentLocation) => ({ ...state, currentLocation }))
    .on(setLocations, (state, { regions, centers }) => ({ ...state, regions, centers }));
