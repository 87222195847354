import { createReducer, createAction } from 'redux-act';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../index';
import { Bank } from '../../services/organizations/types';

export type IState = Bank | null;

const initialState: IState = null;

export const setOrganization = createAction<{ organization: IState }>('set organization');

export const useOrganization = () => useSelector((state: IGlobalState) => state.organization as IState);

export const organizationReducer = createReducer<IState>({}, initialState).on(
    setOrganization,
    (state, { organization }) => organization,
);
