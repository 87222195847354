import { FORM_FIELDS } from './form.constants';

export enum FB_REQUEST_TYPES {
    CALCULATION = 2,
    CONTACT = 3,
    REQUEST = 4,
    FINAL = 5,
}

export const STATUS_STATES = {
    COUNTDOWN: 'countdown',
    REJECTED: 'rejected',
    REFUSED: 'refused',
    APPROVED: 'approved',
    ACCEPTED: 'accepted',
    CONFIRMED: 'confirmed',
    FINAL_APPROVED: 'finalapproved',
    NEW: 'new',
    WAITING: 'waiting',
};

export const FB_FIELDS_MAP = {
    [FORM_FIELDS.AMOUNT]: 'credit_amount',
    [FORM_FIELDS.INITIAL_FEE]: 'initial_fee',
    [FORM_FIELDS.PERIOD]: 'credit_term',
    [FORM_FIELDS.REGION]: 'region',
    [FORM_FIELDS.REGION_FULL_NAME]: 'region_full_name',
    [FORM_FIELDS.REGION_LOCATION_ROUTE]: 'region_location_route',
    [FORM_FIELDS.PURPOSE]: 'credit_goal',

    [FORM_FIELDS.FIRST_NAME]: 'name',
    [FORM_FIELDS.LAST_NAME]: 'surname',
    [FORM_FIELDS.MIDDLE_NAME]: 'middle_name',

    [FORM_FIELDS.AUTO_BRAND]: 'purchase_car_brand',
    [FORM_FIELDS.AUTO_MODEL]: 'purchase_car_model',
    [FORM_FIELDS.AUTO_YEAR]: 'purchase_car_year',

    // [FORM_FIELDS.OKB_SCORE]: 'score_point_okb',
    // [FORM_FIELDS.OKB_YANDEX_SCORE]: 'score_point_okb_yandex',
    [FORM_FIELDS.OKB_YANDEX_SCORE]: 'score_point_okb',
    [FORM_FIELDS.NBKI_SCORE]: 'nkr_score',
    [FORM_FIELDS.SRAVNI_SCORE]: 'sravni_score',
    [FORM_FIELDS.SCORE_GEN7_CC]: 'score_point_gen7_cc',
    [FORM_FIELDS.SCORE_GEN7_CL]: 'score_point_gen7_cl',

    [FORM_FIELDS.PHONE]: 'phone',
    [FORM_FIELDS.EMAIL]: 'email',
    [FORM_FIELDS.AGREEMENT]: 'conditions',

    [FORM_FIELDS.BIRTHDAY]: 'birthdate',
    [FORM_FIELDS.BIRTH_PLACE]: 'birth_settlement',
    [FORM_FIELDS.PASSPORT_NUMBER]: 'passport',
    [FORM_FIELDS.PASSPORT_DATE]: 'doc_when_issued',
    [FORM_FIELDS.UNIT_CODE]: 'passport_code',
    [FORM_FIELDS.ISSUED]: 'doc_issued',
    [FORM_FIELDS.REGISTRATION]: 'registration_address',
    [FORM_FIELDS.REGISTRATION_STREET]: 'registration_street',
    [FORM_FIELDS.REGISTRATION_APARTMENT]: 'registration_apartment',
    [FORM_FIELDS.REGISTRATION_DATE]: 'registration_date',
    [FORM_FIELDS.REGISTRATION_ROUTE]: FORM_FIELDS.REGISTRATION_ROUTE,
    [FORM_FIELDS.RESIDENCE]: 'home_address',
    [FORM_FIELDS.RESIDENCE_STREET]: 'residence_street',
    [FORM_FIELDS.RESIDENCE_APARTMENT]: 'residence_apartment',
    [FORM_FIELDS.RESIDENCE_ROUTE]: FORM_FIELDS.RESIDENCE_ROUTE,

    [FORM_FIELDS.EMPLOYMENT_TYPE]: 'employment_type',
    [FORM_FIELDS.ORGANIZATION_NAME]: 'organization_name',
    [FORM_FIELDS.AREA_OF_EMPLOYMENT]: 'activity_field',
    [FORM_FIELDS.JOB_TYPE]: 'organization_position',
    [FORM_FIELDS.JOB_TITLE]: 'work_position',
    [FORM_FIELDS.LAST_EXPERIENCE]: 'start_date',
    [FORM_FIELDS.LAST_EXPERIENCE_START_YEAR]: 'start_date_year',
    [FORM_FIELDS.LAST_EXPERIENCE_START_MONTH]: 'start_date_month',
    [FORM_FIELDS.EMPLOYER_INN]: 'employer_inn',
    [FORM_FIELDS.EMPLOYER_TYPE]: 'organization_type',
    [FORM_FIELDS.INCOME_CONFIRMATION]: 'income_confirmation',
    [FORM_FIELDS.WORK_ADDRESS]: 'work_address',
    [FORM_FIELDS.WORK_PHONE]: 'work_phone',
    [FORM_FIELDS.WORK_ROUTE]: FORM_FIELDS.WORK_ROUTE,
    [FORM_FIELDS.SALARY_BANK]: 'salary_bank',
    [FORM_FIELDS.UNEMPLOYMENT_REASON]: 'unemployment_reason',
    [FORM_FIELDS.ANOTHER_UNEMPLOYMENT_REASON]: 'unemployment_reason_other',
    [FORM_FIELDS.MONTHLY_SALARY]: 'credit_monthly_salary',

    [FORM_FIELDS.ADDITIONAL_PHONE]: 'extra_phone',
    [FORM_FIELDS.ADDITIONAL_PHONE_OWNER]: 'extra_phone_owner',
    [FORM_FIELDS.ADDITIONAL_PHONE_OWNER_FIO]: 'extra_phone_owner_fio',
    [FORM_FIELDS.ADDITIONAL_INCOME]: 'additional_income',
    [FORM_FIELDS.RENT_APARTMENT]: 'total_payment_credit',
    [FORM_FIELDS.CREDIT_HISTORY]: 'credit_history',
    [FORM_FIELDS.EDUCATION]: 'education',
    [FORM_FIELDS.FAMILY_STATUS]: 'marital_status',
    [FORM_FIELDS.SNILS]: 'snils',
    [FORM_FIELDS.CAR]: 'auto_type',
    [FORM_FIELDS.CAR_BRAND]: 'car_brand',
    [FORM_FIELDS.CAR_MODEL]: 'car_model',
    [FORM_FIELDS.CAR_YEAR]: 'car_year',
    [FORM_FIELDS.CAR_PRICE]: 'car_price',
    [FORM_FIELDS.NUMBER_OF_DEPENDENTS]: 'number_dependents',
    [FORM_FIELDS.PROPERTY_TYPE]: 'property_type',
    [FORM_FIELDS.GENDER]: 'sex',
    [FORM_FIELDS.DELIVERY_TYPE]: 'method_obtaining_a_loan',

    [FORM_FIELDS.PLEDGE_OWNER_MYSELF]: 'pledge_realestate_is_owner',
    [FORM_FIELDS.PLEDGE_OWNER_SINGLE]: 'pledge_realestate_single_owner',
    [FORM_FIELDS.PLEDGE_REALTY_ADDRESS_THE_SAME]: 'pledge_realestate_same_reg_addr',
    [FORM_FIELDS.PLEDGE_OWNER_RELATIONSHIP]: 'pledge_realestate_owner_relation',
    [FORM_FIELDS.PLEDGE_OWNER_FAMILY_STATUS]: 'pledge_realestate_owner_marital_status',
    [FORM_FIELDS.PLEDGE_OWNER_FIRST_NAME]: 'pledge_realestate_owner_first_name',
    [FORM_FIELDS.PLEDGE_OWNER_LAST_NAME]: 'pledge_realestate_owner_last_name',
    [FORM_FIELDS.PLEDGE_OWNER_MIDDLE_NAME]: 'pledge_realestate_owner_middle_name',
    [FORM_FIELDS.PLEDGE_OWNER_PHONE]: 'pledge_realestate_owner_phone_mobile',
    [FORM_FIELDS.PLEDGE_REALTY_STREET]: 'pledge_realestate_address',
    [FORM_FIELDS.PLEDGE_REALTY_HOUSE]: 'pledge_realty_house',
    [FORM_FIELDS.PLEDGE_REALTY_STRUCTURE]: 'pledge_realty_structure',
    [FORM_FIELDS.PLEDGE_REALTY_BUILDING]: 'pledge_realty_building',
    [FORM_FIELDS.PLEDGE_REALTY_FLAT]: 'pledge_realty_flat',

    [FORM_FIELDS.PLEDGE_AUTO_BRAND]: 'car_brand',
    [FORM_FIELDS.PLEDGE_AUTO_BRAND_ID]: 'car_brand_id',
    [FORM_FIELDS.PLEDGE_AUTO_MODEL]: 'car_model',
    [FORM_FIELDS.PLEDGE_AUTO_MODEL_ID]: 'car_model_id',
    [FORM_FIELDS.PLEDGE_AUTO_NUMBER]: 'car_regnumber',
    [FORM_FIELDS.PLEDGE_AUTO_YEAR]: 'car_year',
    [FORM_FIELDS.PLEDGE_AUTO_POWER]: 'car_power',
    [FORM_FIELDS.PLEDGE_AUTO_OWNER_MYSELF]: 'car_is_owner',

    [FORM_FIELDS.PARTNER_ID]: 'aff_id',
    [FORM_FIELDS.SENIORITY]: 'seniority',

    /** CF-1309 */
    [FORM_FIELDS.WHEN_MONEY_NEEDED]: 'when_money_needed',
    [FORM_FIELDS.HAS_RECENT_APPLICATION]: 'has_recent_application',
    [FORM_FIELDS.RECENT_APPLICATION_CAUSE]: 'recent_application_cause',
};
